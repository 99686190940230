import { useEffect, useRef, useState } from "react";

const useDelayCallback = (
  callback: () => void,
  deps: any[] = [],
  delay = 2000
) => {
  const [loading, setLoading] = useState<boolean>(false);
  const timeoutCallback = useRef<NodeJS.Timeout>();

  useEffect(() => {
    callback();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    clearTimeout(timeoutCallback.current!);
    setLoading(true);

    timeoutCallback.current = setTimeout(async () => {
      await callback();
      setLoading(false);
    }, delay);

    return () => clearTimeout(timeoutCallback.current!);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  return { loading, setLoading };
};

export default useDelayCallback;
