import React from "react";
import { IonList } from "@ionic/react";
import { TipSplit } from "../../../../../types/DBTypes";
import SplitItem from "../SplitItem/SplitItem";
import "./SplitList.css";

interface SpProps {
  splits?: TipSplit[];
}

const SplitList = ({ splits }: SpProps) => {
  if (!splits?.length) return null;
  return (
    <IonList inset>
      {splits.map((split) => (
        <SplitItem key={split.id} split={split} />
      ))}
    </IonList>
  );
};

export default SplitList;
