import { createAction } from "@reduxjs/toolkit";
import {
  TCreateProfileEnterprisePayload,
  TCreateProfileEnterpriseSuccessPayload,
  TLoadProfilesEnterpriseSuccessPayload,
} from "../types/enterprise.types";

export const CREATE_PROFILE_ENTERPRISE = "[ENTERPRISE] CREATE PROFILE";
export const CREATE_PROFILE_ENTERPRISE_SUCCESS =
  "[ENTERPRISE] CREATE PROFILE SUCCESS";
export const LOAD_PROFILES_ENTERPRISE = "[ENTERPRISE] LOAD PROFILES";
export const LOAD_PROFILES_ENTERPRISE_SUCCESS =
  "[ENTERPRISE] LOAD PROFILES SUCCESS";

export const createProfileEnterprise =
  createAction<TCreateProfileEnterprisePayload>(CREATE_PROFILE_ENTERPRISE);
export const createProfileEnterpriseSuccess =
  createAction<TCreateProfileEnterpriseSuccessPayload>(
    CREATE_PROFILE_ENTERPRISE_SUCCESS
  );
export const loadProfilesEnterprise = createAction(LOAD_PROFILES_ENTERPRISE);
export const loadProfilesEnterpriseSuccess =
  createAction<TLoadProfilesEnterpriseSuccessPayload>(
    LOAD_PROFILES_ENTERPRISE_SUCCESS
  );
