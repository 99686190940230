import { IonChip } from "@ionic/react";
import { formatAmount } from "../../../../../utils";
import { useContext } from "react";
import { PaymentModalContext } from "../PaymentModalProvider/PaymentModalProvider";

const PREDEFINED_AMOUNTS = [2, 5, 7, 10, 20];

const AmountChips = () => {
  const { amount, setAmount } = useContext(PaymentModalContext);

  const isValChosen = (value: number) => amount === value;

  const handleChipClick = (value: number) => {
    if (!setAmount) return;
    // If I click on already chosen chip
    if (isValChosen(value)) {
      setAmount(0);
    } else {
      setAmount(value);
    }
  };

  return (
    <div className="amounts-scrollable">
      {PREDEFINED_AMOUNTS.map((val) => (
        // TODO should be wrapped in div, otherwise is not scrollable
        <div key={val}>
          <IonChip
            className={`chip--amount${isValChosen(val) ? "--selected" : ""}`}
            onClick={() => handleChipClick(val)}
            disabled={!setAmount}
          >
            {formatAmount(val, 0)}
          </IonChip>
        </div>
      ))}
    </div>
  );
};

export default AmountChips;
