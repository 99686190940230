import {
  IonContent,
  IonHeader,
  IonList,
  IonModal,
  IonText,
  IonToolbar,
  useIonViewDidLeave,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from "@ionic/react";
import Waiter from "../Employee/Employee";
import React, { useEffect, useRef, useState } from "react";
import { IonModalCustomEvent } from "@ionic/core/dist/types/components";
import { ModalBreakpointChangeEventDetail } from "@ionic/core";
import "./EmployeeListModal.css";
import { useParams } from "react-router";

interface TProps {
  onFullModal: (enabled: boolean) => void;
  refToHandleHeight: any;
  data: object[];
}

const EmployeeListModal = ({
  onFullModal,
  refToHandleHeight,
  data,
}: TProps) => {
  const { slug } = useParams<{ slug: string }>();

  // Refs used to calculate proportions
  const listRef = useRef();
  const headerRef = useRef();

  // dynamically calculated breakpoint to show modal exactly under ProfileHeader
  const [initBreakpoint, setInitBreakpoint] = useState<number>();
  // modal controller to show/hide it during rendering, breakpoint calculation and pages entering/leaving
  const [isModalOpen, setIsModalOpen] = useState<boolean>(!!initBreakpoint);
  const [fullMode, setFullMode] = useState<boolean>(false);
  // once pages is open, we need setup default view (open modal on the available part of the app)
  useIonViewWillEnter(() => {
    setFullMode(false);
    setIsModalOpen(true);
  });

  // once we redirected to another pages it should hide modal before it,
  // and then reset modal size to be able under profileHeader
  useIonViewWillLeave(() => {
    setIsModalOpen(false);
  });

  useIonViewDidLeave(() => {
    setFullMode(false);
  });

  useEffect(() => {
    onFullModal(fullMode);
  }, [onFullModal, fullMode]);
  useEffect(() => {
    if (!refToHandleHeight?.current || initBreakpoint) return;
    const calcBreakpoint = () => {
      if (!refToHandleHeight?.current || initBreakpoint) return;
      const { scrollHeight } = refToHandleHeight?.current;
      const { innerHeight } = window;
      if (scrollHeight <= 0) return;

      const breakpointSize = Number(
        // calculate size from bottom to profileHeader with 15px gap
        (
          (innerHeight - scrollHeight - 15) /
          // we need to decrease window's height as we show modal only on 85%
          (innerHeight * 0.85)
        ).toFixed(2)
      );

      setInitBreakpoint(breakpointSize);
      setIsModalOpen(true);
    };

    setTimeout(calcBreakpoint, 1500);
  }, [refToHandleHeight, initBreakpoint]);

  const handleBreakpointChange = ({
    detail,
  }: IonModalCustomEvent<ModalBreakpointChangeEventDetail>) => {
    setFullMode(detail.breakpoint === 1);
  };

  if (!initBreakpoint) return null;
  return (
    <IonModal
      isOpen={isModalOpen}
      initialBreakpoint={initBreakpoint}
      breakpoints={[initBreakpoint, 1]}
      backdropDismiss={false}
      backdropBreakpoint={1}
      className="modal-employees-content"
      onIonBreakpointDidChange={handleBreakpointChange}
    >
      {/*@ts-ignore*/}
      <IonHeader ref={headerRef} collapse={"fade"}>
        <IonToolbar>
          <IonText>
            <h2>Who do you want to tip?</h2>
          </IonText>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {/*@ts-ignore*/}
        <IonList ref={listRef}>
          {data.map(({ name, picture, role, slug: employeeSlug }: any) => (
            <Waiter
              picture={picture}
              firstName={name}
              jobRole={role}
              routerLink={`/${slug}/${employeeSlug}`}
              key={employeeSlug}
            />
          ))}
        </IonList>
      </IonContent>
    </IonModal>
  );
};

export default EmployeeListModal;
