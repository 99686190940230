import { useContext, useEffect, useState } from "react";
import "./TippingForm.css";
import AmountInput from "../AmountInput/AmountInput";
import AmountChips from "../AmountChips/AmountChips";
import { PaymentModalContext } from "../PaymentModalProvider/PaymentModalProvider";

interface TProps {
  name: string;
  picture: string;
}

const TippingForm = ({ name, picture }: TProps) => {
  const [modalActive, setModalActive] = useState(false);

  const { isFeeCovered, setIsFeeCovered } = useContext(PaymentModalContext);

  useEffect(() => {
    if (!isFeeCovered) setModalActive(true);
  }, [isFeeCovered]);

  const handleCancelModal = () => {
    if (setIsFeeCovered) setIsFeeCovered(true);
    setModalActive(false);
  };

  return (
    <div className="tipping-form">
      <AmountInput />
      <AmountChips />
      {/*<Toggle checked={isFeeCovered} setChecked={setIsFeeCovered}>*/}
      {/*  {`Help ${name} get 100% of the tip`}*/}
      {/*</Toggle>*/}
      {/*<Modal*/}
      {/*  title="Are you sure?"*/}
      {/*  secondaryButton={{*/}
      {/*    title: "Yes",*/}
      {/*    onClick: () => setModalActive(false),*/}
      {/*  }}*/}
      {/*  primaryButton={{ title: "Keep 100%", onClick: handleCancelModal }}*/}
      {/*  image={handleImageUrl(picture)}*/}
      {/*  active={modalActive}*/}
      {/*  setActive={setModalActive}*/}
      {/*>*/}
      {/*  <IonText color="dark">*/}
      {/*    By doing this {name} won’t get 100% of your tip.{" "}*/}
      {/*  </IonText>*/}
      {/*</Modal>*/}
    </div>
  );
};

export default TippingForm;
