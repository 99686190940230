import AWS from "aws-sdk";
import { Amplify } from "aws-amplify";
import awsConfig from "./aws-exports";
import { IonApp, setupIonicReact } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { connect } from "react-redux";
import { TRootState } from "./store/store";
import Toast from "./components/common/Toast/Toast";
import TippingRoutes from "./components/routers/Tipping";
import AuthorizedRoutes from "./components/routers/Authorized";
import SignInRoutes from "./components/routers/SignInRoutes";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import "./theme/common.css";

setupIonicReact({ mode: "ios", swipeBackEnabled: false });

Amplify.configure({
  ...awsConfig,
  Analytics: {
    disabled: true,
  },
  aws_cloud_logic_custom: [
    {
      name: "tipmiapiRest",
      endpoint: process.env.REACT_APP_REST_API_URL,
      region: "eu-west-2",
    },
  ],
});

AWS.config.credentials = new AWS.Credentials(
  "AKIA6ORSSOW5CRPJA7XL",
  "Z6GxDPpqOustb20CSaFWLL11ASaJ5X40GDVj2qSY"
);

interface TProps {
  isAuthorized?: boolean;
  toast?: {
    active: boolean;
    text: string;
  };
}

const mapStateToProps = (state: TRootState) => ({
  isAuthorized: state.user.authorized,
  toast: state.ui.toast,
});

const App: React.FC = ({ isAuthorized, toast }: TProps) => {
  const { host } = window.location;

  const tippingHost = process.env.REACT_APP_TIPPING_URL || "";

  return (
    <IonApp>
      <IonReactRouter>
        {host.includes(tippingHost) ? (
          <TippingRoutes />
        ) : (
          <>{isAuthorized ? <AuthorizedRoutes /> : <SignInRoutes />}</>
        )}
        {toast && <Toast active={toast.active} text={toast.text} />}
      </IonReactRouter>
    </IonApp>
  );
};

export default connect(mapStateToProps)(App);
