import {
  IonBackButton,
  IonButtons,
  IonHeader,
  IonMenuButton,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { IS_MOBILE } from "../../../utils";
import "./TabHeader.css";
import React from "react";

interface TProps {
  title: string;
  goBackAvailable?: boolean;
  children?: React.ReactNode;
}
const TabHeader = ({ title, children, goBackAvailable = false }: TProps) => {
  return (
    <IonHeader className="main-header" mode="md">
      <IonToolbar color="secondary">
        {goBackAvailable ? (
          <IonButtons slot="start">
            <IonBackButton mode="ios" color="dark" defaultHref="/" />
          </IonButtons>
        ) : (
          <IonMenuButton slot="start" />
        )}
        <IonTitle>{title}</IonTitle>
        {!IS_MOBILE && children && <div slot="end">{children}</div>}
      </IonToolbar>
      {IS_MOBILE && children && <IonToolbar>{children}</IonToolbar>}
    </IonHeader>
  );
};

export default TabHeader;
