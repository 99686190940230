import React from "react";
import { ProfileEnterprise } from "../../../../../types/DBTypes";
import { IonBadge, IonItem, IonLabel } from "@ionic/react";
import Avatar from "../../../../common/Avatar/Avatar";
import handleAddress from "../../../../../utils/handleAddress";

interface IProps {
  item: ProfileEnterprise;
}

const EnterpriseItem = ({ item }: IProps) => {
  const { id, name, active, address, businesses, picture } = item;

  // const icon = active ? checkmarkCircleSharp : closeCircleSharp;
  // const color = active ? "success" : "danger";

  return (
    <IonItem routerLink={`/enterprise/${id}`}>
      <Avatar url={picture} slot="start" />
      <IonLabel>
        {name}
        {/*<IonIcon color={color} slot="end" size="medium" icon={icon} />*/}
        <p>{handleAddress(address)}</p>
      </IonLabel>
      {businesses?.length && (
        <IonBadge slot="end">{businesses?.length}</IonBadge>
      )}
    </IonItem>
  );
};

export default EnterpriseItem;
