import { createAction } from "@reduxjs/toolkit";
import {
  TLoadTippingInfoPayload,
  TLoadTippingInfoSuccessPayload,
  TSaveTippingTipPayload,
} from "../types/tipping.types";

export const LOAD_TIPPING_INFO = "[UI] LOAD_TIPPING_INFO";
export const LOAD_TIPPING_INFO_SUCCESS = "[UI] LOAD_TIPPING_INFO_SUCCESS";

export const SAVE_TIPPING_TIP = "[UI] SAVE_TIPPING_TIP";

export const loadTippingInfo =
  createAction<TLoadTippingInfoPayload>(LOAD_TIPPING_INFO);

export const loadTippingInfoSuccess =
  createAction<TLoadTippingInfoSuccessPayload>(LOAD_TIPPING_INFO_SUCCESS);

export const saveTippingTip =
  createAction<TSaveTippingTipPayload>(SAVE_TIPPING_TIP);
