import { createSelector } from "@reduxjs/toolkit";
import { TRootState } from "../store";

const splitsSelector = (state: TRootState) => state.splits;

export const getSplits = createSelector(
  splitsSelector,
  (state) => state?.items
);

export const getSplitDetails = createSelector(
  splitsSelector,
  (state) => state?.splitDetails
);

export const getSplitsNextToken = createSelector(
  splitsSelector,
  (state) => state?.nextToken
);

export const getSplitsHasMore = createSelector(
  getSplitsNextToken,
  (nextToken) => !!nextToken
);
