import {
  IonContent,
  IonHeader,
  IonModal,
  IonSegment,
  IonSegmentButton,
  IonToolbar,
} from "@ionic/react";
import { useState } from "react";
import QRCode from "../QRCode/QRCode";

type TQRCodeItem = {
  badge: string;
  path: string;
};
interface IProps {
  data: TQRCodeItem[];
}

const QRCodesModal = ({ data }: IProps) => {
  const [selectedBadge, setSelectedBadge] = useState<TQRCodeItem>(data[0]);

  return (
    <IonModal
      initialBreakpoint={0.75}
      breakpoints={[0, 0.75, 0.95]}
      trigger="open-qr-modal"
    >
      <IonHeader collapse="fade" className="ion-padding-vertical">
        <IonToolbar>
          <IonSegment value={selectedBadge.badge}>
            {data.map((qr, index) => (
              <IonSegmentButton
                key={index}
                value={qr.badge}
                onClick={() => setSelectedBadge(qr)}
              >
                {qr.badge}
              </IonSegmentButton>
            ))}
          </IonSegment>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding-horizontal">
        <QRCode {...selectedBadge} />
      </IonContent>
    </IonModal>
  );
};

export default QRCodesModal;
