import React, { ReactElement, useContext, useState } from "react";
import {
  IonButton,
  IonIcon,
  IonLabel,
  IonList,
  IonListHeader,
} from "@ionic/react";
import { chevronBack, chevronForward } from "ionicons/icons";
import { JSX } from "@ionic/core/components";
import { FieldContext } from "../../pages/EnterpriseUserCreate/EnterpriseUserCreate";

interface IProps {
  children: ReactElement<JSX.IonItem | JSX.IonItemGroup>[];
}

const StepByStep = ({ children }: IProps) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const context = useContext(FieldContext);
  if (!context) return null;
  const { title } = context;
  return (
    <IonList className="enterprise-form-step section-inset">
      <IonListHeader>
        {activeIndex > 0 && (
          <IonButton onClick={() => setActiveIndex((i) => i - 1)}>
            <IonIcon icon={chevronBack} /> Back
          </IonButton>
        )}
        <IonLabel>{title}</IonLabel>
        {activeIndex < children.length - 1 && (
          <IonButton onClick={() => setActiveIndex((i) => i + 1)}>
            Next <IonIcon icon={chevronForward} />
          </IonButton>
        )}
      </IonListHeader>
      {children[activeIndex]}
    </IonList>
  );
};

export default StepByStep;
