import { useEffect, useState } from "react";
import {
  JSX,
  IonSearchbarCustomEvent,
} from "@ionic/core/dist/types/components";
import { SearchbarChangeEventDetail } from "@ionic/core";
import { useIonViewDidLeave } from "@ionic/react";

type TUseSearch = <T>(data: T[]) => [JSX.IonSearchbar["onIonChange"], T[]];

const useSearch: TUseSearch = (data) => {
  const [search, setSearch] = useState<string>("");
  const [filteredData, setFilteredData] = useState<any[]>([]);

  useIonViewDidLeave(() => setSearch(""));

  useEffect(() => {
    if (!search) return setFilteredData(data);
    const searchArray = search.toLowerCase().split(" ");
    const filtered = data.filter((item) => {
      const itemString = JSON.stringify(item).toLowerCase();
      let isMatch = 0;
      searchArray.forEach((searchItem) => {
        if (itemString.includes(searchItem)) isMatch++;
      });
      return isMatch === searchArray?.length;
    });
    setFilteredData(filtered);
  }, [search, data]);

  const handleSearch = (
    e: IonSearchbarCustomEvent<SearchbarChangeEventDetail>
  ) => {
    setSearch(e.detail.value || "");
  };

  return [handleSearch, filteredData];
};

export default useSearch;
