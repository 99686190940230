import { createAction } from "@reduxjs/toolkit";
import {
  TLoadSplitDetailsPayload,
  TLoadSplitDetailsSuccessPayload,
  TLoadSplitsPayload,
  TLoadSplitsSuccessPayload,
  TRepeatEmployeeTransferPayload,
  TRepeatEmployeeTransfersBatchPayload,
  TRepeatEmployeeTransferSuccessPayload,
} from "../types/splits.types";

export const LOAD_SPLITS = "[SPLIT] LIST SPLITS";
export const LOAD_SPLITS_SUCCESS = "[SPLIT] LIST SPLITS SUCCESS";

export const LOAD_SPLITS_MORE = "[SPLIT] LIST SPLITS MORE";
export const LOAD_SPLITS_MORE_SUCCESS = "[SPLIT] LIST SPLITS MORE SUCCESS";

export const LOAD_SPLIT_DETAILS = "[SPLIT] LOAD SPLIT DETAILS";
export const LOAD_SPLIT_DETAILS_SUCCESS = "[SPLIT] LOAD SPLIT DETAILS SUCCESS";

export const REPEAT_EMPLOYEE_TRANSFER = "[SPLIT] REPEAT EMPLOYEE TRANSFER";
export const REPEAT_EMPLOYEE_TRANSFER_SUCCESS =
  "[SPLIT] REPEAT EMPLOYEE TRANSFER SUCCESS";
export const REPEAT_EMPLOYEE_TRANSFER_BATCH =
  "[SPLIT] REPEAT EMPLOYEE TRANSFER BATCH";

export const loadSplits = createAction<TLoadSplitsPayload>(LOAD_SPLITS);
export const loadSplitsSuccess =
  createAction<TLoadSplitsSuccessPayload>(LOAD_SPLITS_SUCCESS);

export const loadSplitsMore = createAction(LOAD_SPLITS_MORE);
export const loadSplitsMoreSuccess = createAction<TLoadSplitsSuccessPayload>(
  LOAD_SPLITS_MORE_SUCCESS
);

export const loadSplitDetails =
  createAction<TLoadSplitDetailsPayload>(LOAD_SPLIT_DETAILS);
export const loadSplitDetailsSuccess =
  createAction<TLoadSplitDetailsSuccessPayload>(LOAD_SPLIT_DETAILS_SUCCESS);

export const repeatEmployeeTransfer =
  createAction<TRepeatEmployeeTransferPayload>(REPEAT_EMPLOYEE_TRANSFER);
export const repeatEmployeeTransferBatch =
  createAction<TRepeatEmployeeTransfersBatchPayload>(
    REPEAT_EMPLOYEE_TRANSFER_BATCH
  );
export const repeatEmployeeTransferSuccess =
  createAction<TRepeatEmployeeTransferSuccessPayload>(
    REPEAT_EMPLOYEE_TRANSFER_SUCCESS
  );
