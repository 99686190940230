import { IonCol, IonContent, IonImg, IonPage, IonRow } from "@ionic/react";
import Login from "../components/Login/Login";
import loginScreen from "../../../../assets/images/login-screen.png";
import "./Login.css";

const LoginPage: React.FC = () => {
  return (
    <IonPage>
      <IonContent className="page--login" fullscreen>
        <IonRow>
          <IonCol sizeMd="6" size="12">
            <Login />
          </IonCol>
          <IonCol sizeMd="6" size="12">
            <IonImg src={loginScreen} className="logo" />
          </IonCol>
        </IonRow>
      </IonContent>
    </IonPage>
  );
};

export default LoginPage;
