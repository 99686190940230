import { ReactNode, useEffect, useState } from "react";
import { IonContent, IonPage } from "@ionic/react";
import logo from "../../../../../assets/images/logo.svg";
import "./PageWrapper.css";
import { connect } from "react-redux";
import { TRootState } from "../../../../../store/store";
import { getIsContentLoading } from "../../../../../store/selectors/tipping.selectors";

interface IProps {
  children: ReactNode;
  isContentLoaded: boolean;
}

const mapState = (state: TRootState) => ({
  isContentLoaded: getIsContentLoading(state),
});

const PageWrapper = ({ children, isContentLoaded }: IProps) => {
  const [showLoader, setShowLoader] = useState<boolean>(!isContentLoaded);

  useEffect(() => {
    let timeout = setTimeout(() => setShowLoader(!isContentLoaded), 2000);
    return () => clearTimeout(timeout);
  }, [isContentLoaded]);

  return (
    <IonPage>
      <IonContent fullscreen className="content--tipping">
        {showLoader && (
          <div
            className={`tipping-loader ${
              isContentLoaded ? "loaded" : "loading"
            }`}
          >
            <div className="tipping-loader--background" />
            <img
              className="tipping-loader--logo"
              src={logo}
              alt="Tipinc Logo"
            />
          </div>
        )}
        {isContentLoaded && children}
      </IonContent>
    </IonPage>
  );
};

export default connect(mapState)(PageWrapper);
