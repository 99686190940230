import { IonCol, IonRow } from "@ionic/react";
import { FC, useEffect, useState } from "react";
import { connect } from "react-redux";
import { TRootState } from "../../../../../store/store";
import { formatAmount } from "../../../../../utils";
import StatisticCard from "../../../../common/StatisticCard/StatisticCard";
import ChipsPicker from "../../../../common/ChipsPicker/ChipsPicker";
import StatisticLoading from "./StatisticsLoading";
import {
  TStatisticsPeriod,
  TStatisticsPeriodKey,
  TVenueStatistics,
} from "../../../../../store/types/outlets.types";
import { getOutletStatistics } from "../../../../../store/actions/outlets.actions";
import { getOutletsStatistics } from "../../../../../store/selectors/outlets.selectors";

const EMPTY_PERIOD: TStatisticsPeriod = {
  actual: { totalTips: 0, totalTipsCount: 0 },
  previous: { totalTips: 0, totalTipsCount: 0 },
};

const PERIOD_ARRAY: TStatisticsPeriodKey[] = ["day", "week", "month"];
const PERIOD_TYPE: (keyof TStatisticsPeriod)[] = ["actual", "previous"];

const calcTrend = (actual: number, previous: number) => {
  if (!previous) return 0;
  return ((actual - previous) / previous) * 100;
};

interface StatisticsRowProps {
  id?: string;
  statistics?: TVenueStatistics[];
  getStatistics: (id: string) => void;
}

const mapDispatch = {
  getStatistics: (id: string) => getOutletStatistics({ id }),
};
const mapState = (state: TRootState) => ({
  statistics: getOutletsStatistics(state),
});

const StatisticsRow: FC<StatisticsRowProps> = ({
  statistics,
  id,
  getStatistics,
}) => {
  const [statisticToShow, setStatisticToShow] =
    useState<TVenueStatistics | null>(null);

  const [period, setPeriod] = useState<TStatisticsPeriodKey>("day");

  useEffect(() => {
    if (!statistics) {
      setStatisticToShow(null);
      return;
    }

    if (id) {
      const venueStatistic = statistics.find((s) => s.id === id);
      if (venueStatistic) setStatisticToShow(venueStatistic);
      else getStatistics(id);
      return;
    }
    const fullStatistic: TVenueStatistics = {
      day: { ...EMPTY_PERIOD },
      week: { ...EMPTY_PERIOD },
      month: { ...EMPTY_PERIOD },
      id: "",
      updatedAt: new Date().toISOString(),
    };

    statistics.forEach((s) => {
      for (const period of PERIOD_ARRAY) {
        for (const type of PERIOD_TYPE) {
          const { totalTips: totalTipsNew, totalTipsCount: totalTipsCountNew } =
            s[period][type];

          const { totalTips, totalTipsCount } = fullStatistic[period][type];
          fullStatistic[period][type] = {
            totalTips: totalTips + totalTipsNew,
            totalTipsCount: totalTipsCount + totalTipsCountNew,
          };
        }
      }
    });

    setStatisticToShow({ ...fullStatistic });
  }, [statistics, id]);

  if (!statisticToShow) return <StatisticLoading />;
  const { actual, previous } = statisticToShow[period] as TStatisticsPeriod;

  return (
    <>
      <IonRow>
        <ChipsPicker
          options={PERIOD_ARRAY}
          value={period}
          onChange={setPeriod as (val: string) => void}
        />
      </IonRow>
      <IonRow>
        <IonCol>
          <StatisticCard
            title="Average tip"
            value={formatAmount(
              actual.totalTips / 100 / actual.totalTipsCount || 0
            )}
            trend={calcTrend(
              actual.totalTips / actual.totalTipsCount || 0,
              previous.totalTips / previous.totalTipsCount || 0
            )}
          />
        </IonCol>
        <IonCol>
          <StatisticCard
            title="Number of tips"
            value={actual.totalTipsCount}
            trend={calcTrend(actual.totalTipsCount, previous.totalTipsCount)}
          />
        </IonCol>
        <IonCol>
          <StatisticCard
            title="Tip total value"
            value={formatAmount(actual.totalTips / 100)}
            trend={calcTrend(actual.totalTips, previous.totalTips)}
          />
        </IonCol>
      </IonRow>
    </>
  );
};

export default connect(mapState, mapDispatch)(StatisticsRow);
