import { IonIcon } from "@ionic/react";
import { checkmarkCircleOutline } from "ionicons/icons";
import React from "react";

interface IProps {
  verified?: boolean;
}

const VerifiedIcon = ({ verified = true }: IProps) => {
  if (!verified) return null;
  return <IonIcon icon={checkmarkCircleOutline} size="small" color="success" />;
};

export default VerifiedIcon;
