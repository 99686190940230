import React from "react";
import { IonIcon, IonItem, IonLabel, IonList } from "@ionic/react";
import useClipboard from "../../../utils/useClipboard";
import "./IconList.css";

interface IProps {
  inset?: boolean;
  data: { icon: string; text?: string | null }[];
}
const IconList = ({ data, inset = false }: IProps) => {
  const copyTextToClipboard = useClipboard();

  return (
    <IonList className="icon-list" inset={inset}>
      {data
        .filter(({ text }) => !!text)
        .map(({ icon, text }, index) => (
          <IonItem
            key={index}
            onClick={() => copyTextToClipboard({ string: text || "" })}
            detail={false}
            button
          >
            <IonIcon icon={icon} slot="start" />
            <IonLabel>{text}</IonLabel>
          </IonItem>
        ))}
    </IonList>
  );
};

export default IconList;
