import { useIonAlert } from "@ionic/react";
import { AlertInput } from "@ionic/core/dist/types/components/alert/alert-interface";

const useConfirmationModal = (
  handler: (value: any) => void,
  message?: string,
  inputs: AlertInput[] = []
) => {
  const [presentAlert] = useIonAlert();

  return (value?: any) =>
    presentAlert({
      header: "Are you sure?",
      subHeader: "Please, confirm your action",
      message,
      inputs,
      buttons: [
        { text: "Cancel", role: "cancel" },
        { text: "Yes", handler: !!value ? () => handler(value) : handler },
      ],
    });
};

export default useConfirmationModal;
