export const BRAND_CATEGORIES = [
  "All",
  "alcopop",
  "bottled ale",
  "cocktails",
  "draught beer",
  "draught cider",
  "drinkSupplier",
  "food supplier",
  "fridge beer",
  "fridge cider",
  "gin",
  "gluten free",
  "non alcohol",
  "postmix",
  "rum",
  "tonics and mixers",
  "vodka",
  "wine",
];
