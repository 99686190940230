import React, { useEffect, useState } from "react";
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonInput,
  IonItem,
  IonItemGroup,
  IonLabel,
  IonSpinner,
} from "@ionic/react";
import { useAppDispatch } from "../../../../../store/store";
import { signIn } from "../../../../../store/actions/user.actions";
import "./Login.css";

const Login = () => {
  const [email, setEmail] = useState<string>("yurii.leso@bfine.cz");
  const [password, setPassword] = useState<string>("Password1!");
  const [isProccessing, setIsProccessing] = useState<boolean>(false);
  const timeoutRef = React.useRef<any>();

  const call = useAppDispatch();
  useEffect(() => () => {
    clearTimeout(timeoutRef.current);
  });
  const handleSignIn = () => {
    setIsProccessing(true);
    call(signIn({ email, password }));
    timeoutRef.current = setTimeout(() => {
      setIsProccessing(false);
    }, 3000);
  };

  return (
    <IonCard className="login-card">
      <IonCardHeader>
        <IonCardTitle>Sign in</IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        <IonItemGroup>
          {/*<IonLabel position="floating">Email*</IonLabel>*/}
          <IonInput
            label="Email"
            labelPlacement="floating"
            type="email"
            value={email}
            onIonChange={({ detail }) =>
              setEmail(detail.value?.toLowerCase() || "")
            }
          />

          <IonInput
            label="Password"
            labelPlacement="floating"
            type="password"
            onIonChange={({ detail }) => setPassword(detail.value || "")}
          />
        </IonItemGroup>
        <IonButton
          expand="block"
          color="dark"
          onClick={handleSignIn}
          disabled={!email.length || password.length < 8}
        >
          {isProccessing ? (
            <IonSpinner color="light" name="crescent" />
          ) : (
            "Sign In"
          )}
        </IonButton>
      </IonCardContent>
    </IonCard>
  );
};

export default Login;
