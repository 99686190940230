import {
  IonBackButton,
  IonButtons,
  IonHeader,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React, { ReactNode } from "react";

interface IProps {
  title: string | ReactNode;
  defaultBackHref?: string;
  children?: ReactNode;
}

const TabPageHeader = ({ title, defaultBackHref = "", children }: IProps) => (
  <IonHeader>
    <IonToolbar color="dark">
      <IonButtons slot="start">
        <IonBackButton defaultHref={defaultBackHref} />
      </IonButtons>
      <IonTitle>{title}</IonTitle>
      {children}
    </IonToolbar>
  </IonHeader>
);

export default TabPageHeader;
