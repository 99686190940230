import { IonToast } from "@ionic/react";
import { useAppDispatch } from "../../../store/store";
import { hideToast } from "../../../store/actions/ui.actions";

interface TProps {
  active?: boolean;
  text: string;
}

const Toast = ({ active = true, text }: TProps) => {
  const call = useAppDispatch();

  const handleDidDismiss = () => call(hideToast());

  return (
    <IonToast
      isOpen={active}
      onDidDismiss={handleDidDismiss}
      message={text}
      duration={1500}
    />
  );
};

export default Toast;
