import React from "react";
import {
  IonButton,
  IonChip,
  IonCol,
  IonContent,
  IonIcon,
  IonPage,
  IonPopover,
  IonRow,
  IonSearchbar,
} from "@ionic/react";
import TabHeader from "../../../../layout/TabHeader/TabHeader";
import Header from "../../../../common/Header/Header";
import Filter from "../../components/Filter/Filter";
import { connect } from "react-redux";
import { TRootState } from "../../../../../store/store";
import { Profile } from "../../../../../types/DBTypes";
import { filterOutline } from "ionicons/icons";
import { IS_MOBILE } from "../../../../../utils";
import "./Individuals.css";
import IndividualsList from "../../components/IndividualsList/IndividualsList";
import dataToExcel from "../../../../../utils/DataToExcel";
import useSearch from "../../../../../hooks/useSearch";
import StatisticCard from "../../../../common/StatisticCard/StatisticCard";
import { StatisticsCardLoading } from "../../../outlets/components/StatisticsRow/StatisticsLoading";

interface IProps {
  users?: Profile[];
  total?: number;
}
const mapState = (state: TRootState) => ({
  total: state.individuals?.total,
  users: state.individuals?.items,
});

const IndividualPage = ({ users, total }: IProps) => {
  const [handleSearch, data] = useSearch<Profile>(users as Profile[]);

  return (
    <IonPage>
      <TabHeader title="Individuals">
        <IonSearchbar
          mode="ios"
          className="searchbar-left-aligned"
          onIonChange={handleSearch}
        />
      </TabHeader>

      <IonContent>
        <IonRow>
          <IonCol size="12">
            <IonRow>
              <IonCol sizeMd="4">
                {total ? (
                  <StatisticCard title="Total" value={total} />
                ) : (
                  <StatisticsCardLoading />
                )}
              </IonCol>
            </IonRow>
          </IonCol>
          <IonCol sizeMd="8" size="12">
            <div className="section-inset">
              <Header title="Users">
                {IS_MOBILE ? (
                  <IonChip id="filter-trigger">
                    <IonIcon icon={filterOutline} />
                  </IonChip>
                ) : (
                  <IonButton
                    onClick={() =>
                      dataToExcel(users as Profile[], "Individuals")
                    }
                  >
                    Export to excel
                  </IonButton>
                )}
              </Header>
              <IndividualsList data={data} />
            </div>
          </IonCol>

          {IS_MOBILE ? (
            <IonPopover
              keepContentsMounted
              trigger="filter-trigger"
              triggerAction="click"
            >
              <Filter />
            </IonPopover>
          ) : (
            <IonCol sizeMd="4" size="12">
              <div className="section-inset">
                <Header title="Filters" />

                <Filter />
              </div>
            </IonCol>
          )}
        </IonRow>
      </IonContent>
    </IonPage>
  );
};

export default connect(mapState)(IndividualPage);
