import { useEffect } from "react";
import { useParams } from "react-router";
import { connect } from "react-redux";
import { TRootState, useAppDispatch } from "../../../../../store/store";
import { loadTippingInfo } from "../../../../../store/actions/tipping.actions";
import TippingSelectWaiter from "../../components/OutletEmployees/OutletEmployees";
import TippingTip from "../../components/TippingTip/TippingTip";
import { TTippingData } from "../../../../../store/types/tipping.types";
import PageWrapper from "../PageWrapper/PageWrapper";

interface TProps {
  data: TTippingData;
}

const mapStateToProps = ({ tipping }: TRootState) => ({
  data: tipping.data,
});

const Home = ({ data }: TProps) => {
  const { slug } = useParams<{ slug: string }>();

  const call = useAppDispatch();

  useEffect(() => {
    if (slug === data?.slug) return;

    call(loadTippingInfo({ slug }));
  }, [call, data, slug]);

  return (
    <PageWrapper>
      {data?.type === "individual" && <TippingSelectWaiter data={data} />}
      {data?.type === "group" && <TippingTip data={data} />}
    </PageWrapper>
  );
};

export default connect(mapStateToProps)(Home);
