import React, { ReactNode } from "react";
import { IonChip, IonIcon, IonText } from "@ionic/react";
import { sadOutline } from "ionicons/icons";
import "./EmptyState.css";

interface IProps {
  onClick: () => void;
  callToAction?: string;
  children?: ReactNode;
}

const EmptyState = ({ onClick, callToAction, children }: IProps) => {
  return (
    <div className="empty-state">
      <IonIcon icon={sadOutline} size="large" />
      <IonText color="medium">{children}</IonText>
      {callToAction && <IonChip onClick={onClick}>{callToAction}</IonChip>}
    </div>
  );
};

export default EmptyState;
