import { createSelector } from "@reduxjs/toolkit";
import { TRootState } from "../store";
import { TTippingInfoPersonalOrGroup } from "../types/tipping.types";

const tippingSelector = (state: TRootState) => state.tipping;

export const getTippingInfo = createSelector(
  tippingSelector,
  (state) => state.data
);

export const getTippingType = createSelector(
  tippingSelector,
  (state) => state.data?.type
);

export const getIsContentLoading = createSelector(
  tippingSelector,
  (state) => !!state.data
);

export const getReceiverPicture = createSelector(
  [tippingSelector, (state, slug: string) => slug],
  (state, slug) =>
    state.data?.type === "group"
      ? state.data.picture
      : state.data?.employees.find(
          (emp: TTippingInfoPersonalOrGroup) => emp.slug === slug
        )?.picture
);
