import React from "react";
import { Business } from "../../../types/DBTypes";
import { IonAvatar, IonItem, IonLabel } from "@ionic/react";
import { handleImageUrl } from "../../../utils";
import avatarDefault from "../../../assets/images/avatar-default.svg";
import { JSX } from "@ionic/core/components";

interface IProps extends JSX.IonItem {
  item: Business;
}

const BusinessItem = ({ item, ...props }: IProps) => {
  const { name, ownerProfile } = item;

  return (
    <IonItem button {...props}>
      <IonAvatar slot="start">
        <img
          src={
            ownerProfile?.picture
              ? handleImageUrl(ownerProfile?.picture)
              : avatarDefault
          }
          alt={name}
        />
      </IonAvatar>
      <IonLabel>{name}</IonLabel>
    </IonItem>
  );
};

export default BusinessItem;
