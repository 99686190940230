import React from "react";
import {
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonText,
} from "@ionic/react";
import { formatAmount, formatDateTime } from "../../../../../utils";
import "./Receipt.css";

interface TProps {
  amount: number;
  fee: number;
  feeIncluded: boolean;
  transactionId: string;
  createdAt: string;
}

const Receipt = ({
  amount,
  fee,
  transactionId,
  feeIncluded,
  createdAt,
}: TProps) => {
  return (
    <div className="receipt">
      <IonList lines="none">
        <IonListHeader>
          <IonLabel>Tip Receipt</IonLabel>
        </IonListHeader>

        <IonItem>
          <IonLabel>Tip Total:</IonLabel>
          <IonText slot="end">
            {formatAmount(amount + (feeIncluded ? fee : 0))}
          </IonText>
        </IonItem>
        <IonItem>
          <IonLabel className="ion-text-center" color="dark">
            <p>{formatDateTime(createdAt)}</p>
          </IonLabel>
        </IonItem>
      </IonList>
      <IonItem lines="none">
        <IonLabel className="ion-text-center" color="dark">
          <p>{transactionId}</p>
        </IonLabel>
      </IonItem>
    </div>
  );
};

export default Receipt;
