import { TAction } from "../store";
import { call, put, takeLatest } from "redux-saga/effects";
import { requestController } from "../../utils";
import { updateProfileSuccess } from "../actions/individuals.actions";
import { TCreateProfileEnterprisePayload } from "../types/enterprise.types";
import {
  CREATE_PROFILE_ENTERPRISE,
  createProfileEnterpriseSuccess,
  LOAD_PROFILES_ENTERPRISE,
  loadProfilesEnterpriseSuccess,
} from "../actions/enterprise.actions";
import { API } from "aws-amplify";
import { listProfileEnterprises } from "../../graphql/queries";
import { ProfileEnterprise } from "../../types/DBTypes";

function* createProfileEnterpriseSaga({
  payload,
}: TAction<TCreateProfileEnterprisePayload>) {
  const { input } = payload;
  const { data } = yield call(
    requestController.post,
    `/api/enterprise/profile`,
    input,
    true
  );
  yield put(
    createProfileEnterpriseSuccess({ item: data as ProfileEnterprise })
  );
}

function* loadProfileEnterpriseSaga() {
  const { data } = yield API.graphql({
    query: listProfileEnterprises,
  });

  yield put(
    loadProfilesEnterpriseSuccess({ items: data.listProfileEnterprises?.items })
  );
}

export default function* enterpriseSagas() {
  yield takeLatest(CREATE_PROFILE_ENTERPRISE, createProfileEnterpriseSaga);
  yield takeLatest(LOAD_PROFILES_ENTERPRISE, loadProfileEnterpriseSaga);
}
