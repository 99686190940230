import { TAction } from "../store";
import {
  CREATE_BRAND_SUCCESS,
  DELETE_BRAND_SUCCESS,
  LOAD_BRANDS_MORE_SUCCESS,
  LOAD_BRANDS_SUCCESS,
  UPDATE_BRAND_SUCCESS,
} from "../actions/brand.actions";
import {
  TCreateBrandSuccessPayload,
  TLoadBrandsSuccessPayload,
  TState,
  TUpdateBrandSuccessPayload,
} from "../types/brand.types";

//----------------------------------------------------------------------------------------------------------------------
const initialState: TState = {};
//----------------------------------------------------------------------------------------------------------------------

const individualsReducers = (
  state = initialState,
  { type, payload }: TAction<any>
): TState => {
  switch (type) {
    case LOAD_BRANDS_SUCCESS: {
      const { data } = payload as TLoadBrandsSuccessPayload;
      return {
        ...state,
        // @ts-ignore
        items: data.items,
        nextToken: data.nextToken || undefined,
      };
    }
    case LOAD_BRANDS_MORE_SUCCESS: {
      const { data } = payload as TLoadBrandsSuccessPayload;
      return {
        ...state,
        // @ts-ignore
        items: state.items?.concat(data.items),
        nextToken: data.nextToken || undefined,
      };
    }
    case UPDATE_BRAND_SUCCESS: {
      const { data } = payload as TUpdateBrandSuccessPayload;
      return {
        ...state,
        // @ts-ignore
        items: state.items?.map((item) => (item.id === data.id ? data : item)),
      };
    }
    case CREATE_BRAND_SUCCESS: {
      const { data } = payload as TCreateBrandSuccessPayload;
      return {
        ...state,
        // @ts-ignore
        items: [data, ...state.items],
      };
    }

    case DELETE_BRAND_SUCCESS: {
      const { data } = payload;
      return {
        ...state,
        // @ts-ignore
        items: state.items?.filter((item) => item.id !== data.id),
      };
    }
    default:
      return state;
  }
};

export default individualsReducers;
