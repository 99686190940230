import React from "react";
import { IonAvatar, IonChip, IonItem, IonLabel } from "@ionic/react";
import avatarDefault from "../../../../../assets/images/avatar-default.svg";
import { formatAmount, handleImageUrl } from "../../../../../utils";
import formatDateTime from "../../../../../utils/formatDateTime";
import { TipSplit } from "../../../../../types/DBTypes";

interface SProps {
  split: TipSplit;
}

const SplitItem = ({ split }: SProps) => {
  const { id, ownerProfile, createdAt, amount, status } = split;
  const businessName = ownerProfile?.business?.name || "";
  const avatarPath = ownerProfile?.picture
    ? handleImageUrl(ownerProfile?.picture)
    : avatarDefault;
  return (
    <IonItem routerLink={`/split/${id}`}>
      <IonAvatar slot="start">
        <img alt={businessName} src={avatarPath} />
      </IonAvatar>
      <IonLabel>
        <h2>{businessName}</h2>
        {createdAt && <p>{formatDateTime(createdAt)}</p>}
      </IonLabel>

      <IonChip
        color={
          status === "fail" || status === "partiallySent" ? "danger" : "success"
        }
      >
        {formatAmount(amount)}
      </IonChip>
    </IonItem>
  );
};

export default SplitItem;
