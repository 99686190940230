import { IonItem, IonLabel } from "@ionic/react";
import React from "react";

interface IFieldItem {
  title: string;
  children?: string | null;
}

const FieldItem = ({ title, children }: IFieldItem) => (
  <IonItem>
    <IonLabel>{title}</IonLabel>
    <IonLabel slot="end">{children}</IonLabel>
  </IonItem>
);
export default FieldItem;
