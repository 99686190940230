import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonPage,
  IonRow,
  IonSearchbar,
} from "@ionic/react";
import TabHeader from "../../../../layout/TabHeader/TabHeader";
import React, { useState } from "react";
import { addSharp } from "ionicons/icons";
import "./BrandAndSupplier.css";
import { TRootState, useAppDispatch } from "../../../../../store/store";
import BrandCardSkeleton from "../../components/BrandCard/BrandCardSkeleton";
import { connect } from "react-redux";
import {
  getBrands,
  getBrandsHasMore,
} from "../../../../../store/selectors/brand.selectors";
import { Brand } from "../../../../../types/DBTypes";
import BrandCard from "../../components/BrandCard/BrandCard";
import CategoriesFilter from "../../components/CategoriesFilter/CategoriesFilter";
import BrandModal from "../../components/BrandModal/BrandModal";
import useSearch from "../../../../../hooks/useSearch";
import { loadBrandsMore } from "../../../../../store/actions/brand.actions";

interface IProps {
  brands?: Brand[];
  hasMore: boolean;
}
const mapState = (state: TRootState) => ({
  brands: getBrands(state),
  hasMore: getBrandsHasMore(state),
});

const BrandAndSupplierPage = ({ brands, hasMore }: IProps) => {
  const [brand, setBrand] = useState<Brand | null>(null);
  const [handleSearch, data] = useSearch(brands as Brand[]);

  const call = useAppDispatch();

  return (
    <IonPage className="page--brands">
      <TabHeader title="Brand and suppliers">
        <IonSearchbar
          mode="ios"
          className="searchbar-left-aligned"
          onIonChange={handleSearch}
        />
        <IonButton onClick={() => setBrand({} as Brand)} slot="end">
          <IonIcon icon={addSharp} />
        </IonButton>
      </TabHeader>
      <IonContent>
        <CategoriesFilter />
        <IonGrid>
          <IonRow>
            {data
              ? data.map((brand) => (
                  <IonCol size="6" sizeLg="3" sizeXl="2" key={brand.id}>
                    <BrandCard brand={brand} onClick={setBrand} />
                  </IonCol>
                ))
              : Array(30).fill(
                  <IonCol size="6" sizeLg="3" sizeXl="2">
                    <BrandCardSkeleton />
                  </IonCol>
                )}
          </IonRow>
          {hasMore && (
            <IonInfiniteScroll
              onIonInfinite={(ev) => {
                if (!hasMore) return;
                call(loadBrandsMore());
                setTimeout(() => ev.target.complete(), 500);
              }}
            >
              <IonInfiniteScrollContent />
            </IonInfiniteScroll>
          )}
        </IonGrid>
        <BrandModal setBrand={setBrand} brand={brand} />
      </IonContent>
    </IonPage>
  );
};

export default connect(mapState)(BrandAndSupplierPage);
