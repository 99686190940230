import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonChip,
  IonIcon,
  IonLabel,
  IonText,
} from "@ionic/react";
import { FC } from "react";
import "./StatisticCard.css";
import { chevronDown, chevronUp } from "ionicons/icons";

interface StatisticCardProps {
  title: string;
  value: string | number;
  trend?: number;
}

const StatisticCard: FC<StatisticCardProps> = ({ title, value, trend }) => {
  const color = trend! >= 0 ? "success" : "danger";
  return (
    <IonCard className="statistic-card">
      <IonCardHeader>
        <IonCardTitle>{title}</IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        <IonText color="dark">{value}</IonText>
        {trend !== undefined && (
          <IonChip>
            {/*<div className="stat-icon">{icon}</div>*/}
            {/*TODO colors & visual*/}
            <IonIcon
              icon={trend >= 0 ? chevronUp : chevronDown}
              color={color}
            />
            <IonLabel color={color}>{trend.toFixed(2)}%</IonLabel>
          </IonChip>
        )}
      </IonCardContent>
    </IonCard>
  );
};

export default StatisticCard;
