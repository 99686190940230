import React from "react";
import TabHeader from "../../../layout/TabHeader/TabHeader";
import Header from "../../../common/Header/Header";
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonPage,
  IonRow,
} from "@ionic/react";
import SplitList from "../components/SplitList/SplitList";
import { connect } from "react-redux";
import { loadSplits } from "../../../../store/actions/splits.actions";
import { TLoadSplitsPayload } from "../../../../store/types/splits.types";
import { TRootState } from "../../../../store/store";
import {
  getSplits,
  getSplitsHasMore,
} from "../../../../store/selectors/splits.selectors";
import { TipSplit } from "../../../../types/DBTypes";
import Filter from "../components/Filter/Filter";

const mapState = (state: TRootState) => ({
  splits: getSplits(state),
  hasMore: getSplitsHasMore(state),
});

const mapDispatch = (dispatch: any) => ({
  onLoadSplits: (params?: TLoadSplitsPayload) =>
    dispatch(loadSplits(params || {})),
});

interface IProps {
  hasMore: boolean;
  splits: TipSplit[];
  onLoadMoreSplits: () => void;
}

const SplitHome = ({ splits, onLoadMoreSplits, hasMore }: IProps) => {
  return (
    <IonPage>
      <TabHeader title="Splits" />

      <IonContent fullscreen>
        <IonGrid>
          <IonRow>
            <IonCol sizeLg="8" size="12">
              <Header title="Transactions" />
              <SplitList splits={splits} />
              {hasMore && (
                <IonInfiniteScroll
                  onIonInfinite={(ev) => {
                    if (!hasMore) return;
                    onLoadMoreSplits();
                    setTimeout(() => ev.target.complete(), 500);
                  }}
                >
                  <IonInfiniteScrollContent />
                </IonInfiniteScroll>
              )}
              {hasMore && (
                <IonButton
                  color="light"
                  expand="full"
                  onClick={onLoadMoreSplits}
                >
                  Load More
                </IonButton>
              )}
            </IonCol>

            <IonCol sizeLg="4" size="12">
              <Header title="Splits" />
              <Filter />
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default connect(mapState, mapDispatch)(SplitHome);
