import Header from "../../../../common/Header/Header";
import { IonIcon, IonText } from "@ionic/react";
import IndividualsList from "../../../individuals/components/IndividualsList/IndividualsList";
import React from "react";
import { BusinessEmployee } from "../../../../../types/DBTypes";
import { sadOutline } from "ionicons/icons";

interface IProps {
  data?: BusinessEmployee[];
}

const EmployeesList = ({ data }: IProps) => {
  return (
    <div className="section-inset">
      <Header title="Employees" />

      {data?.length ? (
        <IndividualsList
          data={data?.map(({ profile, jobRole, confirmed }) => ({
            ...profile,
            jobRole,
            verified: confirmed,
            stripeAcc: !!profile?.details?.tippingAccount?.verified,
          }))}
        />
      ) : (
        <div className="ion-padding ion-text-center">
          <IonIcon icon={sadOutline} size="large" />
          <IonText>
            <p>Outlet hasn't any employees</p>
          </IonText>
        </div>
      )}
    </div>
  );
};
export default EmployeesList;
