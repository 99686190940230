import {
  IonButton,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonMenu,
  IonMenuToggle,
  IonToolbar,
} from "@ionic/react";

import { useLocation } from "react-router-dom";
import {
  business,
  businessSharp,
  card,
  cardSharp,
  exitOutline,
  person,
  personSharp,
  restaurant,
  restaurantSharp,
} from "ionicons/icons";
import "./Menu.css";
import logo from "../../../assets/images/logo.svg";
import { useAppDispatch } from "../../../store/store";
import { signOut } from "../../../store/actions/user.actions";
import { useHistory } from "react-router";

type menuItem = {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
};

const menu: menuItem[] = [
  {
    title: "Individuals",
    url: "/individual",
    iosIcon: person,
    mdIcon: personSharp,
  },
  {
    title: "Outlets",
    url: "/outlet",
    iosIcon: restaurant,
    mdIcon: restaurantSharp,
  },
  {
    title: "Brand and suppliers",
    url: "/brands",
    iosIcon: business,
    mdIcon: businessSharp,
  },
  // {
  //   title: "Overview",
  //   url: "/overview",
  //   iosIcon: calendarNumber,
  //   mdIcon: calendarNumberSharp,
  // },
  {
    title: "Enterprise",
    url: "/enterprise",
    iosIcon: business,
    mdIcon: businessSharp,
  },
  {
    title: "Splits",
    url: "/split",
    iosIcon: card,
    mdIcon: cardSharp,
  },
  // {
  //   title: "Sign ups",
  //   url: "/page/sign-ups",
  //   iosIcon: personAdd,
  //   mdIcon: personAddSharp,
  // },
];

const Menu: React.FC = () => {
  const { pathname } = useLocation();
  const history = useHistory();
  const call = useAppDispatch();
  const handleSignOut = () => call(signOut());

  const handleClickHome = () => {
    if (pathname !== "/individual") history.push("/");
  };

  return (
    <IonMenu contentId="authorized" type="reveal">
      <IonHeader>
        <IonToolbar onClick={handleClickHome}>
          <IonImg
            src={logo}
            alt="Tipmi logo"
            className="ion-padding"
            style={{ cursor: "pointer" }}
          />
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {menu.map(({ title, url, iosIcon, mdIcon }, index) => (
          <IonMenuToggle key={index} autoHide={false}>
            <IonItem
              className={pathname.startsWith(url) ? "selected" : ""}
              routerLink={url}
              routerDirection="none"
              lines="none"
              detail={false}
            >
              <IonIcon slot="start" ios={iosIcon} md={mdIcon} />
              <IonLabel>{title}</IonLabel>
            </IonItem>
          </IonMenuToggle>
        ))}
      </IonContent>
      <IonFooter>
        <IonButton fill="clear" color="medium" onClick={handleSignOut}>
          <IonIcon icon={exitOutline} slot="start" />
          Sign out
        </IonButton>
      </IonFooter>
    </IonMenu>
  );
};

export default Menu;
