import { createAction } from "@reduxjs/toolkit";
import { TSignInPayload } from "../types/user.types";

export const SIGN_IN = "[USER] SIGN_IN";
export const SIGN_IN_SUCCESS = "[USER] SIGN_IN_SUCCESS";
export const SIGN_OUT = "[USER] SIGN_OUT";
export const SIGN_OUT_SUCCESS = "[USER] SIGN_OUT_SUCCESS";

export const signIn = createAction<TSignInPayload>(SIGN_IN);
export const signInSuccess = createAction(SIGN_IN_SUCCESS);
export const signOut = createAction(SIGN_OUT);
export const signOutSuccess = createAction(SIGN_OUT_SUCCESS);
