import { createAction } from "@reduxjs/toolkit";
import {
  TGetOutletBySlugPayload,
  TGetOutletBySlugSuccessPayload,
  TGetOutletStatisticsPayload,
  TGetOutletStatisticsSuccessPayload,
  TLoadOutletsPayload,
  TLoadOutletsSuccessPayload,
  TUpdateBusinessPayload,
  TUpdateBusinessSuccessPayload,
} from "../types/outlets.types";

export const LOAD_OUTLETS = "[OUTLETS] LOAD_OUTLETS";
export const LOAD_OUTLETS_SUCCESS = "[OUTLETS] LOAD_OUTLETS_SUCCESS";

export const GET_OUTLET_BY_SLUG = "[OUTLETS] GET_OUTLET_BY_SLUG";
export const GET_OUTLET_BY_SLUG_SUCCESS =
  "[OUTLETS] GET_OUTLET_BY_SLUG_SUCCESS";
export const GET_OUTLET_STATISTICS = "[OUTLETS] GET_OUTLET_STATISTICS";
export const GET_OUTLET_STATISTICS_SUCCESS =
  "[OUTLETS] GET_OUTLET_STATISTICS_SUCCESS";

export const UPDATE_BUSINESS = "[OUTLETS] UPDATE_BUSINESS";
export const UPDATE_BUSINESS_SUCCESS = "[OUTLETS] UPDATE_BUSINESS_SUCCESS";

export const loadOutlets = createAction<TLoadOutletsPayload>(LOAD_OUTLETS);

export const loadOutletsSuccess =
  createAction<TLoadOutletsSuccessPayload>(LOAD_OUTLETS_SUCCESS);

export const getOutletBySlug =
  createAction<TGetOutletBySlugPayload>(GET_OUTLET_BY_SLUG);
export const getOutletBySlugSuccess =
  createAction<TGetOutletBySlugSuccessPayload>(GET_OUTLET_BY_SLUG_SUCCESS);

export const getOutletStatistics = createAction<TGetOutletStatisticsPayload>(
  GET_OUTLET_STATISTICS
);

export const getOutletStatisticsSuccess =
  createAction<TGetOutletStatisticsSuccessPayload>(
    GET_OUTLET_STATISTICS_SUCCESS
  );

export const updateBusiness =
  createAction<TUpdateBusinessPayload>(UPDATE_BUSINESS);
export const updateBusinessSuccess =
  createAction<TUpdateBusinessSuccessPayload>(UPDATE_BUSINESS_SUCCESS);
