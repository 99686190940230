import { IonChip, IonIcon, IonLabel, NavContext } from "@ionic/react";
import { useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { connect } from "react-redux";
import TippingForm from "../TippingForm/TippingForm";
import { arrowBack } from "ionicons/icons";
import ProfileHeader from "../ProfileHeader/ProfileHeader";
import PaymentModalProvider from "../PaymentModalProvider/PaymentModalProvider";
import { TRootState, useAppDispatch } from "../../../../../store/store";
import { loadTippingInfo } from "../../../../../store/actions/tipping.actions";

const mapStateToProps = ({ tipping }: TRootState) => ({
  data: tipping.data,
});

const TippingTip = ({ data }: any) => {
  const { slug, employeeSlug } = useParams<{
    slug: string;
    employeeSlug: string;
  }>();

  const [headerProps, setHeaderProps] = useState({
    title: data?.name,
    subtitle: data?.location,
    picture: data?.picture,
  });

  const { navigate } = useContext(NavContext);
  const handleBack = useCallback(() => {
    navigate(`/${slug}`, "back");
  }, [navigate, slug]);

  const call = useAppDispatch();
  useEffect(() => {
    if (data?.slug) return;
    call(loadTippingInfo({ slug }));
  }, [data, call, slug]);

  useEffect(() => {
    if (data.type === "individual") {
      const individualInfo = data.employees.find(
        (employee: any) => employee.slug === employeeSlug
      );

      setHeaderProps({
        title: individualInfo.name,
        subtitle: [individualInfo.role, data.name].join(" at "),
        picture: individualInfo.picture,
      });
    }
  }, [data, employeeSlug]);

  if (!data) return null;

  return (
    <>
      <ProfileHeader {...headerProps}>
        {data.type === "individual" ? (
          <IonChip onClick={handleBack}>
            <IonIcon icon={arrowBack} />
            <IonLabel>Team</IonLabel>
          </IonChip>
        ) : undefined}
      </ProfileHeader>
      <PaymentModalProvider>
        <TippingForm
          picture={headerProps.picture}
          name={data.type === "individual" ? headerProps?.title : "the team"}
        />
      </PaymentModalProvider>
    </>
  );
};
export default connect(mapStateToProps)(TippingTip);
