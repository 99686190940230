import { spawn } from "redux-saga/effects";
import userSagas from "./sagas/user.sagas";
import tippingSagas from "./sagas/tipping.sagas";
import individualsSagas from "./sagas/individuals.sagas";
import outletsSagas from "./sagas/outlets.sagas";
import brandSagas from "./sagas/brand.sagas";
import splitsSaga from "./sagas/splits.saga";
import enterpriseSagas from "./sagas/enterprise.sagas";

export default function* saga() {
  yield spawn(userSagas);
  yield spawn(tippingSagas);
  yield spawn(individualsSagas);
  yield spawn(outletsSagas);
  yield spawn(brandSagas);
  yield spawn(splitsSaga);
  yield spawn(enterpriseSagas);
}
