import { put, takeLatest } from "redux-saga/effects";
import { Auth } from "aws-amplify";
import {
  SIGN_IN,
  SIGN_OUT,
  signInSuccess,
  signOutSuccess,
} from "../actions/user.actions";
import { showToast } from "../actions/ui.actions";
import { TAction } from "../store";
import { TSignInPayload } from "../types/user.types";
import safe from "../utils/safe";

function* signInSaga({ payload }: TAction<TSignInPayload>) {
  try {
    const { email, password } = payload;
    const { signInUserSession } = yield Auth.signIn({
      username: email,
      password,
    });

    const isAdmin =
      signInUserSession.accessToken.payload["cognito:groups"]?.includes(
        "admin"
      );

    if (isAdmin) {
      yield put(signInSuccess());
    } else {
      yield Auth.signOut();
      yield put(
        showToast({ text: "You don't have an access to sign in there." })
      );
    }
  } catch (e: any) {
    yield put(showToast({ text: e?.message }));
  }
}

function* signOutSaga() {
  try {
    yield Auth.signOut();
    yield put(signOutSuccess());
  } catch (e: any) {
    yield put(showToast({ text: e?.message }));
  }
}

export default function* userSagas() {
  yield takeLatest(SIGN_IN, safe(signInSaga));
  yield takeLatest(SIGN_OUT, safe(signOutSaga));
}
