import { put, select, takeLatest } from "redux-saga/effects";
import safe from "../utils/safe";
import {
  LOAD_SPLIT_DETAILS,
  LOAD_SPLITS,
  LOAD_SPLITS_MORE,
  loadSplitDetailsSuccess,
  loadSplitsMoreSuccess,
  loadSplitsSuccess,
  REPEAT_EMPLOYEE_TRANSFER,
  REPEAT_EMPLOYEE_TRANSFER_BATCH,
  repeatEmployeeTransferSuccess,
} from "../actions/splits.actions";
import { API } from "aws-amplify";
import { TAction } from "../store";
import {
  TLoadSplitDetailsPayload,
  TLoadSplitsPayload,
  TRepeatEmployeeTransferPayload,
} from "../types/splits.types";
import { getTipSplit, listTipSplits } from "../../graphql/queries";
import { getSplitsNextToken } from "../selectors/splits.selectors";
import { requestController } from "../../utils";
import {
  ModelTipSplitFilterInput,
  TransactionStatus,
} from "../../types/DBTypes";

const LIMIT = 50;

function* loadSplitsSaga({ payload }: TAction<TLoadSplitsPayload>) {
  const { success, failed, owner } = payload;

  let filter: ModelTipSplitFilterInput = {
    or: [],
  };

  if (success && filter.or)
    filter.or.push({ status: { eq: TransactionStatus.sent } });
  if (failed && filter.or)
    filter.or.push(
      { status: { eq: TransactionStatus.fail } },
      { status: { eq: TransactionStatus.partiallySent } }
    );
  if (owner?.length) filter = { ...filter, owner: { eq: owner } };

  // @ts-ignore TODO types
  const res = yield API.graphql({
    query: listTipSplits,
    variables: {
      filter,
      limit: LIMIT,
    },
  });

  // TODO handle error
  if (!res?.data?.listTipSplits) return;

  const { nextToken, items } = res?.data?.listTipSplits;
  yield put(loadSplitsSuccess({ nextToken, items }));
}

function* loadSplitsMoreSaga() {
  //@ts-ignore TODO types
  const nextTokenOld = yield select(getSplitsNextToken);

  // @ts-ignore TODO types
  const res = yield API.graphql({
    query: listTipSplits,
    variables: {
      limit: LIMIT,
      nextToken: nextTokenOld,
    },
  });

  // TODO handle error
  if (!res?.data?.listTipSplits) return;

  const { nextToken, items } = res?.data?.listTipSplits;
  yield put(loadSplitsMoreSuccess({ nextToken, items }));
}

function* loadSplitDetailsSaga({ payload }: TAction<TLoadSplitDetailsPayload>) {
  const { id } = payload;

  // @ts-ignore TODO types
  const res = yield API.graphql({
    query: getTipSplit,
    variables: {
      id,
    },
  });

  // TODO handle error

  const splitDetails = res?.data?.getTipSplit;
  if (!splitDetails) return;

  yield put(loadSplitDetailsSuccess({ splitDetails }));
}

function* repeatEmployeeTransferSaga({
  payload,
}: TAction<TRepeatEmployeeTransferPayload>) {
  const { id } = payload;

  // @ts-ignore
  const result = yield requestController.post(
    `/api/merchant/stripe/transfers/${id}/repeat`
  );

  const { transfer } = result?.data;
  yield put(repeatEmployeeTransferSuccess({ id, transfer }));
}

function* repeatEmployeeTransferBatchSaga({
  payload,
}: TAction<{ ids: string[] }>) {
  const { ids } = payload;

  for (const id of ids) {
    // @ts-ignore
    const result = yield requestController.post(
      `/api/merchant/stripe/transfers/${id}/repeat`
    );

    const { transfer } = result?.data;
    yield put(repeatEmployeeTransferSuccess({ id, transfer }));
  }
}

export default function* splitsSagas() {
  yield takeLatest(LOAD_SPLITS, safe(loadSplitsSaga));
  yield takeLatest(LOAD_SPLITS_MORE, safe(loadSplitsMoreSaga));
  yield takeLatest(LOAD_SPLIT_DETAILS, safe(loadSplitDetailsSaga));

  yield takeLatest(REPEAT_EMPLOYEE_TRANSFER, safe(repeatEmployeeTransferSaga));
  yield takeLatest(
    REPEAT_EMPLOYEE_TRANSFER_BATCH,
    safe(repeatEmployeeTransferBatchSaga)
  );
}
