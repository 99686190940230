import React from "react";
import { IonSkeletonText } from "@ionic/react";

interface IProps {
  width?: string;
  height?: string;
}
const Skeleton = ({ width = "100%", height }: IProps) => (
  <IonSkeletonText animated style={{ width, height }} />
);

export default Skeleton;
