import { IonAvatar, IonItem, IonLabel } from "@ionic/react";
import "./Employee.css";
import handleImageUrl from "../../../../../utils/handleImageUrl";

interface TProps {
  firstName: string;
  jobRole: string;
  picture: string;
  onClick?: () => void;
  routerLink?: string;
}

const Waiter = ({
  firstName,
  jobRole,
  picture,
  onClick,
  routerLink,
}: TProps) => {
  return (
    <IonItem
      routerLink={routerLink}
      detail
      onClick={onClick}
      className="employee-item"
    >
      <IonAvatar slot="start" className="employee-avatar">
        <img src={handleImageUrl(picture, 150)} alt={firstName} />
      </IonAvatar>
      <IonLabel className="employee-info">
        <h3>{firstName}</h3>
        {jobRole && <p>{jobRole.charAt(0).toUpperCase() + jobRole.slice(1)}</p>}
      </IonLabel>
    </IonItem>
  );
};

export default Waiter;
