import * as XLSX from "xlsx";

type TDataItem = { [key: string]: any };
type TPrepareData = (data: TDataItem[]) => Object[];
const prepareData: TPrepareData = (data) =>
  // each item in data is an object
  data.map((item) => {
    const result: { [key: string]: string | number } = {};

    // each item in object can be a string, number, or object
    Object.keys(item).forEach((key) => {
      // if item is a string or number, add it to result
      if (item[key] === null || typeof item[key] !== "object")
        return (result[key] = item[key]);

      // if item is an object, add each key to result
      Object.keys(item[key]).forEach((subKey) => {
        result[`${key}.${subKey}`] = item[key][subKey];
      });
    });

    return result;
  });

type TDataToExcel = (data: Object[], fileName: string) => void;
const dataToExcel: TDataToExcel = (data, fileName) => {
  if (!data?.length) return alert("No data to export");

  let readyData = prepareData(data);

  const worksheet = XLSX.utils.json_to_sheet(readyData);
  const workbook = XLSX.utils.book_new();

  XLSX.utils.book_append_sheet(workbook, worksheet, fileName);

  XLSX.writeFile(workbook, `${fileName}.xlsx`);
};

export default dataToExcel;
