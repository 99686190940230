import { applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";

import { compose, createStore } from "@reduxjs/toolkit";
import rootSaga from "./mainSaga";
import mainReducer from "./mainReducer";
import { showToast } from "./actions/ui.actions";

const sagaMiddleware = createSagaMiddleware({
  onError: (error: Error, errorInfo: any) => {
    console.log("SAGA ERROR", { error, errorInfo });

    store.dispatch(
      showToast({
        text: "App crashed, please send logs to yurii.leso@tipinc.tech and reload",
      })
    );
  },
});

// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
  mainReducer,
  // Done according docs.
  // https://redux-saga.js.org/docs/introduction/GettingStarted
  // @ts-ignore
  composeEnhancers(applyMiddleware(sagaMiddleware))
);

sagaMiddleware.run(rootSaga);
