import { createAction } from "@reduxjs/toolkit";
import {
  TLoadIndividualsPayload,
  TLoadIndividualsSuccessPayload,
  TUpdateProfilePayload,
  TUpdateProfileSlugPayload,
  TUpdateProfileSuccessPayload,
} from "../types/individuals.types";

export const LOAD_INDIVIDUALS = "[INDIVIDUALS] LOAD_INDIVIDUALS";
export const LOAD_INDIVIDUALS_SUCCESS =
  "[INDIVIDUALS] LOAD_INDIVIDUALS_SUCCESS";

export const UPDATE_PROFILE = "[INDIVIDUALS] UPDATE_PROFILE";
export const UPDATE_PROFILE_SUCCESS = "[INDIVIDUALS] UPDATE_PROFILE_SUCCESS";

export const UPDATE_PROFILE_SLUG = "[INDIVIDUALS] UPDATE_PROFILE_SLUG";

export const loadIndividuals =
  createAction<TLoadIndividualsPayload>(LOAD_INDIVIDUALS);

export const loadIndividualsSuccess =
  createAction<TLoadIndividualsSuccessPayload>(LOAD_INDIVIDUALS_SUCCESS);

export const updateProfile =
  createAction<TUpdateProfilePayload>(UPDATE_PROFILE);
export const updateProfileSuccess = createAction<TUpdateProfileSuccessPayload>(
  UPDATE_PROFILE_SUCCESS
);
export const updateProfileSlug =
  createAction<TUpdateProfileSlugPayload>(UPDATE_PROFILE_SLUG);
