import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonChip,
  IonCol,
  IonRow,
  IonSkeletonText,
} from "@ionic/react";
import { FC } from "react";
import ChipsPicker from "../../../../common/ChipsPicker/ChipsPicker";

export const StatisticsCardLoading = () => (
  <IonCard className="statistic-card">
    <IonCardHeader>
      <IonSkeletonText
        animated
        style={{ width: "60%", height: "2rem" }}
      ></IonSkeletonText>
    </IonCardHeader>
    <IonCardContent>
      <IonSkeletonText
        animated
        style={{ width: "40%", height: "3rem" }}
      ></IonSkeletonText>
      <IonChip>
        <IonSkeletonText
          animated
          style={{ width: "60px", height: "1rem" }}
        ></IonSkeletonText>
      </IonChip>
    </IonCardContent>
  </IonCard>
);

const StatisticLoading: FC = () => {
  return (
    <>
      <IonRow>
        <ChipsPicker
          options={["Day", "Week", "Month"]}
          value={"Day"}
          onChange={(val: string) => {}}
        />
      </IonRow>
      <IonRow>
        <IonCol>
          <StatisticsCardLoading />
        </IonCol>
        <IonCol>
          <StatisticsCardLoading />
        </IonCol>
        <IonCol>
          <StatisticsCardLoading />
        </IonCol>
      </IonRow>
    </>
  );
};

export default StatisticLoading;
