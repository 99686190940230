import { createSelector } from "@reduxjs/toolkit";
import { TRootState } from "../store";

const individualsSelector = (state: TRootState) => state.individuals;

export const getIndividualsNextToken = createSelector(
  individualsSelector,
  (state) => state?.nextToken
);

export const getIndividualsTotal = createSelector(
  individualsSelector,
  (state) => state?.total
);
