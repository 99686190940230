import { useContext } from "react";
import { IonButton, IonItem, IonLabel } from "@ionic/react";
import { FieldContext } from "./EnterpriseUserCreate";
import { useAppDispatch } from "../../../../../store/store";
import { createProfileEnterprise } from "../../../../../store/actions/enterprise.actions";
import FieldItem from "../../components/FieldItem/FieldItem";
import { useHistory } from "react-router";

const Step4 = () => {
  const history = useHistory();
  const call = useAppDispatch();
  const context = useContext(FieldContext);
  if (!context) return null;
  const { fields, setTitle } = context;
  setTitle("Review and submit");

  const handleSubmit = () => {
    call(createProfileEnterprise({ input: fields }));
    history.push("/enterprise");
  };

  return (
    <>
      <FieldItem title="Company Name">{fields.name}</FieldItem>
      <FieldItem title="Company Registered number">
        {fields.companyNumber}
      </FieldItem>
      <FieldItem title="Address line 1">{fields.address.address}</FieldItem>
      <FieldItem title="Address line 2">{fields.address.address2}</FieldItem>
      <FieldItem title="City">{fields.address.city}</FieldItem>
      <FieldItem title="Region">{fields.address.region}</FieldItem>
      <FieldItem title="Postal code">{fields.address.postcode}</FieldItem>
      <FieldItem title="Country">{fields.address.country}</FieldItem>
      <FieldItem title="Contact Name">{fields.contactDetails.name}</FieldItem>
      <FieldItem title="Contact Job Role">
        {fields.contactDetails.role}
      </FieldItem>
      <FieldItem title="Contact Email">{fields.contactDetails.email}</FieldItem>
      <FieldItem title="Contact Phone">{fields.contactDetails.phone}</FieldItem>
      <FieldItem title="Email for account">{fields.email}</FieldItem>
      <IonItem>
        <IonLabel slot="end">
          <IonButton onClick={handleSubmit}>Submit</IonButton>
        </IonLabel>
      </IonItem>
    </>
  );
};
export default Step4;
