import { createSelector } from "@reduxjs/toolkit";
import { TRootState } from "../store";

const outletsSelector = (state: TRootState) => state.outlets;

export const getOutlets = createSelector(
  outletsSelector,
  (state) => state?.items
);

export const getOutletsStatistics = createSelector(
  outletsSelector,
  (state) => state?.statistics
);

export const getOutletBySlug = createSelector(
  outletsSelector,
  (state) => (slug: string) =>
    state?.items?.find((item) => item.slug === slug) || null
);

export const getOutletsTotal = createSelector(
  outletsSelector,
  (state) => state?.total
);

export const getOutletsActiveTipinc = createSelector(
  outletsSelector,
  (state) =>
    state?.items?.filter((item) => item?.business?.tipping?.merchantId !== null)
      .length
);

export const getOutletsNextToken = createSelector(
  outletsSelector,
  (state) => state?.nextToken
);
