import { call, put, takeLatest } from "redux-saga/effects";
import {
  LOAD_INDIVIDUALS,
  loadIndividualsSuccess,
  UPDATE_PROFILE,
  UPDATE_PROFILE_SLUG,
  updateProfileSuccess,
} from "../actions/individuals.actions";
import {
  TLoadIndividualsPayload,
  TUpdateProfilePayload,
  TUpdateProfileSlugPayload,
} from "../types/individuals.types";
import { requestController } from "../../utils";
import { TAction } from "../store";
import { Simulate } from "react-dom/test-utils";
import { getIndividualsNextToken } from "../selectors/individuals.selectors";
import { API } from "aws-amplify";
import { updateProfile } from "../../graphql/mutations";
import safe from "../utils/safe";
import select = Simulate.select;

function* loadIndividualsSaga({ payload }: TAction<TLoadIndividualsPayload>) {
  const { regions, verified, notVerified, createdAfter } = payload;

  // @ts-ignore TODO types
  const nextToken = yield select(getIndividualsNextToken);

  const params: { [key: string]: string } = {};
  if (regions?.length) params.regions = regions.map(encodeURI).join(",");
  if (verified) params.verified = verified.toString();
  if (notVerified) params.notVerified = notVerified.toString();
  if (createdAfter) params.createdAfter = createdAfter;
  if (nextToken) params.nextToken = nextToken;

  const { data } = yield requestController.get(
    "/api/profiles/individuals",
    params,
    true
  );

  yield put(loadIndividualsSuccess({ data }));
}

function* updateProfileSaga({ payload }: TAction<TUpdateProfilePayload>) {
  const { input } = payload;

  if ("slug" in input) throw new Error("Slug cannot be updated this way");

  const { data } = yield API.graphql({
    query: updateProfile,
    variables: {
      input,
    },
  });

  yield put(updateProfileSuccess({ data: data?.updateProfile }));
}

function* updateProfileSlugSaga({
  payload,
}: TAction<TUpdateProfileSlugPayload>) {
  const { slug, id } = payload;
  const { data } = yield call(requestController.put, `/api/profiles/${id}`, {
    slug,
  });

  yield put(updateProfileSuccess({ data }));
}

export default function* individualsSagas() {
  yield takeLatest(LOAD_INDIVIDUALS, safe(loadIndividualsSaga));
  yield takeLatest(UPDATE_PROFILE, safe(updateProfileSaga));
  yield takeLatest(UPDATE_PROFILE_SLUG, safe(updateProfileSlugSaga));
}
