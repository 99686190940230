import React, { useContext } from "react";
import { IonInput, IonItem } from "@ionic/react";
import { FieldContext } from "./EnterpriseUserCreate";

const Step1 = () => {
  const context = useContext(FieldContext);
  if (!context) return null;
  const { fields, setField, setTitle } = context;
  setTitle("Company details");
  return (
    <>
      <IonItem>
        <IonInput
          placeholder="The full legal name of the enterprise customer (e.g., Nandos UK Limited)."
          label="Company name"
          labelPlacement="floating"
          onIonChange={setField("name")}
          value={fields.name}
        />
      </IonItem>

      <IonItem>
        <IonInput
          placeholder="The registered company number for legal identification."
          label="Company Number"
          labelPlacement="floating"
          onIonChange={setField("companyNumber")}
          value={fields.companyNumber}
        />
      </IonItem>
    </>
  );
};

export default Step1;
