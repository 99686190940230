import React, { useContext } from "react";
import { IonInput, IonItem, IonItemGroup } from "@ionic/react";
import { FieldContext } from "./EnterpriseUserCreate";

const Step2 = () => {
  const context = useContext(FieldContext);
  if (!context) return null;
  const { fields, setField, setTitle } = context;
  setTitle("Company address");
  return (
    <>
      <IonItem>
        <IonInput
          label="Address line 1"
          value={fields.address.address}
          onIonChange={setField("address.address")}
          labelPlacement="floating"
        />
      </IonItem>
      <IonItem>
        <IonInput
          label="Address line 2"
          value={fields.address.address2}
          onIonChange={setField("address.address2")}
          labelPlacement="floating"
        />
      </IonItem>
      <IonItem>
        <IonInput
          label="City"
          value={fields.address.city}
          onIonChange={setField("address.city")}
          labelPlacement="floating"
        />
      </IonItem>
      <IonItem>
        <IonInput
          label="Region"
          value={fields.address.region}
          onIonChange={setField("address.region")}
          labelPlacement="floating"
        />
      </IonItem>
      <IonItemGroup>
        <IonItem>
          <IonInput
            label="Postal code"
            value={fields.address.postcode}
            onIonChange={setField("address.postcode")}
            labelPlacement="floating"
          />
        </IonItem>
        <IonItem>
          <IonInput
            label="Country"
            value={fields.address.country}
            onIonChange={setField("address.country")}
            labelPlacement="floating"
            disabled
          />
        </IonItem>
      </IonItemGroup>
    </>
  );
};

export default Step2;
