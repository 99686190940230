import { Redirect, Route } from "react-router-dom";
import {
  TippingHome,
  TippingTip,
  TippingResult,
} from "../feature/tipping/pages";
import { IonRouterOutlet } from "@ionic/react";
import ConnectCompleted from "../feature/connect/pages/ConnectCompleted";

const TippingRoutes = () => (
  <IonRouterOutlet id="tipping" animated>
    <Route path="/:slug" exact component={TippingHome} />
    {/*<Route path="/:slug/:employeeSlug" component={TippingTip} exact />*/}
    <Route path="/:slug/result" component={TippingResult} exact />
    <Route
      path="/connect/:type(completed)"
      exact
      component={ConnectCompleted}
    />
    <Route path="/connect/:type(refresh)" exact component={ConnectCompleted} />
    <Route path="/tip" exact>
      <Redirect to="/" />
    </Route>
  </IonRouterOutlet>
);

export default TippingRoutes;
