import { createRef, useState } from "react";
import ProfileHeader from "../ProfileHeader/ProfileHeader";
import EmployeeListModal from "../EmployeeListModal/EmployeeListModal";
import { TippingInfoIndividual } from "../../../../../store/types/tipping.types";

interface TProps {
  data: TippingInfoIndividual;
}

const OutletEmployees = ({ data }: TProps) => {
  const profileHeaderRef = createRef<HTMLIonCardElement>();

  // indicates if modal is open in full mode (85% of pages), needs to create animation and manipulate with profileHeader compomonent
  const [fullModalSize, setFullModalSize] = useState<boolean>(false);

  const { name, employees, picture, location } = data;
  return (
    <>
      <ProfileHeader
        ref={profileHeaderRef}
        title={name}
        subtitle={location}
        className={fullModalSize ? "profile-header-wrapper--briefly" : ""}
        picture={picture}
      />
      <EmployeeListModal
        data={employees}
        onFullModal={setFullModalSize}
        refToHandleHeight={profileHeaderRef}
      />
    </>
  );
};

export default OutletEmployees;
