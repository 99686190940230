import React from "react";
import { IonCheckbox, IonItem, IonLabel, IonText } from "@ionic/react";
import { TipTransaction } from "../../../../../types/DBTypes";
import { formatAmount, formatDateTime } from "../../../../../utils";

interface TProps {
  transfer: TipTransaction;
}

const TipItem = ({ transfer }: TProps) => {
  return (
    <IonItem>
      <IonLabel>{formatDateTime(transfer.createdAt)}</IonLabel>
      <IonText>
        <b>{formatAmount(+transfer.amount)}</b>
      </IonText>
    </IonItem>
  );
};

export default TipItem;
