import { put, takeLatest } from "redux-saga/effects";
import {
  LOAD_TIPPING_INFO,
  loadTippingInfoSuccess,
  SAVE_TIPPING_TIP,
} from "../actions/tipping.actions";
import { showToast } from "../actions/ui.actions";
import request from "../utils/requestController";
import { TAction } from "../store";
import {
  TLoadTippingInfoPayload,
  TSaveTippingTipPayload,
} from "../types/tipping.types";
import safe from "../utils/safe";

function* loadTippingInfoSaga({ payload }: TAction<TLoadTippingInfoPayload>) {
  try {
    const { slug } = payload;
    const { data } = yield request.get("/api/tipping/info", { slug });

    yield put(loadTippingInfoSuccess({ data }));
  } catch (e: any) {
    yield put(showToast({ text: e?.message }));
  }
}

function* saveTippingTipSaga({ payload }: TAction<TSaveTippingTipPayload>) {
  const { id, amount, receiverId, type, fee } = payload;
  const transaction = {
    amount,
    receiverId,
    id,
    fee,
    // TODO doesn't work with personal QR tipping
    type: type === "group" ? "group" : "individual",
    source: "QR",
  };
  try {
    yield request.post("/api/tipping/transactions", transaction);

    yield put(showToast({ text: "Tip sent!" }));
  } catch (e) {
    console.log({ e });
  }
}

export default function* tippingSagas() {
  yield takeLatest(LOAD_TIPPING_INFO, safe(loadTippingInfoSaga));
  yield takeLatest(SAVE_TIPPING_TIP, safe(saveTippingTipSaga));
}
