import { TAction } from "../store";
import {
  TUpdateBusinessSuccessPayload,
  TLoadOutletsSuccessPayload,
  TState,
  TGetOutletBySlugSuccessPayload,
} from "../types/outlets.types";
import {
  GET_OUTLET_BY_SLUG_SUCCESS,
  GET_OUTLET_STATISTICS_SUCCESS,
  LOAD_OUTLETS_SUCCESS,
  UPDATE_BUSINESS_SUCCESS,
} from "../actions/outlets.actions";
import { UPDATE_PROFILE_SUCCESS } from "../actions/individuals.actions";
import { TUpdateProfileSuccessPayload } from "../types/individuals.types";
import getPrimitiveValueObject from "../../utils/getPrimitiveValueObject";

//----------------------------------------------------------------------------------------------------------------------
const initialState: TState = {
  statistics: [],
};
//----------------------------------------------------------------------------------------------------------------------

const outletsReducer = (
  state = initialState,
  { type, payload }: TAction<any>
): TState => {
  switch (type) {
    case LOAD_OUTLETS_SUCCESS: {
      const { data } = payload as TLoadOutletsSuccessPayload;
      return {
        ...state,
        // @ts-ignore
        items: data.items,
        total: data.total || undefined,
        nextToken: data.nextToken || undefined,
      };
    }

    case GET_OUTLET_BY_SLUG_SUCCESS: {
      const { data } = payload as TGetOutletBySlugSuccessPayload;
      return {
        ...state,
        items: (state.items || []).concat([data]),
      };
    }

    case GET_OUTLET_STATISTICS_SUCCESS: {
      const { data } = payload;
      return {
        ...state,
        statistics: data.concat(state.statistics || []),
      };
    }
    case UPDATE_PROFILE_SUCCESS: {
      const { data } = payload as TUpdateProfileSuccessPayload;
      if (data.details?.accountType !== "business") return state;

      return {
        ...state,
        // @ts-ignore
        items: state.items?.map((item) => {
          if (item.id !== data.id) return item;
          return {
            ...item,
            ...getPrimitiveValueObject(data),
          };
        }),
      };
    }
    case UPDATE_BUSINESS_SUCCESS: {
      const { data } = payload as TUpdateBusinessSuccessPayload;

      return {
        ...state,
        // @ts-ignore
        items: state.items?.map((item) => {
          if (item.id !== data.id) return item;

          return {
            ...item,
            business: {
              ...item.business,
              ...getPrimitiveValueObject(data),
              tipping: {
                ...item.business?.tipping,
                ...getPrimitiveValueObject(data.tipping || {}),
              },
            },
          };
        }),
      };
    }

    default:
      return state;
  }
};

export default outletsReducer;
