import { LOAD_TIPPING_INFO_SUCCESS } from "../actions/tipping.actions";
import { TAction } from "../store";
import { TTippingData } from "../types/tipping.types";

//----------------------------------------------------------------------------------------------------------------------
const initialState: { data?: TTippingData } = {};
//----------------------------------------------------------------------------------------------------------------------

const tippingReducers = (state = initialState, { type, payload }: TAction) => {
  switch (type) {
    case LOAD_TIPPING_INFO_SUCCESS:
      const { data } = payload;
      return { ...state, data };
    default:
      return state;
  }
};

export default tippingReducers;
