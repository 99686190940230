import { SIGN_IN_SUCCESS, SIGN_OUT_SUCCESS } from "../actions/user.actions";
import { TAction } from "../store";

//----------------------------------------------------------------------------------------------------------------------
const initialState = {
  authorized: false,
};
//----------------------------------------------------------------------------------------------------------------------

const userReducers = (state = initialState, { type, payload }: TAction) => {
  switch (type) {
    case SIGN_IN_SUCCESS:
      return { ...state, authorized: true };
    case SIGN_OUT_SUCCESS:
      return { ...state, authorized: false };
    default:
      return state;
  }
};

export default userReducers;
