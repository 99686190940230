import { useCallback } from "react";
import { Clipboard } from "@capacitor/clipboard";
import { useAppDispatch } from "../store/store";
import { showToast } from "../store/actions/ui.actions";

interface IOptions {
  string?: string;
  image?: string;
  url?: string;
  label?: string;
}

const useClipboard = () => {
  const call = useAppDispatch();

  return useCallback(
    async (options: IOptions) => {
      await Clipboard.write(options);
      call(showToast({ text: "Successfully copied to clipboard" }));
    },
    [call]
  );
};

export default useClipboard;
