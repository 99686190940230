import { useContext } from "react";
import { IonInput, IonItem, IonLabel } from "@ionic/react";
import { PaymentModalContext } from "../PaymentModalProvider/PaymentModalProvider";
import { IonInputCustomEvent } from "@ionic/core/dist/types/components";
import { formatAmount } from "../../../../../utils";

const MIN_AMOUNT = 1;

const AmountInput = () => {
  const { amount, setAmount } = useContext(PaymentModalContext);

  const handleInputChange = ({ detail }: IonInputCustomEvent<FocusEvent>) => {
    if (!setAmount) return;
    // @ts-ignore
    const { value } = detail?.target;
    if (!value) {
      setAmount(0);
      return;
    }

    const valueNumber = value.replace(/^\D+/g, "");
    if (isNaN(+valueNumber)) return;

    if (+valueNumber < MIN_AMOUNT) {
      setTimeout(() => setAmount(MIN_AMOUNT), 500);
      return;
    }
    setTimeout(() => setAmount(+valueNumber), 500);
  };

  // const handleInputBlur = () => {
  //   if (!!amount) paymentModal?.show();
  //   else paymentModal?.hide();
  // };

  return (
    <IonItem className="input--amount" lines="none">
      <IonLabel position="floating">Tip amount</IonLabel>
      <IonInput
        placeholder="£2"
        value={amount > 0 ? `${formatAmount(amount)}` : null}
        inputMode="decimal"
        onIonBlur={handleInputChange}
        disabled={!setAmount}
      />
    </IonItem>
  );
};

export default AmountInput;
