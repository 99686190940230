import { Brand } from "../../../../../types/DBTypes";
import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonHeader,
  IonImg,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { handleImageUrl } from "../../../../../utils";
import "./BrandModal.css";
import React, { useEffect, useState } from "react";
import { BRAND_CATEGORIES } from "../../utils/constants";
import Toggle from "../../../../common/Toggle/Toggle";
import DragAndDropFile from "../../../../common/DragAndDropFile/DragAndDropFile";
import { useAppDispatch } from "../../../../../store/store";
import {
  createBrand,
  deleteBrand,
  updateBrand,
} from "../../../../../store/actions/brand.actions";
import { showToast } from "../../../../../store/actions/ui.actions";
import useConfirmationModal from "../../../../../hooks/useConfirmationModal";

interface IProps {
  brand?: Brand | null;
  setBrand: (brand: Brand | null) => void;
}

const BrandModal = ({ brand, setBrand }: IProps) => {
  const [name, setName] = useState(brand?.name);
  const [productType, setProductType] = useState(brand?.productType);
  const [active, setActive] = useState<boolean>(brand?.active || true);
  const [logo, setLogo] = useState<string>(brand?.logo || "");

  const call = useAppDispatch();
  const handleDelete = useConfirmationModal(() => {
    if (brand?.id) call(deleteBrand({ id: brand?.id }));
    setBrand(null);
  }, "Are you sure you want to delete this brand?");
  useEffect(() => {
    setName(brand?.name);
    setProductType(brand?.productType);
    setActive(brand?.active || true);
    setLogo(brand?.logo || "");
  }, [brand]);

  const action = brand?.id ? "Edit" : "Add";
  const handleSave = () => {
    if (!name) return call(showToast({ text: "Name is required" }));
    if (!productType)
      return call(showToast({ text: "Product type is required" }));

    if (!brand?.id) {
      call(createBrand({ name, productType, active, logo }));
    } else {
      call(updateBrand({ id: brand?.id, name, productType, active, logo }));
    }
    setBrand(null);
  };

  return (
    <IonModal
      isOpen={!!brand}
      initialBreakpoint={0.3}
      breakpoints={[0.3, 0.5, 1]}
      className="brand-modal"
      onDidDismiss={() => setBrand(null)}
    >
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="end">
            <IonButton onClick={() => setBrand(null)}>Cancel</IonButton>
          </IonButtons>
          <IonTitle>
            {action} {brand?.name}
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        {logo ? (
          <IonImg src={handleImageUrl(logo)} />
        ) : (
          <DragAndDropFile path="original/logos" onFileUpload={setLogo} />
        )}

        <IonItem>
          <IonLabel position="floating">Brand name*</IonLabel>
          <IonInput
            type="text"
            value={name}
            onIonChange={({ detail }) => setName(detail.value || "")}
          />
        </IonItem>

        <IonItem>
          <IonLabel>Regions</IonLabel>
          <IonSelect
            placeholder="chose"
            interface="action-sheet"
            onIonChange={({ detail }) => {
              setProductType(detail.value);
            }}
            value={productType}
          >
            {BRAND_CATEGORIES.map((brand) => (
              <IonSelectOption key={brand} value={brand}>
                {brand}
              </IonSelectOption>
            ))}
          </IonSelect>
        </IonItem>
        <Toggle checked={active} setChecked={setActive}>
          Active
        </Toggle>
      </IonContent>

      <IonFooter>
        <IonGrid>
          <IonRow>
            <IonCol>
              {action === "Edit" && (
                <IonButton
                  onClick={() => setBrand(null)}
                  expand="block"
                  color="tertiary"
                >
                  Cancel
                </IonButton>
              )}
            </IonCol>
            <IonCol>
              {action === "Edit" && (
                <IonButton onClick={handleDelete} expand="block" color="danger">
                  Delete
                </IonButton>
              )}
            </IonCol>
            <IonCol>
              <IonButton
                disabled={!name || !productType}
                expand="block"
                color="success"
                onClick={handleSave}
              >
                Save
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonFooter>
    </IonModal>
  );
};

export default BrandModal;
