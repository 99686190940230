import React from "react";
import { IonContent, IonItem, IonList, IonPopover } from "@ionic/react";
import {
  Business,
  Profile,
  UpdateBusinessInput,
  UpdateProfileInput,
} from "../../../../../types/DBTypes";
import useConfirmationModal from "../../../../../hooks/useConfirmationModal";
import { updateBusiness as updateBusinessAction } from "../../../../../store/actions/outlets.actions";
import { connect } from "react-redux";
import {
  updateProfile,
  updateProfileSlug as updateProfileSlugAction,
} from "../../../../../store/actions/individuals.actions";
import { TUpdateProfileSlugPayload } from "../../../../../store/types/individuals.types";

interface IProps {
  profile: Profile;
  business: Business;
  updateBusiness: (input: UpdateBusinessInput) => void;
  updateProfile: (input: UpdateProfileInput) => void;
  updateProfileSlug: (payload: TUpdateProfileSlugPayload) => void;
}

const mapProps = (dispatch: any) => ({
  updateBusiness: (input: UpdateBusinessInput) =>
    dispatch(updateBusinessAction({ input })),
  updateProfile: (input: UpdateProfileInput) =>
    dispatch(updateProfile({ input })),
  updateProfileSlug: (payload: TUpdateProfileSlugPayload) =>
    dispatch(updateProfileSlugAction(payload)),
});
const OutletPopoverController = ({
  profile,
  business,
  updateBusiness,
  updateProfile,
  updateProfileSlug,
}: IProps) => {
  const { verified } = business;
  const { slug } = profile;

  const handleVerify = useConfirmationModal(() => {
    updateBusiness({ id: business.id, verified: !verified });
  }, `Would you like to ${verified ? "remove verification from" : "verify"} this business?`);

  const handleEditSlug = useConfirmationModal(
    async ({ slug }) => {
      const newSlug = slug.trim().toLowerCase();
      updateProfileSlug({ slug: newSlug, id: profile.id });
    },
    "Enter new slug: ",
    [{ name: "slug", type: "text", value: slug, min: 6, max: 6 }]
  );
  return (
    <IonPopover trigger="profile-popover" dismissOnSelect={true} side="bottom">
      <IonContent>
        <IonList>
          <IonItem onClick={handleVerify}>
            {verified ? "Unverify" : "Verify"}
          </IonItem>
          <IonItem onClick={() => handleEditSlug()}>Edit slug</IonItem>
          <IonItem>Close</IonItem>
        </IonList>
      </IonContent>
    </IonPopover>
  );
};

export default connect(null, mapProps)(OutletPopoverController);
