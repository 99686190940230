/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../types/DBTypes";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getProfileEnterprise = /* GraphQL */ `query GetProfileEnterprise($id: ID!) {
  getProfileEnterprise(id: $id) {
    id
    name
    address {
      searchName
      address
      address2
      city
      region
      postcode
      country
      latitude
      longitude
      __typename
    }
    companyNumber
    businesses
    active
    createdAt
    updatedAt
    owner
    picture
    contactDetails {
      name
      role
      phone
      email
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetProfileEnterpriseQueryVariables,
  APITypes.GetProfileEnterpriseQuery
>;
export const listProfileEnterprises = /* GraphQL */ `query ListProfileEnterprises(
  $filter: ModelProfileEnterpriseFilterInput
  $limit: Int
  $nextToken: String
) {
  listProfileEnterprises(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      address {
        searchName
        address
        address2
        city
        region
        postcode
        country
        latitude
        longitude
        __typename
      }
      companyNumber
      businesses
      active
      createdAt
      updatedAt
      owner
      picture
      contactDetails {
        name
        role
        phone
        email
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListProfileEnterprisesQueryVariables,
  APITypes.ListProfileEnterprisesQuery
>;
export const getBusinessStatistics = /* GraphQL */ `query GetBusinessStatistics($id: ID!) {
  getBusinessStatistics(id: $id) {
    id
    data {
      date
      totalTips
      totalTipsCount
      __typename
    }
    updatedAt
    owner
    lastSplitDate
    createdAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBusinessStatisticsQueryVariables,
  APITypes.GetBusinessStatisticsQuery
>;
export const listBusinessStatisticss = /* GraphQL */ `query ListBusinessStatisticss(
  $filter: ModelBusinessStatisticsFilterInput
  $limit: Int
  $nextToken: String
) {
  listBusinessStatisticss(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      data {
        date
        totalTips
        totalTipsCount
        __typename
      }
      updatedAt
      owner
      lastSplitDate
      createdAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBusinessStatisticssQueryVariables,
  APITypes.ListBusinessStatisticssQuery
>;
export const businessByCode = /* GraphQL */ `query BusinessByCode(
  $code: String
  $id: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelBusinessFilterInput
  $limit: Int
  $nextToken: String
) {
  businessByCode(
    code: $code
    id: $id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      verified
      name
      tipping {
        merchantId
        policy
        type
        active
        __typename
      }
      marketing {
        customerId
        subscriptionId
        active
        expirationDate
        sequenceId
        cancelled
        cancelledAt
        __typename
      }
      createdAt
      updatedAt
      owner
      code
      answers {
        questionId
        value
        __typename
      }
      isOutlet
      details {
        id
        contactName
        jobRole
        phone
        email
        website
        location {
          searchName
          address
          address2
          city
          region
          postcode
          country
          latitude
          longitude
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      ownerProfile {
        id
        slug
        firstName
        lastName
        bio
        picture
        location {
          searchName
          address
          address2
          city
          region
          postcode
          country
          latitude
          longitude
          __typename
        }
        createdAt
        updatedAt
        business {
          id
          verified
          name
          tipping {
            merchantId
            policy
            type
            active
            __typename
          }
          marketing {
            customerId
            subscriptionId
            active
            expirationDate
            sequenceId
            cancelled
            cancelledAt
            __typename
          }
          createdAt
          updatedAt
          owner
          code
          answers {
            questionId
            value
            __typename
          }
          isOutlet
          details {
            id
            contactName
            jobRole
            phone
            email
            website
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          ownerProfile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          invites {
            items {
              id
              businessId
              receiver
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          employees {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          brands {
            items {
              id
              businessId
              brandId
              status
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profileId
              brand {
                id
                name
                logo
                active
                productType
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        archived
        details {
          id
          accountType
          isOnboardingComplete
          email
          feedRegion
          tippingAccount {
            code
            merchant
            accountId
            verified
            missingRequirements {
              key
              description
              __typename
            }
            pendingValidation
            bankDetails {
              name
              sortCode
              last4Digits
              __typename
            }
            __typename
          }
          phone_number
          phoneNumberConfirmed
          pushNotificationToken
          pushNotificationReadDate
          createdAt
          updatedAt
          __typename
        }
        employers {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        experiences {
          items {
            id
            jobTitle
            businessId
            companyName
            profileId
            startDate
            endDate
            employmentType
            location
            description
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        jobs {
          items {
            id
            profileId
            jobId
            coverLetter
            saved
            savedDate
            applied
            appliedDate
            createdAt
            updatedAt
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            job {
              id
              owner
              jobType
              businessId
              jobRole
              title
              description
              salary {
                type
                from
                to
                __typename
              }
              region
              requireCV
              requireCoverLetter
              createdAt
              archived
              startDate
              endDate
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              reported {
                nextToken
                __typename
              }
              __typename
            }
            rejected
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      invites {
        items {
          id
          businessId
          receiver
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      employees {
        items {
          id
          jobRole
          businessId
          profileId
          employeeId
          tippingPolicyAcknowledgedDate
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          confirmed
          __typename
        }
        nextToken
        __typename
      }
      brands {
        items {
          id
          businessId
          brandId
          status
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profileId
          brand {
            id
            name
            logo
            active
            productType
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.BusinessByCodeQueryVariables,
  APITypes.BusinessByCodeQuery
>;
export const businessByName = /* GraphQL */ `query BusinessByName(
  $name: String
  $id: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelBusinessFilterInput
  $limit: Int
  $nextToken: String
) {
  businessByName(
    name: $name
    id: $id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      verified
      name
      tipping {
        merchantId
        policy
        type
        active
        __typename
      }
      marketing {
        customerId
        subscriptionId
        active
        expirationDate
        sequenceId
        cancelled
        cancelledAt
        __typename
      }
      createdAt
      updatedAt
      owner
      code
      answers {
        questionId
        value
        __typename
      }
      isOutlet
      details {
        id
        contactName
        jobRole
        phone
        email
        website
        location {
          searchName
          address
          address2
          city
          region
          postcode
          country
          latitude
          longitude
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      ownerProfile {
        id
        slug
        firstName
        lastName
        bio
        picture
        location {
          searchName
          address
          address2
          city
          region
          postcode
          country
          latitude
          longitude
          __typename
        }
        createdAt
        updatedAt
        business {
          id
          verified
          name
          tipping {
            merchantId
            policy
            type
            active
            __typename
          }
          marketing {
            customerId
            subscriptionId
            active
            expirationDate
            sequenceId
            cancelled
            cancelledAt
            __typename
          }
          createdAt
          updatedAt
          owner
          code
          answers {
            questionId
            value
            __typename
          }
          isOutlet
          details {
            id
            contactName
            jobRole
            phone
            email
            website
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          ownerProfile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          invites {
            items {
              id
              businessId
              receiver
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          employees {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          brands {
            items {
              id
              businessId
              brandId
              status
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profileId
              brand {
                id
                name
                logo
                active
                productType
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        archived
        details {
          id
          accountType
          isOnboardingComplete
          email
          feedRegion
          tippingAccount {
            code
            merchant
            accountId
            verified
            missingRequirements {
              key
              description
              __typename
            }
            pendingValidation
            bankDetails {
              name
              sortCode
              last4Digits
              __typename
            }
            __typename
          }
          phone_number
          phoneNumberConfirmed
          pushNotificationToken
          pushNotificationReadDate
          createdAt
          updatedAt
          __typename
        }
        employers {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        experiences {
          items {
            id
            jobTitle
            businessId
            companyName
            profileId
            startDate
            endDate
            employmentType
            location
            description
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        jobs {
          items {
            id
            profileId
            jobId
            coverLetter
            saved
            savedDate
            applied
            appliedDate
            createdAt
            updatedAt
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            job {
              id
              owner
              jobType
              businessId
              jobRole
              title
              description
              salary {
                type
                from
                to
                __typename
              }
              region
              requireCV
              requireCoverLetter
              createdAt
              archived
              startDate
              endDate
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              reported {
                nextToken
                __typename
              }
              __typename
            }
            rejected
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      invites {
        items {
          id
          businessId
          receiver
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      employees {
        items {
          id
          jobRole
          businessId
          profileId
          employeeId
          tippingPolicyAcknowledgedDate
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          confirmed
          __typename
        }
        nextToken
        __typename
      }
      brands {
        items {
          id
          businessId
          brandId
          status
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profileId
          brand {
            id
            name
            logo
            active
            productType
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.BusinessByNameQueryVariables,
  APITypes.BusinessByNameQuery
>;
export const searchBusinesss = /* GraphQL */ `query SearchBusinesss(
  $filter: SearchableBusinessFilterInput
  $sort: SearchableBusinessSortInput
  $limit: Int
  $nextToken: String
  $from: Int
) {
  searchBusinesss(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
  ) {
    items {
      id
      verified
      name
      tipping {
        merchantId
        policy
        type
        active
        __typename
      }
      marketing {
        customerId
        subscriptionId
        active
        expirationDate
        sequenceId
        cancelled
        cancelledAt
        __typename
      }
      createdAt
      updatedAt
      owner
      code
      answers {
        questionId
        value
        __typename
      }
      isOutlet
      details {
        id
        contactName
        jobRole
        phone
        email
        website
        location {
          searchName
          address
          address2
          city
          region
          postcode
          country
          latitude
          longitude
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      ownerProfile {
        id
        slug
        firstName
        lastName
        bio
        picture
        location {
          searchName
          address
          address2
          city
          region
          postcode
          country
          latitude
          longitude
          __typename
        }
        createdAt
        updatedAt
        business {
          id
          verified
          name
          tipping {
            merchantId
            policy
            type
            active
            __typename
          }
          marketing {
            customerId
            subscriptionId
            active
            expirationDate
            sequenceId
            cancelled
            cancelledAt
            __typename
          }
          createdAt
          updatedAt
          owner
          code
          answers {
            questionId
            value
            __typename
          }
          isOutlet
          details {
            id
            contactName
            jobRole
            phone
            email
            website
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          ownerProfile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          invites {
            items {
              id
              businessId
              receiver
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          employees {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          brands {
            items {
              id
              businessId
              brandId
              status
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profileId
              brand {
                id
                name
                logo
                active
                productType
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        archived
        details {
          id
          accountType
          isOnboardingComplete
          email
          feedRegion
          tippingAccount {
            code
            merchant
            accountId
            verified
            missingRequirements {
              key
              description
              __typename
            }
            pendingValidation
            bankDetails {
              name
              sortCode
              last4Digits
              __typename
            }
            __typename
          }
          phone_number
          phoneNumberConfirmed
          pushNotificationToken
          pushNotificationReadDate
          createdAt
          updatedAt
          __typename
        }
        employers {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        experiences {
          items {
            id
            jobTitle
            businessId
            companyName
            profileId
            startDate
            endDate
            employmentType
            location
            description
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        jobs {
          items {
            id
            profileId
            jobId
            coverLetter
            saved
            savedDate
            applied
            appliedDate
            createdAt
            updatedAt
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            job {
              id
              owner
              jobType
              businessId
              jobRole
              title
              description
              salary {
                type
                from
                to
                __typename
              }
              region
              requireCV
              requireCoverLetter
              createdAt
              archived
              startDate
              endDate
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              reported {
                nextToken
                __typename
              }
              __typename
            }
            rejected
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      invites {
        items {
          id
          businessId
          receiver
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      employees {
        items {
          id
          jobRole
          businessId
          profileId
          employeeId
          tippingPolicyAcknowledgedDate
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          confirmed
          __typename
        }
        nextToken
        __typename
      }
      brands {
        items {
          id
          businessId
          brandId
          status
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profileId
          brand {
            id
            name
            logo
            active
            productType
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchBusinesssQueryVariables,
  APITypes.SearchBusinesssQuery
>;
export const getBusiness = /* GraphQL */ `query GetBusiness($id: ID!) {
  getBusiness(id: $id) {
    id
    verified
    name
    tipping {
      merchantId
      policy
      type
      active
      __typename
    }
    marketing {
      customerId
      subscriptionId
      active
      expirationDate
      sequenceId
      cancelled
      cancelledAt
      __typename
    }
    createdAt
    updatedAt
    owner
    code
    answers {
      questionId
      value
      __typename
    }
    isOutlet
    details {
      id
      contactName
      jobRole
      phone
      email
      website
      location {
        searchName
        address
        address2
        city
        region
        postcode
        country
        latitude
        longitude
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    ownerProfile {
      id
      slug
      firstName
      lastName
      bio
      picture
      location {
        searchName
        address
        address2
        city
        region
        postcode
        country
        latitude
        longitude
        __typename
      }
      createdAt
      updatedAt
      business {
        id
        verified
        name
        tipping {
          merchantId
          policy
          type
          active
          __typename
        }
        marketing {
          customerId
          subscriptionId
          active
          expirationDate
          sequenceId
          cancelled
          cancelledAt
          __typename
        }
        createdAt
        updatedAt
        owner
        code
        answers {
          questionId
          value
          __typename
        }
        isOutlet
        details {
          id
          contactName
          jobRole
          phone
          email
          website
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        ownerProfile {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        invites {
          items {
            id
            businessId
            receiver
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        employees {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        brands {
          items {
            id
            businessId
            brandId
            status
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profileId
            brand {
              id
              name
              logo
              active
              productType
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      archived
      details {
        id
        accountType
        isOnboardingComplete
        email
        feedRegion
        tippingAccount {
          code
          merchant
          accountId
          verified
          missingRequirements {
            key
            description
            __typename
          }
          pendingValidation
          bankDetails {
            name
            sortCode
            last4Digits
            __typename
          }
          __typename
        }
        phone_number
        phoneNumberConfirmed
        pushNotificationToken
        pushNotificationReadDate
        createdAt
        updatedAt
        __typename
      }
      employers {
        items {
          id
          jobRole
          businessId
          profileId
          employeeId
          tippingPolicyAcknowledgedDate
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          confirmed
          __typename
        }
        nextToken
        __typename
      }
      experiences {
        items {
          id
          jobTitle
          businessId
          companyName
          profileId
          startDate
          endDate
          employmentType
          location
          description
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      jobs {
        items {
          id
          profileId
          jobId
          coverLetter
          saved
          savedDate
          applied
          appliedDate
          createdAt
          updatedAt
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          job {
            id
            owner
            jobType
            businessId
            jobRole
            title
            description
            salary {
              type
              from
              to
              __typename
            }
            region
            requireCV
            requireCoverLetter
            createdAt
            archived
            startDate
            endDate
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            reported {
              items {
                id
                postId
                userId
                commentId
                jobId
                creatorId
                createdAt
                message
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          rejected
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    invites {
      items {
        id
        businessId
        receiver
        createdAt
        updatedAt
        business {
          id
          verified
          name
          tipping {
            merchantId
            policy
            type
            active
            __typename
          }
          marketing {
            customerId
            subscriptionId
            active
            expirationDate
            sequenceId
            cancelled
            cancelledAt
            __typename
          }
          createdAt
          updatedAt
          owner
          code
          answers {
            questionId
            value
            __typename
          }
          isOutlet
          details {
            id
            contactName
            jobRole
            phone
            email
            website
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          ownerProfile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          invites {
            items {
              id
              businessId
              receiver
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          employees {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          brands {
            items {
              id
              businessId
              brandId
              status
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profileId
              brand {
                id
                name
                logo
                active
                productType
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    employees {
      items {
        id
        jobRole
        businessId
        profileId
        employeeId
        tippingPolicyAcknowledgedDate
        createdAt
        updatedAt
        business {
          id
          verified
          name
          tipping {
            merchantId
            policy
            type
            active
            __typename
          }
          marketing {
            customerId
            subscriptionId
            active
            expirationDate
            sequenceId
            cancelled
            cancelledAt
            __typename
          }
          createdAt
          updatedAt
          owner
          code
          answers {
            questionId
            value
            __typename
          }
          isOutlet
          details {
            id
            contactName
            jobRole
            phone
            email
            website
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          ownerProfile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          invites {
            items {
              id
              businessId
              receiver
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          employees {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          brands {
            items {
              id
              businessId
              brandId
              status
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profileId
              brand {
                id
                name
                logo
                active
                productType
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        profile {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        confirmed
        __typename
      }
      nextToken
      __typename
    }
    brands {
      items {
        id
        businessId
        brandId
        status
        createdAt
        updatedAt
        business {
          id
          verified
          name
          tipping {
            merchantId
            policy
            type
            active
            __typename
          }
          marketing {
            customerId
            subscriptionId
            active
            expirationDate
            sequenceId
            cancelled
            cancelledAt
            __typename
          }
          createdAt
          updatedAt
          owner
          code
          answers {
            questionId
            value
            __typename
          }
          isOutlet
          details {
            id
            contactName
            jobRole
            phone
            email
            website
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          ownerProfile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          invites {
            items {
              id
              businessId
              receiver
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          employees {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          brands {
            items {
              id
              businessId
              brandId
              status
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profileId
              brand {
                id
                name
                logo
                active
                productType
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        profileId
        brand {
          id
          name
          logo
          active
          productType
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBusinessQueryVariables,
  APITypes.GetBusinessQuery
>;
export const listBusinesss = /* GraphQL */ `query ListBusinesss(
  $filter: ModelBusinessFilterInput
  $limit: Int
  $nextToken: String
) {
  listBusinesss(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      verified
      name
      tipping {
        merchantId
        policy
        type
        active
        __typename
      }
      marketing {
        customerId
        subscriptionId
        active
        expirationDate
        sequenceId
        cancelled
        cancelledAt
        __typename
      }
      createdAt
      updatedAt
      owner
      code
      answers {
        questionId
        value
        __typename
      }
      isOutlet
      details {
        id
        contactName
        jobRole
        phone
        email
        website
        location {
          searchName
          address
          address2
          city
          region
          postcode
          country
          latitude
          longitude
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      ownerProfile {
        id
        slug
        firstName
        lastName
        bio
        picture
        location {
          searchName
          address
          address2
          city
          region
          postcode
          country
          latitude
          longitude
          __typename
        }
        createdAt
        updatedAt
        business {
          id
          verified
          name
          tipping {
            merchantId
            policy
            type
            active
            __typename
          }
          marketing {
            customerId
            subscriptionId
            active
            expirationDate
            sequenceId
            cancelled
            cancelledAt
            __typename
          }
          createdAt
          updatedAt
          owner
          code
          answers {
            questionId
            value
            __typename
          }
          isOutlet
          details {
            id
            contactName
            jobRole
            phone
            email
            website
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          ownerProfile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          invites {
            items {
              id
              businessId
              receiver
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          employees {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          brands {
            items {
              id
              businessId
              brandId
              status
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profileId
              brand {
                id
                name
                logo
                active
                productType
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        archived
        details {
          id
          accountType
          isOnboardingComplete
          email
          feedRegion
          tippingAccount {
            code
            merchant
            accountId
            verified
            missingRequirements {
              key
              description
              __typename
            }
            pendingValidation
            bankDetails {
              name
              sortCode
              last4Digits
              __typename
            }
            __typename
          }
          phone_number
          phoneNumberConfirmed
          pushNotificationToken
          pushNotificationReadDate
          createdAt
          updatedAt
          __typename
        }
        employers {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        experiences {
          items {
            id
            jobTitle
            businessId
            companyName
            profileId
            startDate
            endDate
            employmentType
            location
            description
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        jobs {
          items {
            id
            profileId
            jobId
            coverLetter
            saved
            savedDate
            applied
            appliedDate
            createdAt
            updatedAt
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            job {
              id
              owner
              jobType
              businessId
              jobRole
              title
              description
              salary {
                type
                from
                to
                __typename
              }
              region
              requireCV
              requireCoverLetter
              createdAt
              archived
              startDate
              endDate
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              reported {
                nextToken
                __typename
              }
              __typename
            }
            rejected
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      invites {
        items {
          id
          businessId
          receiver
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      employees {
        items {
          id
          jobRole
          businessId
          profileId
          employeeId
          tippingPolicyAcknowledgedDate
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          confirmed
          __typename
        }
        nextToken
        __typename
      }
      brands {
        items {
          id
          businessId
          brandId
          status
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profileId
          brand {
            id
            name
            logo
            active
            productType
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBusinesssQueryVariables,
  APITypes.ListBusinesssQuery
>;
export const getBusinessDetails = /* GraphQL */ `query GetBusinessDetails($id: ID!) {
  getBusinessDetails(id: $id) {
    id
    contactName
    jobRole
    phone
    email
    website
    location {
      searchName
      address
      address2
      city
      region
      postcode
      country
      latitude
      longitude
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBusinessDetailsQueryVariables,
  APITypes.GetBusinessDetailsQuery
>;
export const listBusinessDetailss = /* GraphQL */ `query ListBusinessDetailss(
  $filter: ModelBusinessDetailsFilterInput
  $limit: Int
  $nextToken: String
) {
  listBusinessDetailss(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      contactName
      jobRole
      phone
      email
      website
      location {
        searchName
        address
        address2
        city
        region
        postcode
        country
        latitude
        longitude
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBusinessDetailssQueryVariables,
  APITypes.ListBusinessDetailssQuery
>;
export const getProfilesBySlug = /* GraphQL */ `query GetProfilesBySlug(
  $slug: String
  $sortDirection: ModelSortDirection
  $filter: ModelProfileFilterInput
  $limit: Int
  $nextToken: String
) {
  getProfilesBySlug(
    slug: $slug
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      slug
      firstName
      lastName
      bio
      picture
      location {
        searchName
        address
        address2
        city
        region
        postcode
        country
        latitude
        longitude
        __typename
      }
      createdAt
      updatedAt
      business {
        id
        verified
        name
        tipping {
          merchantId
          policy
          type
          active
          __typename
        }
        marketing {
          customerId
          subscriptionId
          active
          expirationDate
          sequenceId
          cancelled
          cancelledAt
          __typename
        }
        createdAt
        updatedAt
        owner
        code
        answers {
          questionId
          value
          __typename
        }
        isOutlet
        details {
          id
          contactName
          jobRole
          phone
          email
          website
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        ownerProfile {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        invites {
          items {
            id
            businessId
            receiver
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        employees {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        brands {
          items {
            id
            businessId
            brandId
            status
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profileId
            brand {
              id
              name
              logo
              active
              productType
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      archived
      details {
        id
        accountType
        isOnboardingComplete
        email
        feedRegion
        tippingAccount {
          code
          merchant
          accountId
          verified
          missingRequirements {
            key
            description
            __typename
          }
          pendingValidation
          bankDetails {
            name
            sortCode
            last4Digits
            __typename
          }
          __typename
        }
        phone_number
        phoneNumberConfirmed
        pushNotificationToken
        pushNotificationReadDate
        createdAt
        updatedAt
        __typename
      }
      employers {
        items {
          id
          jobRole
          businessId
          profileId
          employeeId
          tippingPolicyAcknowledgedDate
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          confirmed
          __typename
        }
        nextToken
        __typename
      }
      experiences {
        items {
          id
          jobTitle
          businessId
          companyName
          profileId
          startDate
          endDate
          employmentType
          location
          description
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      jobs {
        items {
          id
          profileId
          jobId
          coverLetter
          saved
          savedDate
          applied
          appliedDate
          createdAt
          updatedAt
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          job {
            id
            owner
            jobType
            businessId
            jobRole
            title
            description
            salary {
              type
              from
              to
              __typename
            }
            region
            requireCV
            requireCoverLetter
            createdAt
            archived
            startDate
            endDate
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            reported {
              items {
                id
                postId
                userId
                commentId
                jobId
                creatorId
                createdAt
                message
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          rejected
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetProfilesBySlugQueryVariables,
  APITypes.GetProfilesBySlugQuery
>;
export const searchProfiles = /* GraphQL */ `query SearchProfiles(
  $filter: SearchableProfileFilterInput
  $sort: SearchableProfileSortInput
  $limit: Int
  $nextToken: String
  $from: Int
) {
  searchProfiles(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
  ) {
    items {
      id
      slug
      firstName
      lastName
      bio
      picture
      location {
        searchName
        address
        address2
        city
        region
        postcode
        country
        latitude
        longitude
        __typename
      }
      createdAt
      updatedAt
      business {
        id
        verified
        name
        tipping {
          merchantId
          policy
          type
          active
          __typename
        }
        marketing {
          customerId
          subscriptionId
          active
          expirationDate
          sequenceId
          cancelled
          cancelledAt
          __typename
        }
        createdAt
        updatedAt
        owner
        code
        answers {
          questionId
          value
          __typename
        }
        isOutlet
        details {
          id
          contactName
          jobRole
          phone
          email
          website
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        ownerProfile {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        invites {
          items {
            id
            businessId
            receiver
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        employees {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        brands {
          items {
            id
            businessId
            brandId
            status
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profileId
            brand {
              id
              name
              logo
              active
              productType
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      archived
      details {
        id
        accountType
        isOnboardingComplete
        email
        feedRegion
        tippingAccount {
          code
          merchant
          accountId
          verified
          missingRequirements {
            key
            description
            __typename
          }
          pendingValidation
          bankDetails {
            name
            sortCode
            last4Digits
            __typename
          }
          __typename
        }
        phone_number
        phoneNumberConfirmed
        pushNotificationToken
        pushNotificationReadDate
        createdAt
        updatedAt
        __typename
      }
      employers {
        items {
          id
          jobRole
          businessId
          profileId
          employeeId
          tippingPolicyAcknowledgedDate
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          confirmed
          __typename
        }
        nextToken
        __typename
      }
      experiences {
        items {
          id
          jobTitle
          businessId
          companyName
          profileId
          startDate
          endDate
          employmentType
          location
          description
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      jobs {
        items {
          id
          profileId
          jobId
          coverLetter
          saved
          savedDate
          applied
          appliedDate
          createdAt
          updatedAt
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          job {
            id
            owner
            jobType
            businessId
            jobRole
            title
            description
            salary {
              type
              from
              to
              __typename
            }
            region
            requireCV
            requireCoverLetter
            createdAt
            archived
            startDate
            endDate
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            reported {
              items {
                id
                postId
                userId
                commentId
                jobId
                creatorId
                createdAt
                message
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          rejected
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    total
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchProfilesQueryVariables,
  APITypes.SearchProfilesQuery
>;
export const getProfile = /* GraphQL */ `query GetProfile($id: ID!) {
  getProfile(id: $id) {
    id
    slug
    firstName
    lastName
    bio
    picture
    location {
      searchName
      address
      address2
      city
      region
      postcode
      country
      latitude
      longitude
      __typename
    }
    createdAt
    updatedAt
    business {
      id
      verified
      name
      tipping {
        merchantId
        policy
        type
        active
        __typename
      }
      marketing {
        customerId
        subscriptionId
        active
        expirationDate
        sequenceId
        cancelled
        cancelledAt
        __typename
      }
      createdAt
      updatedAt
      owner
      code
      answers {
        questionId
        value
        __typename
      }
      isOutlet
      details {
        id
        contactName
        jobRole
        phone
        email
        website
        location {
          searchName
          address
          address2
          city
          region
          postcode
          country
          latitude
          longitude
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      ownerProfile {
        id
        slug
        firstName
        lastName
        bio
        picture
        location {
          searchName
          address
          address2
          city
          region
          postcode
          country
          latitude
          longitude
          __typename
        }
        createdAt
        updatedAt
        business {
          id
          verified
          name
          tipping {
            merchantId
            policy
            type
            active
            __typename
          }
          marketing {
            customerId
            subscriptionId
            active
            expirationDate
            sequenceId
            cancelled
            cancelledAt
            __typename
          }
          createdAt
          updatedAt
          owner
          code
          answers {
            questionId
            value
            __typename
          }
          isOutlet
          details {
            id
            contactName
            jobRole
            phone
            email
            website
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          ownerProfile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          invites {
            items {
              id
              businessId
              receiver
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          employees {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          brands {
            items {
              id
              businessId
              brandId
              status
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profileId
              brand {
                id
                name
                logo
                active
                productType
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        archived
        details {
          id
          accountType
          isOnboardingComplete
          email
          feedRegion
          tippingAccount {
            code
            merchant
            accountId
            verified
            missingRequirements {
              key
              description
              __typename
            }
            pendingValidation
            bankDetails {
              name
              sortCode
              last4Digits
              __typename
            }
            __typename
          }
          phone_number
          phoneNumberConfirmed
          pushNotificationToken
          pushNotificationReadDate
          createdAt
          updatedAt
          __typename
        }
        employers {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        experiences {
          items {
            id
            jobTitle
            businessId
            companyName
            profileId
            startDate
            endDate
            employmentType
            location
            description
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        jobs {
          items {
            id
            profileId
            jobId
            coverLetter
            saved
            savedDate
            applied
            appliedDate
            createdAt
            updatedAt
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            job {
              id
              owner
              jobType
              businessId
              jobRole
              title
              description
              salary {
                type
                from
                to
                __typename
              }
              region
              requireCV
              requireCoverLetter
              createdAt
              archived
              startDate
              endDate
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              reported {
                nextToken
                __typename
              }
              __typename
            }
            rejected
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      invites {
        items {
          id
          businessId
          receiver
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      employees {
        items {
          id
          jobRole
          businessId
          profileId
          employeeId
          tippingPolicyAcknowledgedDate
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          confirmed
          __typename
        }
        nextToken
        __typename
      }
      brands {
        items {
          id
          businessId
          brandId
          status
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profileId
          brand {
            id
            name
            logo
            active
            productType
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    archived
    details {
      id
      accountType
      isOnboardingComplete
      email
      feedRegion
      tippingAccount {
        code
        merchant
        accountId
        verified
        missingRequirements {
          key
          description
          __typename
        }
        pendingValidation
        bankDetails {
          name
          sortCode
          last4Digits
          __typename
        }
        __typename
      }
      phone_number
      phoneNumberConfirmed
      pushNotificationToken
      pushNotificationReadDate
      createdAt
      updatedAt
      __typename
    }
    employers {
      items {
        id
        jobRole
        businessId
        profileId
        employeeId
        tippingPolicyAcknowledgedDate
        createdAt
        updatedAt
        business {
          id
          verified
          name
          tipping {
            merchantId
            policy
            type
            active
            __typename
          }
          marketing {
            customerId
            subscriptionId
            active
            expirationDate
            sequenceId
            cancelled
            cancelledAt
            __typename
          }
          createdAt
          updatedAt
          owner
          code
          answers {
            questionId
            value
            __typename
          }
          isOutlet
          details {
            id
            contactName
            jobRole
            phone
            email
            website
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          ownerProfile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          invites {
            items {
              id
              businessId
              receiver
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          employees {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          brands {
            items {
              id
              businessId
              brandId
              status
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profileId
              brand {
                id
                name
                logo
                active
                productType
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        profile {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        confirmed
        __typename
      }
      nextToken
      __typename
    }
    experiences {
      items {
        id
        verified
        name
        tipping {
          merchantId
          policy
          type
          active
          __typename
        }
        marketing {
          customerId
          subscriptionId
          active
          expirationDate
          sequenceId
          cancelled
          cancelledAt
          __typename
        }
        createdAt
        updatedAt
        owner
        code
        answers {
          questionId
          value
          __typename
        }
        isOutlet
        details {
          id
          verified
          name
          tipping {
            merchantId
            policy
            type
            active
            __typename
          }
          marketing {
            customerId
            subscriptionId
            active
            expirationDate
            sequenceId
            cancelled
            cancelledAt
            __typename
          }
          createdAt
          updatedAt
          owner
          code
          answers {
            questionId
            value
            __typename
          }
          isOutlet
          details {
            id
            contactName
            jobRole
            phone
            email
            website
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          ownerProfile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          invites {
            items {
              id
              businessId
              receiver
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          employees {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          brands {
            items {
              id
              businessId
              brandId
              status
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profileId
              brand {
                id
                name
                logo
                active
                productType
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        ownerProfile {
          id
          slug
          firstName
          lastName
          bio
          picture
          createdAt
          updatedAt
          archived
          __typename
        }
        invites {
          nextToken
          __typename
        }
        employees {
          nextToken
          __typename
        }
        brands {
          nextToken
          __typename
        }
        __typename
      }
      archived
      details {
        id
        accountType
        isOnboardingComplete
        email
        feedRegion
        tippingAccount {
          code
          merchant
          accountId
          verified
          pendingValidation
          __typename
        }
        phone_number
        phoneNumberConfirmed
        pushNotificationToken
        pushNotificationReadDate
        createdAt
        updatedAt
        __typename
      }
      employers {
        items {
          id
          jobRole
          businessId
          profileId
          employeeId
          tippingPolicyAcknowledgedDate
          createdAt
          updatedAt
          confirmed
          __typename
        }
        nextToken
        __typename
      }
      experiences {
        items {
          id
          jobTitle
          businessId
          companyName
          profileId
          startDate
          endDate
          employmentType
          location
          description
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      jobs {
        items {
          id
          profileId
          jobId
          coverLetter
          saved
          savedDate
          applied
          appliedDate
          createdAt
          updatedAt
          rejected
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    comments {
      items {
        id
        postId
        commentId
        content
        creatorId
        createdAt
        archived
        attachments {
          mediaType
          source
          alt
          __typename
        }
        updatedAt
        creator {
          id
          slug
          firstName
          lastName
          bio
          picture
          createdAt
          updatedAt
          archived
          __typename
        }
        post {
          id
          hubId
          creatorId
          content
          createdAt
          archived
          feedRegion
          regions
          updatedAt
          __typename
        }
        comments {
          nextToken
          __typename
        }
        reported {
          nextToken
          __typename
        }
        likes {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    reported {
      items {
        id
        postId
        userId
        commentId
        jobId
        creatorId
        createdAt
        message
        updatedAt
        creator {
          id
          slug
          firstName
          lastName
          bio
          picture
          createdAt
          updatedAt
          archived
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    likes {
      items {
        id
        postId
        profileId
        createdAt
        updatedAt
        profile {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    jobs {
      items {
        id
        profileId
        jobId
        coverLetter
        saved
        savedDate
        applied
        appliedDate
        createdAt
        updatedAt
        profile {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        job {
          id
          owner
          jobType
          businessId
          jobRole
          title
          description
          salary {
            type
            from
            to
            __typename
          }
          region
          requireCV
          requireCoverLetter
          createdAt
          archived
          startDate
          endDate
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          reported {
            items {
              id
              postId
              userId
              commentId
              jobId
              creatorId
              createdAt
              message
              updatedAt
              creator {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        rejected
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetProfileQueryVariables,
  APITypes.GetProfileQuery
>;
export const listProfiles = /* GraphQL */ `query ListProfiles(
  $filter: ModelProfileFilterInput
  $limit: Int
  $nextToken: String
) {
  listProfiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      slug
      firstName
      lastName
      bio
      picture
      location {
        searchName
        address
        address2
        city
        region
        postcode
        country
        latitude
        longitude
        __typename
      }
      createdAt
      updatedAt
      business {
        id
        verified
        name
        tipping {
          merchantId
          policy
          type
          active
          __typename
        }
        marketing {
          customerId
          subscriptionId
          active
          expirationDate
          sequenceId
          cancelled
          cancelledAt
          __typename
        }
        createdAt
        updatedAt
        owner
        code
        answers {
          questionId
          value
          __typename
        }
        isOutlet
        details {
          id
          contactName
          jobRole
          phone
          email
          website
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        ownerProfile {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        invites {
          items {
            id
            businessId
            receiver
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        employees {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        brands {
          items {
            id
            businessId
            brandId
            status
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profileId
            brand {
              id
              name
              logo
              active
              productType
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      archived
      details {
        id
        accountType
        isOnboardingComplete
        email
        feedRegion
        tippingAccount {
          code
          merchant
          accountId
          verified
          missingRequirements {
            key
            description
            __typename
          }
          pendingValidation
          bankDetails {
            name
            sortCode
            last4Digits
            __typename
          }
          __typename
        }
        phone_number
        phoneNumberConfirmed
        pushNotificationToken
        pushNotificationReadDate
        createdAt
        updatedAt
        __typename
      }
      employers {
        items {
          id
          jobRole
          businessId
          profileId
          employeeId
          tippingPolicyAcknowledgedDate
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          confirmed
          __typename
        }
        nextToken
        __typename
      }
      experiences {
        items {
          id
          jobTitle
          businessId
          companyName
          profileId
          startDate
          endDate
          employmentType
          location
          description
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      jobs {
        items {
          id
          profileId
          jobId
          coverLetter
          saved
          savedDate
          applied
          appliedDate
          createdAt
          updatedAt
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          job {
            id
            owner
            jobType
            businessId
            jobRole
            title
            description
            salary {
              type
              from
              to
              __typename
            }
            region
            requireCV
            requireCoverLetter
            createdAt
            archived
            startDate
            endDate
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            reported {
              items {
                id
                postId
                userId
                commentId
                jobId
                creatorId
                createdAt
                message
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          rejected
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListProfilesQueryVariables,
  APITypes.ListProfilesQuery
>;
export const getProfileDetails = /* GraphQL */ `query GetProfileDetails($id: ID!) {
  getProfileDetails(id: $id) {
    id
    accountType
    isOnboardingComplete
    email
    feedRegion
    tippingAccount {
      code
      merchant
      accountId
      verified
      missingRequirements {
        key
        description
        __typename
      }
      pendingValidation
      bankDetails {
        name
        sortCode
        last4Digits
        __typename
      }
      __typename
    }
    phone_number
    phoneNumberConfirmed
    pushNotificationToken
    pushNotificationReadDate
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetProfileDetailsQueryVariables,
  APITypes.GetProfileDetailsQuery
>;
export const listProfileDetailss = /* GraphQL */ `query ListProfileDetailss(
  $filter: ModelProfileDetailsFilterInput
  $limit: Int
  $nextToken: String
) {
  listProfileDetailss(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      accountType
      isOnboardingComplete
      email
      feedRegion
      tippingAccount {
        code
        merchant
        accountId
        verified
        missingRequirements {
          key
          description
          __typename
        }
        pendingValidation
        bankDetails {
          name
          sortCode
          last4Digits
          __typename
        }
        __typename
      }
      phone_number
      phoneNumberConfirmed
      pushNotificationToken
      pushNotificationReadDate
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListProfileDetailssQueryVariables,
  APITypes.ListProfileDetailssQuery
>;
export const searchProfileDetailss = /* GraphQL */ `query SearchProfileDetailss(
  $filter: SearchableProfileDetailsFilterInput
  $sort: SearchableProfileDetailsSortInput
  $limit: Int
  $nextToken: String
  $from: Int
) {
  searchProfileDetailss(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
  ) {
    items {
      id
      accountType
      isOnboardingComplete
      email
      feedRegion
      tippingAccount {
        code
        merchant
        accountId
        verified
        missingRequirements {
          key
          description
          __typename
        }
        pendingValidation
        bankDetails {
          name
          sortCode
          last4Digits
          __typename
        }
        __typename
      }
      phone_number
      phoneNumberConfirmed
      pushNotificationToken
      pushNotificationReadDate
      createdAt
      updatedAt
      __typename
    }
    nextToken
    total
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchProfileDetailssQueryVariables,
  APITypes.SearchProfileDetailssQuery
>;
export const businessEmployeeByProfile = /* GraphQL */ `query BusinessEmployeeByProfile(
  $profileId: ID
  $businessId: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelBusinessEmployeeFilterInput
  $limit: Int
  $nextToken: String
) {
  businessEmployeeByProfile(
    profileId: $profileId
    businessId: $businessId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      jobRole
      businessId
      profileId
      employeeId
      tippingPolicyAcknowledgedDate
      createdAt
      updatedAt
      business {
        id
        verified
        name
        tipping {
          merchantId
          policy
          type
          active
          __typename
        }
        marketing {
          customerId
          subscriptionId
          active
          expirationDate
          sequenceId
          cancelled
          cancelledAt
          __typename
        }
        createdAt
        updatedAt
        owner
        code
        answers {
          questionId
          value
          __typename
        }
        isOutlet
        details {
          id
          contactName
          jobRole
          phone
          email
          website
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        ownerProfile {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        invites {
          items {
            id
            businessId
            receiver
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        employees {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        brands {
          items {
            id
            businessId
            brandId
            status
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profileId
            brand {
              id
              name
              logo
              active
              productType
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      profile {
        id
        slug
        firstName
        lastName
        bio
        picture
        location {
          searchName
          address
          address2
          city
          region
          postcode
          country
          latitude
          longitude
          __typename
        }
        createdAt
        updatedAt
        business {
          id
          verified
          name
          tipping {
            merchantId
            policy
            type
            active
            __typename
          }
          marketing {
            customerId
            subscriptionId
            active
            expirationDate
            sequenceId
            cancelled
            cancelledAt
            __typename
          }
          createdAt
          updatedAt
          owner
          code
          answers {
            questionId
            value
            __typename
          }
          isOutlet
          details {
            id
            contactName
            jobRole
            phone
            email
            website
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          ownerProfile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          invites {
            items {
              id
              businessId
              receiver
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          employees {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          brands {
            items {
              id
              businessId
              brandId
              status
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profileId
              brand {
                id
                name
                logo
                active
                productType
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        archived
        details {
          id
          accountType
          isOnboardingComplete
          email
          feedRegion
          tippingAccount {
            code
            merchant
            accountId
            verified
            missingRequirements {
              key
              description
              __typename
            }
            pendingValidation
            bankDetails {
              name
              sortCode
              last4Digits
              __typename
            }
            __typename
          }
          phone_number
          phoneNumberConfirmed
          pushNotificationToken
          pushNotificationReadDate
          createdAt
          updatedAt
          __typename
        }
        employers {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        experiences {
          items {
            id
            jobTitle
            businessId
            companyName
            profileId
            startDate
            endDate
            employmentType
            location
            description
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        jobs {
          items {
            id
            profileId
            jobId
            coverLetter
            saved
            savedDate
            applied
            appliedDate
            createdAt
            updatedAt
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            job {
              id
              owner
              jobType
              businessId
              jobRole
              title
              description
              salary {
                type
                from
                to
                __typename
              }
              region
              requireCV
              requireCoverLetter
              createdAt
              archived
              startDate
              endDate
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              reported {
                nextToken
                __typename
              }
              __typename
            }
            rejected
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      confirmed
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.BusinessEmployeeByProfileQueryVariables,
  APITypes.BusinessEmployeeByProfileQuery
>;
export const getBusinessEmployee = /* GraphQL */ `query GetBusinessEmployee($id: ID!) {
  getBusinessEmployee(id: $id) {
    id
    jobRole
    businessId
    profileId
    employeeId
    tippingPolicyAcknowledgedDate
    createdAt
    updatedAt
    business {
      id
      verified
      name
      tipping {
        merchantId
        policy
        type
        active
        __typename
      }
      marketing {
        customerId
        subscriptionId
        active
        expirationDate
        sequenceId
        cancelled
        cancelledAt
        __typename
      }
      createdAt
      updatedAt
      owner
      code
      answers {
        questionId
        value
        __typename
      }
      isOutlet
      details {
        id
        contactName
        jobRole
        phone
        email
        website
        location {
          searchName
          address
          address2
          city
          region
          postcode
          country
          latitude
          longitude
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      ownerProfile {
        id
        slug
        firstName
        lastName
        bio
        picture
        location {
          searchName
          address
          address2
          city
          region
          postcode
          country
          latitude
          longitude
          __typename
        }
        createdAt
        updatedAt
        business {
          id
          verified
          name
          tipping {
            merchantId
            policy
            type
            active
            __typename
          }
          marketing {
            customerId
            subscriptionId
            active
            expirationDate
            sequenceId
            cancelled
            cancelledAt
            __typename
          }
          createdAt
          updatedAt
          owner
          code
          answers {
            questionId
            value
            __typename
          }
          isOutlet
          details {
            id
            contactName
            jobRole
            phone
            email
            website
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          ownerProfile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          invites {
            items {
              id
              businessId
              receiver
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          employees {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          brands {
            items {
              id
              businessId
              brandId
              status
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profileId
              brand {
                id
                name
                logo
                active
                productType
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        archived
        details {
          id
          accountType
          isOnboardingComplete
          email
          feedRegion
          tippingAccount {
            code
            merchant
            accountId
            verified
            missingRequirements {
              key
              description
              __typename
            }
            pendingValidation
            bankDetails {
              name
              sortCode
              last4Digits
              __typename
            }
            __typename
          }
          phone_number
          phoneNumberConfirmed
          pushNotificationToken
          pushNotificationReadDate
          createdAt
          updatedAt
          __typename
        }
        employers {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        experiences {
          items {
            id
            jobTitle
            businessId
            companyName
            profileId
            startDate
            endDate
            employmentType
            location
            description
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        jobs {
          items {
            id
            profileId
            jobId
            coverLetter
            saved
            savedDate
            applied
            appliedDate
            createdAt
            updatedAt
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            job {
              id
              owner
              jobType
              businessId
              jobRole
              title
              description
              salary {
                type
                from
                to
                __typename
              }
              region
              requireCV
              requireCoverLetter
              createdAt
              archived
              startDate
              endDate
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              reported {
                nextToken
                __typename
              }
              __typename
            }
            rejected
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      invites {
        items {
          id
          businessId
          receiver
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      employees {
        items {
          id
          jobRole
          businessId
          profileId
          employeeId
          tippingPolicyAcknowledgedDate
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          confirmed
          __typename
        }
        nextToken
        __typename
      }
      brands {
        items {
          id
          businessId
          brandId
          status
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profileId
          brand {
            id
            name
            logo
            active
            productType
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    profile {
      id
      slug
      firstName
      lastName
      bio
      picture
      location {
        searchName
        address
        address2
        city
        region
        postcode
        country
        latitude
        longitude
        __typename
      }
      createdAt
      updatedAt
      business {
        id
        verified
        name
        tipping {
          merchantId
          policy
          type
          active
          __typename
        }
        marketing {
          customerId
          subscriptionId
          active
          expirationDate
          sequenceId
          cancelled
          cancelledAt
          __typename
        }
        createdAt
        updatedAt
        owner
        code
        answers {
          questionId
          value
          __typename
        }
        isOutlet
        details {
          id
          contactName
          jobRole
          phone
          email
          website
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        ownerProfile {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        invites {
          items {
            id
            businessId
            receiver
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        employees {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        brands {
          items {
            id
            businessId
            brandId
            status
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profileId
            brand {
              id
              name
              logo
              active
              productType
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      archived
      details {
        id
        accountType
        isOnboardingComplete
        email
        feedRegion
        tippingAccount {
          code
          merchant
          accountId
          verified
          missingRequirements {
            key
            description
            __typename
          }
          pendingValidation
          bankDetails {
            name
            sortCode
            last4Digits
            __typename
          }
          __typename
        }
        phone_number
        phoneNumberConfirmed
        pushNotificationToken
        pushNotificationReadDate
        createdAt
        updatedAt
        __typename
      }
      employers {
        items {
          id
          jobRole
          businessId
          profileId
          employeeId
          tippingPolicyAcknowledgedDate
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          confirmed
          __typename
        }
        nextToken
        __typename
      }
      experiences {
        items {
          id
          jobTitle
          businessId
          companyName
          profileId
          startDate
          endDate
          employmentType
          location
          description
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      jobs {
        items {
          id
          profileId
          jobId
          coverLetter
          saved
          savedDate
          applied
          appliedDate
          createdAt
          updatedAt
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          job {
            id
            owner
            jobType
            businessId
            jobRole
            title
            description
            salary {
              type
              from
              to
              __typename
            }
            region
            requireCV
            requireCoverLetter
            createdAt
            archived
            startDate
            endDate
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            reported {
              items {
                id
                postId
                userId
                commentId
                jobId
                creatorId
                createdAt
                message
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          rejected
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    confirmed
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBusinessEmployeeQueryVariables,
  APITypes.GetBusinessEmployeeQuery
>;
export const listBusinessEmployees = /* GraphQL */ `query ListBusinessEmployees(
  $filter: ModelBusinessEmployeeFilterInput
  $limit: Int
  $nextToken: String
) {
  listBusinessEmployees(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      jobRole
      businessId
      profileId
      employeeId
      tippingPolicyAcknowledgedDate
      createdAt
      updatedAt
      business {
        id
        verified
        name
        tipping {
          merchantId
          policy
          type
          active
          __typename
        }
        marketing {
          customerId
          subscriptionId
          active
          expirationDate
          sequenceId
          cancelled
          cancelledAt
          __typename
        }
        createdAt
        updatedAt
        owner
        code
        answers {
          questionId
          value
          __typename
        }
        isOutlet
        details {
          id
          contactName
          jobRole
          phone
          email
          website
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        ownerProfile {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        invites {
          items {
            id
            businessId
            receiver
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        employees {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        brands {
          items {
            id
            businessId
            brandId
            status
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profileId
            brand {
              id
              name
              logo
              active
              productType
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      profile {
        id
        slug
        firstName
        lastName
        bio
        picture
        location {
          searchName
          address
          address2
          city
          region
          postcode
          country
          latitude
          longitude
          __typename
        }
        createdAt
        updatedAt
        business {
          id
          verified
          name
          tipping {
            merchantId
            policy
            type
            active
            __typename
          }
          marketing {
            customerId
            subscriptionId
            active
            expirationDate
            sequenceId
            cancelled
            cancelledAt
            __typename
          }
          createdAt
          updatedAt
          owner
          code
          answers {
            questionId
            value
            __typename
          }
          isOutlet
          details {
            id
            contactName
            jobRole
            phone
            email
            website
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          ownerProfile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          invites {
            items {
              id
              businessId
              receiver
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          employees {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          brands {
            items {
              id
              businessId
              brandId
              status
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profileId
              brand {
                id
                name
                logo
                active
                productType
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        archived
        details {
          id
          accountType
          isOnboardingComplete
          email
          feedRegion
          tippingAccount {
            code
            merchant
            accountId
            verified
            missingRequirements {
              key
              description
              __typename
            }
            pendingValidation
            bankDetails {
              name
              sortCode
              last4Digits
              __typename
            }
            __typename
          }
          phone_number
          phoneNumberConfirmed
          pushNotificationToken
          pushNotificationReadDate
          createdAt
          updatedAt
          __typename
        }
        employers {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        experiences {
          items {
            id
            jobTitle
            businessId
            companyName
            profileId
            startDate
            endDate
            employmentType
            location
            description
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        jobs {
          items {
            id
            profileId
            jobId
            coverLetter
            saved
            savedDate
            applied
            appliedDate
            createdAt
            updatedAt
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            job {
              id
              owner
              jobType
              businessId
              jobRole
              title
              description
              salary {
                type
                from
                to
                __typename
              }
              region
              requireCV
              requireCoverLetter
              createdAt
              archived
              startDate
              endDate
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              reported {
                nextToken
                __typename
              }
              __typename
            }
            rejected
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      confirmed
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBusinessEmployeesQueryVariables,
  APITypes.ListBusinessEmployeesQuery
>;
export const getBusinessBrands = /* GraphQL */ `query GetBusinessBrands($id: ID!) {
  getBusinessBrands(id: $id) {
    id
    businessId
    brandId
    status
    createdAt
    updatedAt
    business {
      id
      verified
      name
      tipping {
        merchantId
        policy
        type
        active
        __typename
      }
      marketing {
        customerId
        subscriptionId
        active
        expirationDate
        sequenceId
        cancelled
        cancelledAt
        __typename
      }
      createdAt
      updatedAt
      owner
      code
      answers {
        questionId
        value
        __typename
      }
      isOutlet
      details {
        id
        contactName
        jobRole
        phone
        email
        website
        location {
          searchName
          address
          address2
          city
          region
          postcode
          country
          latitude
          longitude
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      ownerProfile {
        id
        slug
        firstName
        lastName
        bio
        picture
        location {
          searchName
          address
          address2
          city
          region
          postcode
          country
          latitude
          longitude
          __typename
        }
        createdAt
        updatedAt
        business {
          id
          verified
          name
          tipping {
            merchantId
            policy
            type
            active
            __typename
          }
          marketing {
            customerId
            subscriptionId
            active
            expirationDate
            sequenceId
            cancelled
            cancelledAt
            __typename
          }
          createdAt
          updatedAt
          owner
          code
          answers {
            questionId
            value
            __typename
          }
          isOutlet
          details {
            id
            contactName
            jobRole
            phone
            email
            website
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          ownerProfile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          invites {
            items {
              id
              businessId
              receiver
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          employees {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          brands {
            items {
              id
              businessId
              brandId
              status
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profileId
              brand {
                id
                name
                logo
                active
                productType
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        archived
        details {
          id
          accountType
          isOnboardingComplete
          email
          feedRegion
          tippingAccount {
            code
            merchant
            accountId
            verified
            missingRequirements {
              key
              description
              __typename
            }
            pendingValidation
            bankDetails {
              name
              sortCode
              last4Digits
              __typename
            }
            __typename
          }
          phone_number
          phoneNumberConfirmed
          pushNotificationToken
          pushNotificationReadDate
          createdAt
          updatedAt
          __typename
        }
        employers {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        experiences {
          items {
            id
            jobTitle
            businessId
            companyName
            profileId
            startDate
            endDate
            employmentType
            location
            description
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        jobs {
          items {
            id
            profileId
            jobId
            coverLetter
            saved
            savedDate
            applied
            appliedDate
            createdAt
            updatedAt
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            job {
              id
              owner
              jobType
              businessId
              jobRole
              title
              description
              salary {
                type
                from
                to
                __typename
              }
              region
              requireCV
              requireCoverLetter
              createdAt
              archived
              startDate
              endDate
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              reported {
                nextToken
                __typename
              }
              __typename
            }
            rejected
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      invites {
        items {
          id
          businessId
          receiver
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      employees {
        items {
          id
          jobRole
          businessId
          profileId
          employeeId
          tippingPolicyAcknowledgedDate
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          confirmed
          __typename
        }
        nextToken
        __typename
      }
      brands {
        items {
          id
          businessId
          brandId
          status
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profileId
          brand {
            id
            name
            logo
            active
            productType
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    profileId
    brand {
      id
      name
      logo
      active
      productType
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBusinessBrandsQueryVariables,
  APITypes.GetBusinessBrandsQuery
>;
export const listBusinessBrandss = /* GraphQL */ `query ListBusinessBrandss(
  $filter: ModelBusinessBrandsFilterInput
  $limit: Int
  $nextToken: String
) {
  listBusinessBrandss(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      businessId
      brandId
      status
      createdAt
      updatedAt
      business {
        id
        verified
        name
        tipping {
          merchantId
          policy
          type
          active
          __typename
        }
        marketing {
          customerId
          subscriptionId
          active
          expirationDate
          sequenceId
          cancelled
          cancelledAt
          __typename
        }
        createdAt
        updatedAt
        owner
        code
        answers {
          questionId
          value
          __typename
        }
        isOutlet
        details {
          id
          contactName
          jobRole
          phone
          email
          website
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        ownerProfile {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        invites {
          items {
            id
            businessId
            receiver
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        employees {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        brands {
          items {
            id
            businessId
            brandId
            status
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profileId
            brand {
              id
              name
              logo
              active
              productType
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      profileId
      brand {
        id
        name
        logo
        active
        productType
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBusinessBrandssQueryVariables,
  APITypes.ListBusinessBrandssQuery
>;
export const businessBrandsByBrand = /* GraphQL */ `query BusinessBrandsByBrand(
  $brandId: ID
  $businessId: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelBusinessBrandsFilterInput
  $limit: Int
  $nextToken: String
) {
  businessBrandsByBrand(
    brandId: $brandId
    businessId: $businessId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      businessId
      brandId
      status
      createdAt
      updatedAt
      business {
        id
        verified
        name
        tipping {
          merchantId
          policy
          type
          active
          __typename
        }
        marketing {
          customerId
          subscriptionId
          active
          expirationDate
          sequenceId
          cancelled
          cancelledAt
          __typename
        }
        createdAt
        updatedAt
        owner
        code
        answers {
          questionId
          value
          __typename
        }
        isOutlet
        details {
          id
          contactName
          jobRole
          phone
          email
          website
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        ownerProfile {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        invites {
          items {
            id
            businessId
            receiver
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        employees {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        brands {
          items {
            id
            businessId
            brandId
            status
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profileId
            brand {
              id
              name
              logo
              active
              productType
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      profileId
      brand {
        id
        name
        logo
        active
        productType
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.BusinessBrandsByBrandQueryVariables,
  APITypes.BusinessBrandsByBrandQuery
>;
export const businessBrandsByBusiness = /* GraphQL */ `query BusinessBrandsByBusiness(
  $businessId: ID
  $brandId: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelBusinessBrandsFilterInput
  $limit: Int
  $nextToken: String
) {
  businessBrandsByBusiness(
    businessId: $businessId
    brandId: $brandId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      businessId
      brandId
      status
      createdAt
      updatedAt
      business {
        id
        verified
        name
        tipping {
          merchantId
          policy
          type
          active
          __typename
        }
        marketing {
          customerId
          subscriptionId
          active
          expirationDate
          sequenceId
          cancelled
          cancelledAt
          __typename
        }
        createdAt
        updatedAt
        owner
        code
        answers {
          questionId
          value
          __typename
        }
        isOutlet
        details {
          id
          contactName
          jobRole
          phone
          email
          website
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        ownerProfile {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        invites {
          items {
            id
            businessId
            receiver
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        employees {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        brands {
          items {
            id
            businessId
            brandId
            status
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profileId
            brand {
              id
              name
              logo
              active
              productType
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      profileId
      brand {
        id
        name
        logo
        active
        productType
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.BusinessBrandsByBusinessQueryVariables,
  APITypes.BusinessBrandsByBusinessQuery
>;
export const getBrand = /* GraphQL */ `query GetBrand($id: ID!) {
  getBrand(id: $id) {
    id
    name
    logo
    active
    productType
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetBrandQueryVariables, APITypes.GetBrandQuery>;
export const listBrands = /* GraphQL */ `query ListBrands(
  $filter: ModelBrandFilterInput
  $limit: Int
  $nextToken: String
) {
  listBrands(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      logo
      active
      productType
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBrandsQueryVariables,
  APITypes.ListBrandsQuery
>;
export const searchBrands = /* GraphQL */ `query SearchBrands(
  $filter: SearchableBrandFilterInput
  $sort: SearchableBrandSortInput
  $limit: Int
  $nextToken: String
  $from: Int
) {
  searchBrands(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
  ) {
    items {
      id
      name
      logo
      active
      productType
      createdAt
      updatedAt
      __typename
    }
    nextToken
    total
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchBrandsQueryVariables,
  APITypes.SearchBrandsQuery
>;
export const getExperience = /* GraphQL */ `query GetExperience($id: ID!) {
  getExperience(id: $id) {
    id
    jobTitle
    businessId
    companyName
    profileId
    startDate
    endDate
    employmentType
    location
    description
    createdAt
    updatedAt
    business {
      id
      verified
      name
      tipping {
        merchantId
        policy
        type
        active
        __typename
      }
      marketing {
        customerId
        subscriptionId
        active
        expirationDate
        sequenceId
        cancelled
        cancelledAt
        __typename
      }
      createdAt
      updatedAt
      owner
      code
      answers {
        questionId
        value
        __typename
      }
      isOutlet
      details {
        id
        contactName
        jobRole
        phone
        email
        website
        location {
          searchName
          address
          address2
          city
          region
          postcode
          country
          latitude
          longitude
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      ownerProfile {
        id
        slug
        firstName
        lastName
        bio
        picture
        location {
          searchName
          address
          address2
          city
          region
          postcode
          country
          latitude
          longitude
          __typename
        }
        createdAt
        updatedAt
        business {
          id
          verified
          name
          tipping {
            merchantId
            policy
            type
            active
            __typename
          }
          marketing {
            customerId
            subscriptionId
            active
            expirationDate
            sequenceId
            cancelled
            cancelledAt
            __typename
          }
          createdAt
          updatedAt
          owner
          code
          answers {
            questionId
            value
            __typename
          }
          isOutlet
          details {
            id
            contactName
            jobRole
            phone
            email
            website
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          ownerProfile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          invites {
            items {
              id
              businessId
              receiver
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          employees {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          brands {
            items {
              id
              businessId
              brandId
              status
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profileId
              brand {
                id
                name
                logo
                active
                productType
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        archived
        details {
          id
          accountType
          isOnboardingComplete
          email
          feedRegion
          tippingAccount {
            code
            merchant
            accountId
            verified
            missingRequirements {
              key
              description
              __typename
            }
            pendingValidation
            bankDetails {
              name
              sortCode
              last4Digits
              __typename
            }
            __typename
          }
          phone_number
          phoneNumberConfirmed
          pushNotificationToken
          pushNotificationReadDate
          createdAt
          updatedAt
          __typename
        }
        employers {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        experiences {
          items {
            id
            jobTitle
            businessId
            companyName
            profileId
            startDate
            endDate
            employmentType
            location
            description
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        jobs {
          items {
            id
            profileId
            jobId
            coverLetter
            saved
            savedDate
            applied
            appliedDate
            createdAt
            updatedAt
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            job {
              id
              owner
              jobType
              businessId
              jobRole
              title
              description
              salary {
                type
                from
                to
                __typename
              }
              region
              requireCV
              requireCoverLetter
              createdAt
              archived
              startDate
              endDate
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              reported {
                nextToken
                __typename
              }
              __typename
            }
            rejected
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      invites {
        items {
          id
          businessId
          receiver
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      employees {
        items {
          id
          jobRole
          businessId
          profileId
          employeeId
          tippingPolicyAcknowledgedDate
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          confirmed
          __typename
        }
        nextToken
        __typename
      }
      brands {
        items {
          id
          businessId
          brandId
          status
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profileId
          brand {
            id
            name
            logo
            active
            productType
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    profile {
      id
      slug
      firstName
      lastName
      bio
      picture
      location {
        searchName
        address
        address2
        city
        region
        postcode
        country
        latitude
        longitude
        __typename
      }
      createdAt
      updatedAt
      business {
        id
        verified
        name
        tipping {
          merchantId
          policy
          type
          active
          __typename
        }
        marketing {
          customerId
          subscriptionId
          active
          expirationDate
          sequenceId
          cancelled
          cancelledAt
          __typename
        }
        createdAt
        updatedAt
        owner
        code
        answers {
          questionId
          value
          __typename
        }
        isOutlet
        details {
          id
          contactName
          jobRole
          phone
          email
          website
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        ownerProfile {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        invites {
          items {
            id
            businessId
            receiver
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        employees {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        brands {
          items {
            id
            businessId
            brandId
            status
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profileId
            brand {
              id
              name
              logo
              active
              productType
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      archived
      details {
        id
        accountType
        isOnboardingComplete
        email
        feedRegion
        tippingAccount {
          code
          merchant
          accountId
          verified
          missingRequirements {
            key
            description
            __typename
          }
          pendingValidation
          bankDetails {
            name
            sortCode
            last4Digits
            __typename
          }
          __typename
        }
        phone_number
        phoneNumberConfirmed
        pushNotificationToken
        pushNotificationReadDate
        createdAt
        updatedAt
        __typename
      }
      employers {
        items {
          id
          jobRole
          businessId
          profileId
          employeeId
          tippingPolicyAcknowledgedDate
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          confirmed
          __typename
        }
        nextToken
        __typename
      }
      experiences {
        items {
          id
          jobTitle
          businessId
          companyName
          profileId
          startDate
          endDate
          employmentType
          location
          description
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      jobs {
        items {
          id
          profileId
          jobId
          coverLetter
          saved
          savedDate
          applied
          appliedDate
          createdAt
          updatedAt
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          job {
            id
            owner
            jobType
            businessId
            jobRole
            title
            description
            salary {
              type
              from
              to
              __typename
            }
            region
            requireCV
            requireCoverLetter
            createdAt
            archived
            startDate
            endDate
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            reported {
              items {
                id
                postId
                userId
                commentId
                jobId
                creatorId
                createdAt
                message
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          rejected
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetExperienceQueryVariables,
  APITypes.GetExperienceQuery
>;
export const listExperiences = /* GraphQL */ `query ListExperiences(
  $filter: ModelExperienceFilterInput
  $limit: Int
  $nextToken: String
) {
  listExperiences(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      jobTitle
      businessId
      companyName
      profileId
      startDate
      endDate
      employmentType
      location
      description
      createdAt
      updatedAt
      business {
        id
        verified
        name
        tipping {
          merchantId
          policy
          type
          active
          __typename
        }
        marketing {
          customerId
          subscriptionId
          active
          expirationDate
          sequenceId
          cancelled
          cancelledAt
          __typename
        }
        createdAt
        updatedAt
        owner
        code
        answers {
          questionId
          value
          __typename
        }
        isOutlet
        details {
          id
          contactName
          jobRole
          phone
          email
          website
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        ownerProfile {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        invites {
          items {
            id
            businessId
            receiver
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        employees {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        brands {
          items {
            id
            businessId
            brandId
            status
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profileId
            brand {
              id
              name
              logo
              active
              productType
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      profile {
        id
        slug
        firstName
        lastName
        bio
        picture
        location {
          searchName
          address
          address2
          city
          region
          postcode
          country
          latitude
          longitude
          __typename
        }
        createdAt
        updatedAt
        business {
          id
          verified
          name
          tipping {
            merchantId
            policy
            type
            active
            __typename
          }
          marketing {
            customerId
            subscriptionId
            active
            expirationDate
            sequenceId
            cancelled
            cancelledAt
            __typename
          }
          createdAt
          updatedAt
          owner
          code
          answers {
            questionId
            value
            __typename
          }
          isOutlet
          details {
            id
            contactName
            jobRole
            phone
            email
            website
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          ownerProfile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          invites {
            items {
              id
              businessId
              receiver
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          employees {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          brands {
            items {
              id
              businessId
              brandId
              status
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profileId
              brand {
                id
                name
                logo
                active
                productType
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        archived
        details {
          id
          accountType
          isOnboardingComplete
          email
          feedRegion
          tippingAccount {
            code
            merchant
            accountId
            verified
            missingRequirements {
              key
              description
              __typename
            }
            pendingValidation
            bankDetails {
              name
              sortCode
              last4Digits
              __typename
            }
            __typename
          }
          phone_number
          phoneNumberConfirmed
          pushNotificationToken
          pushNotificationReadDate
          createdAt
          updatedAt
          __typename
        }
        employers {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        experiences {
          items {
            id
            jobTitle
            businessId
            companyName
            profileId
            startDate
            endDate
            employmentType
            location
            description
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        jobs {
          items {
            id
            profileId
            jobId
            coverLetter
            saved
            savedDate
            applied
            appliedDate
            createdAt
            updatedAt
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            job {
              id
              owner
              jobType
              businessId
              jobRole
              title
              description
              salary {
                type
                from
                to
                __typename
              }
              region
              requireCV
              requireCoverLetter
              createdAt
              archived
              startDate
              endDate
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              reported {
                nextToken
                __typename
              }
              __typename
            }
            rejected
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListExperiencesQueryVariables,
  APITypes.ListExperiencesQuery
>;
export const getPost = /* GraphQL */ `query GetPost($id: ID!) {
  getPost(id: $id) {
    id
    hubId
    creatorId
    content
    createdAt
    archived
    feedRegion
    regions
    attachments {
      mediaType
      source
      alt
      __typename
    }
    updatedAt
    creator {
      id
      slug
      firstName
      lastName
      bio
      picture
      location {
        searchName
        address
        address2
        city
        region
        postcode
        country
        latitude
        longitude
        __typename
      }
      createdAt
      updatedAt
      business {
        id
        verified
        name
        tipping {
          merchantId
          policy
          type
          active
          __typename
        }
        marketing {
          customerId
          subscriptionId
          active
          expirationDate
          sequenceId
          cancelled
          cancelledAt
          __typename
        }
        createdAt
        updatedAt
        owner
        code
        answers {
          questionId
          value
          __typename
        }
        isOutlet
        details {
          id
          contactName
          jobRole
          phone
          email
          website
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        ownerProfile {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        invites {
          items {
            id
            businessId
            receiver
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        employees {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        brands {
          items {
            id
            businessId
            brandId
            status
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profileId
            brand {
              id
              name
              logo
              active
              productType
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      archived
      details {
        id
        accountType
        isOnboardingComplete
        email
        feedRegion
        tippingAccount {
          code
          merchant
          accountId
          verified
          missingRequirements {
            key
            description
            __typename
          }
          pendingValidation
          bankDetails {
            name
            sortCode
            last4Digits
            __typename
          }
          __typename
        }
        phone_number
        phoneNumberConfirmed
        pushNotificationToken
        pushNotificationReadDate
        createdAt
        updatedAt
        __typename
      }
      employers {
        items {
          id
          jobRole
          businessId
          profileId
          employeeId
          tippingPolicyAcknowledgedDate
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          confirmed
          __typename
        }
        nextToken
        __typename
      }
      experiences {
        items {
          id
          jobTitle
          businessId
          companyName
          profileId
          startDate
          endDate
          employmentType
          location
          description
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      jobs {
        items {
          id
          profileId
          jobId
          coverLetter
          saved
          savedDate
          applied
          appliedDate
          createdAt
          updatedAt
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          job {
            id
            owner
            jobType
            businessId
            jobRole
            title
            description
            salary {
              type
              from
              to
              __typename
            }
            region
            requireCV
            requireCoverLetter
            createdAt
            archived
            startDate
            endDate
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            reported {
              items {
                id
                postId
                userId
                commentId
                jobId
                creatorId
                createdAt
                message
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          rejected
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    comments {
      items {
        id
        postId
        commentId
        content
        creatorId
        createdAt
        archived
        attachments {
          mediaType
          source
          alt
          __typename
        }
        updatedAt
        creator {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        post {
          id
          hubId
          creatorId
          content
          createdAt
          archived
          feedRegion
          regions
          attachments {
            mediaType
            source
            alt
            __typename
          }
          updatedAt
          creator {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          comments {
            items {
              id
              postId
              commentId
              content
              creatorId
              createdAt
              archived
              attachments {
                mediaType
                source
                alt
                __typename
              }
              updatedAt
              creator {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              post {
                id
                hubId
                creatorId
                content
                createdAt
                archived
                feedRegion
                regions
                updatedAt
                __typename
              }
              comments {
                nextToken
                __typename
              }
              reported {
                nextToken
                __typename
              }
              likes {
                nextToken
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          reported {
            items {
              id
              postId
              userId
              commentId
              jobId
              creatorId
              createdAt
              message
              updatedAt
              creator {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          likes {
            items {
              id
              postId
              profileId
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        comments {
          items {
            id
            postId
            commentId
            content
            creatorId
            createdAt
            archived
            attachments {
              mediaType
              source
              alt
              __typename
            }
            updatedAt
            creator {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            post {
              id
              hubId
              creatorId
              content
              createdAt
              archived
              feedRegion
              regions
              attachments {
                mediaType
                source
                alt
                __typename
              }
              updatedAt
              creator {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              comments {
                nextToken
                __typename
              }
              reported {
                nextToken
                __typename
              }
              likes {
                nextToken
                __typename
              }
              __typename
            }
            comments {
              items {
                id
                postId
                commentId
                content
                creatorId
                createdAt
                archived
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            reported {
              items {
                id
                postId
                userId
                commentId
                jobId
                creatorId
                createdAt
                message
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            likes {
              items {
                id
                commentId
                profileId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        reported {
          items {
            id
            postId
            userId
            commentId
            jobId
            creatorId
            createdAt
            message
            updatedAt
            creator {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        likes {
          items {
            id
            commentId
            profileId
            createdAt
            updatedAt
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    reported {
      items {
        id
        verified
        name
        tipping {
          merchantId
          policy
          type
          active
          __typename
        }
        marketing {
          customerId
          subscriptionId
          active
          expirationDate
          sequenceId
          cancelled
          cancelledAt
          __typename
        }
        createdAt
        updatedAt
        owner
        code
        answers {
          questionId
          value
          __typename
        }
        isOutlet
        details {
          id
          contactName
          jobRole
          phone
          email
          website
          createdAt
          updatedAt
          __typename
        }
        ownerProfile {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    likes {
      items {
        id
        verified
        name
        tipping {
          merchantId
          policy
          type
          active
          __typename
        }
        marketing {
          customerId
          subscriptionId
          active
          expirationDate
          sequenceId
          cancelled
          cancelledAt
          __typename
        }
        createdAt
        updatedAt
        owner
        code
        answers {
          questionId
          value
          __typename
        }
        isOutlet
        details {
          id
          contactName
          jobRole
          phone
          email
          website
          createdAt
          updatedAt
          __typename
        }
        ownerProfile {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<APITypes.GetPostQueryVariables, APITypes.GetPostQuery>;
export const listPosts = /* GraphQL */ `query ListPosts(
  $filter: ModelPostFilterInput
  $limit: Int
  $nextToken: String
) {
  listPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      hubId
      creatorId
      content
      createdAt
      archived
      feedRegion
      regions
      attachments {
        mediaType
        source
        alt
        __typename
      }
      updatedAt
      creator {
        id
        slug
        firstName
        lastName
        bio
        picture
        location {
          searchName
          address
          address2
          city
          region
          postcode
          country
          latitude
          longitude
          __typename
        }
        createdAt
        updatedAt
        business {
          id
          verified
          name
          tipping {
            merchantId
            policy
            type
            active
            __typename
          }
          marketing {
            customerId
            subscriptionId
            active
            expirationDate
            sequenceId
            cancelled
            cancelledAt
            __typename
          }
          createdAt
          updatedAt
          owner
          code
          answers {
            questionId
            value
            __typename
          }
          isOutlet
          details {
            id
            contactName
            jobRole
            phone
            email
            website
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          ownerProfile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          invites {
            items {
              id
              businessId
              receiver
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          employees {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          brands {
            items {
              id
              businessId
              brandId
              status
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profileId
              brand {
                id
                name
                logo
                active
                productType
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        archived
        details {
          id
          accountType
          isOnboardingComplete
          email
          feedRegion
          tippingAccount {
            code
            merchant
            accountId
            verified
            missingRequirements {
              key
              description
              __typename
            }
            pendingValidation
            bankDetails {
              name
              sortCode
              last4Digits
              __typename
            }
            __typename
          }
          phone_number
          phoneNumberConfirmed
          pushNotificationToken
          pushNotificationReadDate
          createdAt
          updatedAt
          __typename
        }
        employers {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        experiences {
          items {
            id
            jobTitle
            businessId
            companyName
            profileId
            startDate
            endDate
            employmentType
            location
            description
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        jobs {
          items {
            id
            profileId
            jobId
            coverLetter
            saved
            savedDate
            applied
            appliedDate
            createdAt
            updatedAt
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            job {
              id
              owner
              jobType
              businessId
              jobRole
              title
              description
              salary {
                type
                from
                to
                __typename
              }
              region
              requireCV
              requireCoverLetter
              createdAt
              archived
              startDate
              endDate
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              reported {
                nextToken
                __typename
              }
              __typename
            }
            rejected
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      comments {
        items {
          id
          postId
          commentId
          content
          creatorId
          createdAt
          archived
          attachments {
            mediaType
            source
            alt
            __typename
          }
          updatedAt
          creator {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          post {
            id
            hubId
            creatorId
            content
            createdAt
            archived
            feedRegion
            regions
            attachments {
              mediaType
              source
              alt
              __typename
            }
            updatedAt
            creator {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            comments {
              items {
                id
                postId
                commentId
                content
                creatorId
                createdAt
                archived
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            reported {
              items {
                id
                postId
                userId
                commentId
                jobId
                creatorId
                createdAt
                message
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            likes {
              items {
                id
                postId
                profileId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          comments {
            items {
              id
              postId
              commentId
              content
              creatorId
              createdAt
              archived
              attachments {
                mediaType
                source
                alt
                __typename
              }
              updatedAt
              creator {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              post {
                id
                hubId
                creatorId
                content
                createdAt
                archived
                feedRegion
                regions
                updatedAt
                __typename
              }
              comments {
                nextToken
                __typename
              }
              reported {
                nextToken
                __typename
              }
              likes {
                nextToken
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          reported {
            items {
              id
              postId
              userId
              commentId
              jobId
              creatorId
              createdAt
              message
              updatedAt
              creator {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          likes {
            items {
              id
              commentId
              profileId
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      reported {
        items {
          id
          postId
          userId
          commentId
          jobId
          creatorId
          createdAt
          message
          updatedAt
          creator {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      likes {
        items {
          id
          postId
          profileId
          createdAt
          updatedAt
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListPostsQueryVariables, APITypes.ListPostsQuery>;
export const searchPosts = /* GraphQL */ `query SearchPosts(
  $filter: SearchablePostFilterInput
  $sort: SearchablePostSortInput
  $limit: Int
  $nextToken: String
  $from: Int
) {
  searchPosts(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
  ) {
    items {
      id
      hubId
      creatorId
      content
      createdAt
      archived
      feedRegion
      regions
      attachments {
        mediaType
        source
        alt
        __typename
      }
      updatedAt
      creator {
        id
        slug
        firstName
        lastName
        bio
        picture
        location {
          searchName
          address
          address2
          city
          region
          postcode
          country
          latitude
          longitude
          __typename
        }
        createdAt
        updatedAt
        business {
          id
          verified
          name
          tipping {
            merchantId
            policy
            type
            active
            __typename
          }
          marketing {
            customerId
            subscriptionId
            active
            expirationDate
            sequenceId
            cancelled
            cancelledAt
            __typename
          }
          createdAt
          updatedAt
          owner
          code
          answers {
            questionId
            value
            __typename
          }
          isOutlet
          details {
            id
            contactName
            jobRole
            phone
            email
            website
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          ownerProfile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          invites {
            items {
              id
              businessId
              receiver
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          employees {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          brands {
            items {
              id
              businessId
              brandId
              status
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profileId
              brand {
                id
                name
                logo
                active
                productType
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        archived
        details {
          id
          accountType
          isOnboardingComplete
          email
          feedRegion
          tippingAccount {
            code
            merchant
            accountId
            verified
            missingRequirements {
              key
              description
              __typename
            }
            pendingValidation
            bankDetails {
              name
              sortCode
              last4Digits
              __typename
            }
            __typename
          }
          phone_number
          phoneNumberConfirmed
          pushNotificationToken
          pushNotificationReadDate
          createdAt
          updatedAt
          __typename
        }
        employers {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        experiences {
          items {
            id
            jobTitle
            businessId
            companyName
            profileId
            startDate
            endDate
            employmentType
            location
            description
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        jobs {
          items {
            id
            profileId
            jobId
            coverLetter
            saved
            savedDate
            applied
            appliedDate
            createdAt
            updatedAt
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            job {
              id
              owner
              jobType
              businessId
              jobRole
              title
              description
              salary {
                type
                from
                to
                __typename
              }
              region
              requireCV
              requireCoverLetter
              createdAt
              archived
              startDate
              endDate
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              reported {
                nextToken
                __typename
              }
              __typename
            }
            rejected
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      comments {
        items {
          id
          postId
          commentId
          content
          creatorId
          createdAt
          archived
          attachments {
            mediaType
            source
            alt
            __typename
          }
          updatedAt
          creator {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          post {
            id
            hubId
            creatorId
            content
            createdAt
            archived
            feedRegion
            regions
            attachments {
              mediaType
              source
              alt
              __typename
            }
            updatedAt
            creator {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            comments {
              items {
                id
                postId
                commentId
                content
                creatorId
                createdAt
                archived
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            reported {
              items {
                id
                postId
                userId
                commentId
                jobId
                creatorId
                createdAt
                message
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            likes {
              items {
                id
                postId
                profileId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          comments {
            items {
              id
              postId
              commentId
              content
              creatorId
              createdAt
              archived
              attachments {
                mediaType
                source
                alt
                __typename
              }
              updatedAt
              creator {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              post {
                id
                hubId
                creatorId
                content
                createdAt
                archived
                feedRegion
                regions
                updatedAt
                __typename
              }
              comments {
                nextToken
                __typename
              }
              reported {
                nextToken
                __typename
              }
              likes {
                nextToken
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          reported {
            items {
              id
              postId
              userId
              commentId
              jobId
              creatorId
              createdAt
              message
              updatedAt
              creator {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          likes {
            items {
              id
              commentId
              profileId
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      reported {
        items {
          id
          postId
          userId
          commentId
          jobId
          creatorId
          createdAt
          message
          updatedAt
          creator {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      likes {
        items {
          id
          postId
          profileId
          createdAt
          updatedAt
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    total
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchPostsQueryVariables,
  APITypes.SearchPostsQuery
>;
export const getJob = /* GraphQL */ `query GetJob($id: ID!) {
  getJob(id: $id) {
    id
    owner
    jobType
    businessId
    jobRole
    title
    description
    salary {
      type
      from
      to
      __typename
    }
    region
    requireCV
    requireCoverLetter
    createdAt
    archived
    startDate
    endDate
    updatedAt
    business {
      id
      verified
      name
      tipping {
        merchantId
        policy
        type
        active
        __typename
      }
      marketing {
        customerId
        subscriptionId
        active
        expirationDate
        sequenceId
        cancelled
        cancelledAt
        __typename
      }
      createdAt
      updatedAt
      owner
      code
      answers {
        questionId
        value
        __typename
      }
      isOutlet
      details {
        id
        contactName
        jobRole
        phone
        email
        website
        location {
          searchName
          address
          address2
          city
          region
          postcode
          country
          latitude
          longitude
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      ownerProfile {
        id
        slug
        firstName
        lastName
        bio
        picture
        location {
          searchName
          address
          address2
          city
          region
          postcode
          country
          latitude
          longitude
          __typename
        }
        createdAt
        updatedAt
        business {
          id
          verified
          name
          tipping {
            merchantId
            policy
            type
            active
            __typename
          }
          marketing {
            customerId
            subscriptionId
            active
            expirationDate
            sequenceId
            cancelled
            cancelledAt
            __typename
          }
          createdAt
          updatedAt
          owner
          code
          answers {
            questionId
            value
            __typename
          }
          isOutlet
          details {
            id
            contactName
            jobRole
            phone
            email
            website
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          ownerProfile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          invites {
            items {
              id
              businessId
              receiver
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          employees {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          brands {
            items {
              id
              businessId
              brandId
              status
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profileId
              brand {
                id
                name
                logo
                active
                productType
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        archived
        details {
          id
          accountType
          isOnboardingComplete
          email
          feedRegion
          tippingAccount {
            code
            merchant
            accountId
            verified
            missingRequirements {
              key
              description
              __typename
            }
            pendingValidation
            bankDetails {
              name
              sortCode
              last4Digits
              __typename
            }
            __typename
          }
          phone_number
          phoneNumberConfirmed
          pushNotificationToken
          pushNotificationReadDate
          createdAt
          updatedAt
          __typename
        }
        employers {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        experiences {
          items {
            id
            jobTitle
            businessId
            companyName
            profileId
            startDate
            endDate
            employmentType
            location
            description
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        jobs {
          items {
            id
            profileId
            jobId
            coverLetter
            saved
            savedDate
            applied
            appliedDate
            createdAt
            updatedAt
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            job {
              id
              owner
              jobType
              businessId
              jobRole
              title
              description
              salary {
                type
                from
                to
                __typename
              }
              region
              requireCV
              requireCoverLetter
              createdAt
              archived
              startDate
              endDate
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              reported {
                nextToken
                __typename
              }
              __typename
            }
            rejected
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      invites {
        items {
          id
          businessId
          receiver
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      employees {
        items {
          id
          jobRole
          businessId
          profileId
          employeeId
          tippingPolicyAcknowledgedDate
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          confirmed
          __typename
        }
        nextToken
        __typename
      }
      brands {
        items {
          id
          businessId
          brandId
          status
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profileId
          brand {
            id
            name
            logo
            active
            productType
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    reported {
      items {
        id
        postId
        userId
        commentId
        jobId
        creatorId
        createdAt
        message
        updatedAt
        creator {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<APITypes.GetJobQueryVariables, APITypes.GetJobQuery>;
export const listJobs = /* GraphQL */ `query ListJobs($filter: ModelJobFilterInput, $limit: Int, $nextToken: String) {
  listJobs(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      owner
      jobType
      businessId
      jobRole
      title
      description
      salary {
        type
        from
        to
        __typename
      }
      region
      requireCV
      requireCoverLetter
      createdAt
      archived
      startDate
      endDate
      updatedAt
      business {
        id
        verified
        name
        tipping {
          merchantId
          policy
          type
          active
          __typename
        }
        marketing {
          customerId
          subscriptionId
          active
          expirationDate
          sequenceId
          cancelled
          cancelledAt
          __typename
        }
        createdAt
        updatedAt
        owner
        code
        answers {
          questionId
          value
          __typename
        }
        isOutlet
        details {
          id
          contactName
          jobRole
          phone
          email
          website
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        ownerProfile {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        invites {
          items {
            id
            businessId
            receiver
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        employees {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        brands {
          items {
            id
            businessId
            brandId
            status
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profileId
            brand {
              id
              name
              logo
              active
              productType
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      reported {
        items {
          id
          postId
          userId
          commentId
          jobId
          creatorId
          createdAt
          message
          updatedAt
          creator {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListJobsQueryVariables, APITypes.ListJobsQuery>;
export const searchJobs = /* GraphQL */ `query SearchJobs(
  $filter: SearchableJobFilterInput
  $sort: SearchableJobSortInput
  $limit: Int
  $nextToken: String
  $from: Int
) {
  searchJobs(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
  ) {
    items {
      id
      owner
      jobType
      businessId
      jobRole
      title
      description
      salary {
        type
        from
        to
        __typename
      }
      region
      requireCV
      requireCoverLetter
      createdAt
      archived
      startDate
      endDate
      updatedAt
      business {
        id
        verified
        name
        tipping {
          merchantId
          policy
          type
          active
          __typename
        }
        marketing {
          customerId
          subscriptionId
          active
          expirationDate
          sequenceId
          cancelled
          cancelledAt
          __typename
        }
        createdAt
        updatedAt
        owner
        code
        answers {
          questionId
          value
          __typename
        }
        isOutlet
        details {
          id
          contactName
          jobRole
          phone
          email
          website
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        ownerProfile {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        invites {
          items {
            id
            businessId
            receiver
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        employees {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        brands {
          items {
            id
            businessId
            brandId
            status
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profileId
            brand {
              id
              name
              logo
              active
              productType
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      reported {
        items {
          id
          postId
          userId
          commentId
          jobId
          creatorId
          createdAt
          message
          updatedAt
          creator {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    total
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchJobsQueryVariables,
  APITypes.SearchJobsQuery
>;
export const profileJobsByProfile = /* GraphQL */ `query ProfileJobsByProfile(
  $profileId: ID
  $jobId: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelProfileJobFilterInput
  $limit: Int
  $nextToken: String
) {
  profileJobsByProfile(
    profileId: $profileId
    jobId: $jobId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      profileId
      jobId
      coverLetter
      saved
      savedDate
      applied
      appliedDate
      createdAt
      updatedAt
      profile {
        id
        slug
        firstName
        lastName
        bio
        picture
        location {
          searchName
          address
          address2
          city
          region
          postcode
          country
          latitude
          longitude
          __typename
        }
        createdAt
        updatedAt
        business {
          id
          verified
          name
          tipping {
            merchantId
            policy
            type
            active
            __typename
          }
          marketing {
            customerId
            subscriptionId
            active
            expirationDate
            sequenceId
            cancelled
            cancelledAt
            __typename
          }
          createdAt
          updatedAt
          owner
          code
          answers {
            questionId
            value
            __typename
          }
          isOutlet
          details {
            id
            contactName
            jobRole
            phone
            email
            website
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          ownerProfile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          invites {
            items {
              id
              businessId
              receiver
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          employees {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          brands {
            items {
              id
              businessId
              brandId
              status
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profileId
              brand {
                id
                name
                logo
                active
                productType
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        archived
        details {
          id
          accountType
          isOnboardingComplete
          email
          feedRegion
          tippingAccount {
            code
            merchant
            accountId
            verified
            missingRequirements {
              key
              description
              __typename
            }
            pendingValidation
            bankDetails {
              name
              sortCode
              last4Digits
              __typename
            }
            __typename
          }
          phone_number
          phoneNumberConfirmed
          pushNotificationToken
          pushNotificationReadDate
          createdAt
          updatedAt
          __typename
        }
        employers {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        experiences {
          items {
            id
            jobTitle
            businessId
            companyName
            profileId
            startDate
            endDate
            employmentType
            location
            description
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        jobs {
          items {
            id
            profileId
            jobId
            coverLetter
            saved
            savedDate
            applied
            appliedDate
            createdAt
            updatedAt
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            job {
              id
              owner
              jobType
              businessId
              jobRole
              title
              description
              salary {
                type
                from
                to
                __typename
              }
              region
              requireCV
              requireCoverLetter
              createdAt
              archived
              startDate
              endDate
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              reported {
                nextToken
                __typename
              }
              __typename
            }
            rejected
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      job {
        id
        owner
        jobType
        businessId
        jobRole
        title
        description
        salary {
          type
          from
          to
          __typename
        }
        region
        requireCV
        requireCoverLetter
        createdAt
        archived
        startDate
        endDate
        updatedAt
        business {
          id
          verified
          name
          tipping {
            merchantId
            policy
            type
            active
            __typename
          }
          marketing {
            customerId
            subscriptionId
            active
            expirationDate
            sequenceId
            cancelled
            cancelledAt
            __typename
          }
          createdAt
          updatedAt
          owner
          code
          answers {
            questionId
            value
            __typename
          }
          isOutlet
          details {
            id
            contactName
            jobRole
            phone
            email
            website
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          ownerProfile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          invites {
            items {
              id
              businessId
              receiver
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          employees {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          brands {
            items {
              id
              businessId
              brandId
              status
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profileId
              brand {
                id
                name
                logo
                active
                productType
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        reported {
          items {
            id
            postId
            userId
            commentId
            jobId
            creatorId
            createdAt
            message
            updatedAt
            creator {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      rejected
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ProfileJobsByProfileQueryVariables,
  APITypes.ProfileJobsByProfileQuery
>;
export const profileJobsByJob = /* GraphQL */ `query ProfileJobsByJob(
  $jobId: ID
  $profileId: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelProfileJobFilterInput
  $limit: Int
  $nextToken: String
) {
  profileJobsByJob(
    jobId: $jobId
    profileId: $profileId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      profileId
      jobId
      coverLetter
      saved
      savedDate
      applied
      appliedDate
      createdAt
      updatedAt
      profile {
        id
        slug
        firstName
        lastName
        bio
        picture
        location {
          searchName
          address
          address2
          city
          region
          postcode
          country
          latitude
          longitude
          __typename
        }
        createdAt
        updatedAt
        business {
          id
          verified
          name
          tipping {
            merchantId
            policy
            type
            active
            __typename
          }
          marketing {
            customerId
            subscriptionId
            active
            expirationDate
            sequenceId
            cancelled
            cancelledAt
            __typename
          }
          createdAt
          updatedAt
          owner
          code
          answers {
            questionId
            value
            __typename
          }
          isOutlet
          details {
            id
            contactName
            jobRole
            phone
            email
            website
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          ownerProfile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          invites {
            items {
              id
              businessId
              receiver
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          employees {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          brands {
            items {
              id
              businessId
              brandId
              status
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profileId
              brand {
                id
                name
                logo
                active
                productType
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        archived
        details {
          id
          accountType
          isOnboardingComplete
          email
          feedRegion
          tippingAccount {
            code
            merchant
            accountId
            verified
            missingRequirements {
              key
              description
              __typename
            }
            pendingValidation
            bankDetails {
              name
              sortCode
              last4Digits
              __typename
            }
            __typename
          }
          phone_number
          phoneNumberConfirmed
          pushNotificationToken
          pushNotificationReadDate
          createdAt
          updatedAt
          __typename
        }
        employers {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        experiences {
          items {
            id
            jobTitle
            businessId
            companyName
            profileId
            startDate
            endDate
            employmentType
            location
            description
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        jobs {
          items {
            id
            profileId
            jobId
            coverLetter
            saved
            savedDate
            applied
            appliedDate
            createdAt
            updatedAt
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            job {
              id
              owner
              jobType
              businessId
              jobRole
              title
              description
              salary {
                type
                from
                to
                __typename
              }
              region
              requireCV
              requireCoverLetter
              createdAt
              archived
              startDate
              endDate
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              reported {
                nextToken
                __typename
              }
              __typename
            }
            rejected
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      job {
        id
        owner
        jobType
        businessId
        jobRole
        title
        description
        salary {
          type
          from
          to
          __typename
        }
        region
        requireCV
        requireCoverLetter
        createdAt
        archived
        startDate
        endDate
        updatedAt
        business {
          id
          verified
          name
          tipping {
            merchantId
            policy
            type
            active
            __typename
          }
          marketing {
            customerId
            subscriptionId
            active
            expirationDate
            sequenceId
            cancelled
            cancelledAt
            __typename
          }
          createdAt
          updatedAt
          owner
          code
          answers {
            questionId
            value
            __typename
          }
          isOutlet
          details {
            id
            contactName
            jobRole
            phone
            email
            website
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          ownerProfile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          invites {
            items {
              id
              businessId
              receiver
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          employees {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          brands {
            items {
              id
              businessId
              brandId
              status
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profileId
              brand {
                id
                name
                logo
                active
                productType
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        reported {
          items {
            id
            postId
            userId
            commentId
            jobId
            creatorId
            createdAt
            message
            updatedAt
            creator {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      rejected
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ProfileJobsByJobQueryVariables,
  APITypes.ProfileJobsByJobQuery
>;
export const searchProfileJobs = /* GraphQL */ `query SearchProfileJobs(
  $filter: SearchableProfileJobFilterInput
  $sort: SearchableProfileJobSortInput
  $limit: Int
  $nextToken: String
  $from: Int
) {
  searchProfileJobs(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
  ) {
    items {
      id
      profileId
      jobId
      coverLetter
      saved
      savedDate
      applied
      appliedDate
      createdAt
      updatedAt
      profile {
        id
        slug
        firstName
        lastName
        bio
        picture
        location {
          searchName
          address
          address2
          city
          region
          postcode
          country
          latitude
          longitude
          __typename
        }
        createdAt
        updatedAt
        business {
          id
          verified
          name
          tipping {
            merchantId
            policy
            type
            active
            __typename
          }
          marketing {
            customerId
            subscriptionId
            active
            expirationDate
            sequenceId
            cancelled
            cancelledAt
            __typename
          }
          createdAt
          updatedAt
          owner
          code
          answers {
            questionId
            value
            __typename
          }
          isOutlet
          details {
            id
            contactName
            jobRole
            phone
            email
            website
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          ownerProfile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          invites {
            items {
              id
              businessId
              receiver
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          employees {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          brands {
            items {
              id
              businessId
              brandId
              status
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profileId
              brand {
                id
                name
                logo
                active
                productType
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        archived
        details {
          id
          accountType
          isOnboardingComplete
          email
          feedRegion
          tippingAccount {
            code
            merchant
            accountId
            verified
            missingRequirements {
              key
              description
              __typename
            }
            pendingValidation
            bankDetails {
              name
              sortCode
              last4Digits
              __typename
            }
            __typename
          }
          phone_number
          phoneNumberConfirmed
          pushNotificationToken
          pushNotificationReadDate
          createdAt
          updatedAt
          __typename
        }
        employers {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        experiences {
          items {
            id
            jobTitle
            businessId
            companyName
            profileId
            startDate
            endDate
            employmentType
            location
            description
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        jobs {
          items {
            id
            profileId
            jobId
            coverLetter
            saved
            savedDate
            applied
            appliedDate
            createdAt
            updatedAt
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            job {
              id
              owner
              jobType
              businessId
              jobRole
              title
              description
              salary {
                type
                from
                to
                __typename
              }
              region
              requireCV
              requireCoverLetter
              createdAt
              archived
              startDate
              endDate
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              reported {
                nextToken
                __typename
              }
              __typename
            }
            rejected
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      job {
        id
        owner
        jobType
        businessId
        jobRole
        title
        description
        salary {
          type
          from
          to
          __typename
        }
        region
        requireCV
        requireCoverLetter
        createdAt
        archived
        startDate
        endDate
        updatedAt
        business {
          id
          verified
          name
          tipping {
            merchantId
            policy
            type
            active
            __typename
          }
          marketing {
            customerId
            subscriptionId
            active
            expirationDate
            sequenceId
            cancelled
            cancelledAt
            __typename
          }
          createdAt
          updatedAt
          owner
          code
          answers {
            questionId
            value
            __typename
          }
          isOutlet
          details {
            id
            contactName
            jobRole
            phone
            email
            website
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          ownerProfile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          invites {
            items {
              id
              businessId
              receiver
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          employees {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          brands {
            items {
              id
              businessId
              brandId
              status
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profileId
              brand {
                id
                name
                logo
                active
                productType
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        reported {
          items {
            id
            postId
            userId
            commentId
            jobId
            creatorId
            createdAt
            message
            updatedAt
            creator {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      rejected
      __typename
    }
    nextToken
    total
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchProfileJobsQueryVariables,
  APITypes.SearchProfileJobsQuery
>;
export const getProfileJob = /* GraphQL */ `query GetProfileJob($id: ID!) {
  getProfileJob(id: $id) {
    id
    profileId
    jobId
    coverLetter
    saved
    savedDate
    applied
    appliedDate
    createdAt
    updatedAt
    profile {
      id
      slug
      firstName
      lastName
      bio
      picture
      location {
        searchName
        address
        address2
        city
        region
        postcode
        country
        latitude
        longitude
        __typename
      }
      createdAt
      updatedAt
      business {
        id
        verified
        name
        tipping {
          merchantId
          policy
          type
          active
          __typename
        }
        marketing {
          customerId
          subscriptionId
          active
          expirationDate
          sequenceId
          cancelled
          cancelledAt
          __typename
        }
        createdAt
        updatedAt
        owner
        code
        answers {
          questionId
          value
          __typename
        }
        isOutlet
        details {
          id
          contactName
          jobRole
          phone
          email
          website
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        ownerProfile {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        invites {
          items {
            id
            businessId
            receiver
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        employees {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        brands {
          items {
            id
            businessId
            brandId
            status
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profileId
            brand {
              id
              name
              logo
              active
              productType
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      archived
      details {
        id
        accountType
        isOnboardingComplete
        email
        feedRegion
        tippingAccount {
          code
          merchant
          accountId
          verified
          missingRequirements {
            key
            description
            __typename
          }
          pendingValidation
          bankDetails {
            name
            sortCode
            last4Digits
            __typename
          }
          __typename
        }
        phone_number
        phoneNumberConfirmed
        pushNotificationToken
        pushNotificationReadDate
        createdAt
        updatedAt
        __typename
      }
      employers {
        items {
          id
          jobRole
          businessId
          profileId
          employeeId
          tippingPolicyAcknowledgedDate
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          confirmed
          __typename
        }
        nextToken
        __typename
      }
      experiences {
        items {
          id
          jobTitle
          businessId
          companyName
          profileId
          startDate
          endDate
          employmentType
          location
          description
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      jobs {
        items {
          id
          profileId
          jobId
          coverLetter
          saved
          savedDate
          applied
          appliedDate
          createdAt
          updatedAt
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          job {
            id
            owner
            jobType
            businessId
            jobRole
            title
            description
            salary {
              type
              from
              to
              __typename
            }
            region
            requireCV
            requireCoverLetter
            createdAt
            archived
            startDate
            endDate
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            reported {
              items {
                id
                postId
                userId
                commentId
                jobId
                creatorId
                createdAt
                message
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          rejected
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    job {
      id
      owner
      jobType
      businessId
      jobRole
      title
      description
      salary {
        type
        from
        to
        __typename
      }
      region
      requireCV
      requireCoverLetter
      createdAt
      archived
      startDate
      endDate
      updatedAt
      business {
        id
        verified
        name
        tipping {
          merchantId
          policy
          type
          active
          __typename
        }
        marketing {
          customerId
          subscriptionId
          active
          expirationDate
          sequenceId
          cancelled
          cancelledAt
          __typename
        }
        createdAt
        updatedAt
        owner
        code
        answers {
          questionId
          value
          __typename
        }
        isOutlet
        details {
          id
          contactName
          jobRole
          phone
          email
          website
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        ownerProfile {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        invites {
          items {
            id
            businessId
            receiver
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        employees {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        brands {
          items {
            id
            businessId
            brandId
            status
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profileId
            brand {
              id
              name
              logo
              active
              productType
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      reported {
        items {
          id
          postId
          userId
          commentId
          jobId
          creatorId
          createdAt
          message
          updatedAt
          creator {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    rejected
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetProfileJobQueryVariables,
  APITypes.GetProfileJobQuery
>;
export const listProfileJobs = /* GraphQL */ `query ListProfileJobs(
  $filter: ModelProfileJobFilterInput
  $limit: Int
  $nextToken: String
) {
  listProfileJobs(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      profileId
      jobId
      coverLetter
      saved
      savedDate
      applied
      appliedDate
      createdAt
      updatedAt
      profile {
        id
        slug
        firstName
        lastName
        bio
        picture
        location {
          searchName
          address
          address2
          city
          region
          postcode
          country
          latitude
          longitude
          __typename
        }
        createdAt
        updatedAt
        business {
          id
          verified
          name
          tipping {
            merchantId
            policy
            type
            active
            __typename
          }
          marketing {
            customerId
            subscriptionId
            active
            expirationDate
            sequenceId
            cancelled
            cancelledAt
            __typename
          }
          createdAt
          updatedAt
          owner
          code
          answers {
            questionId
            value
            __typename
          }
          isOutlet
          details {
            id
            contactName
            jobRole
            phone
            email
            website
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          ownerProfile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          invites {
            items {
              id
              businessId
              receiver
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          employees {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          brands {
            items {
              id
              businessId
              brandId
              status
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profileId
              brand {
                id
                name
                logo
                active
                productType
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        archived
        details {
          id
          accountType
          isOnboardingComplete
          email
          feedRegion
          tippingAccount {
            code
            merchant
            accountId
            verified
            missingRequirements {
              key
              description
              __typename
            }
            pendingValidation
            bankDetails {
              name
              sortCode
              last4Digits
              __typename
            }
            __typename
          }
          phone_number
          phoneNumberConfirmed
          pushNotificationToken
          pushNotificationReadDate
          createdAt
          updatedAt
          __typename
        }
        employers {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        experiences {
          items {
            id
            jobTitle
            businessId
            companyName
            profileId
            startDate
            endDate
            employmentType
            location
            description
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        jobs {
          items {
            id
            profileId
            jobId
            coverLetter
            saved
            savedDate
            applied
            appliedDate
            createdAt
            updatedAt
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            job {
              id
              owner
              jobType
              businessId
              jobRole
              title
              description
              salary {
                type
                from
                to
                __typename
              }
              region
              requireCV
              requireCoverLetter
              createdAt
              archived
              startDate
              endDate
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              reported {
                nextToken
                __typename
              }
              __typename
            }
            rejected
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      job {
        id
        owner
        jobType
        businessId
        jobRole
        title
        description
        salary {
          type
          from
          to
          __typename
        }
        region
        requireCV
        requireCoverLetter
        createdAt
        archived
        startDate
        endDate
        updatedAt
        business {
          id
          verified
          name
          tipping {
            merchantId
            policy
            type
            active
            __typename
          }
          marketing {
            customerId
            subscriptionId
            active
            expirationDate
            sequenceId
            cancelled
            cancelledAt
            __typename
          }
          createdAt
          updatedAt
          owner
          code
          answers {
            questionId
            value
            __typename
          }
          isOutlet
          details {
            id
            contactName
            jobRole
            phone
            email
            website
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          ownerProfile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          invites {
            items {
              id
              businessId
              receiver
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          employees {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          brands {
            items {
              id
              businessId
              brandId
              status
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profileId
              brand {
                id
                name
                logo
                active
                productType
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        reported {
          items {
            id
            postId
            userId
            commentId
            jobId
            creatorId
            createdAt
            message
            updatedAt
            creator {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      rejected
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListProfileJobsQueryVariables,
  APITypes.ListProfileJobsQuery
>;
export const getComment = /* GraphQL */ `query GetComment($id: ID!) {
  getComment(id: $id) {
    id
    postId
    commentId
    content
    creatorId
    createdAt
    archived
    attachments {
      mediaType
      source
      alt
      __typename
    }
    updatedAt
    creator {
      id
      slug
      firstName
      lastName
      bio
      picture
      location {
        searchName
        address
        address2
        city
        region
        postcode
        country
        latitude
        longitude
        __typename
      }
      createdAt
      updatedAt
      business {
        id
        verified
        name
        tipping {
          merchantId
          policy
          type
          active
          __typename
        }
        marketing {
          customerId
          subscriptionId
          active
          expirationDate
          sequenceId
          cancelled
          cancelledAt
          __typename
        }
        createdAt
        updatedAt
        owner
        code
        answers {
          questionId
          value
          __typename
        }
        isOutlet
        details {
          id
          contactName
          jobRole
          phone
          email
          website
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        ownerProfile {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        invites {
          items {
            id
            businessId
            receiver
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        employees {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        brands {
          items {
            id
            businessId
            brandId
            status
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profileId
            brand {
              id
              name
              logo
              active
              productType
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      archived
      details {
        id
        accountType
        isOnboardingComplete
        email
        feedRegion
        tippingAccount {
          code
          merchant
          accountId
          verified
          missingRequirements {
            key
            description
            __typename
          }
          pendingValidation
          bankDetails {
            name
            sortCode
            last4Digits
            __typename
          }
          __typename
        }
        phone_number
        phoneNumberConfirmed
        pushNotificationToken
        pushNotificationReadDate
        createdAt
        updatedAt
        __typename
      }
      employers {
        items {
          id
          jobRole
          businessId
          profileId
          employeeId
          tippingPolicyAcknowledgedDate
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          confirmed
          __typename
        }
        nextToken
        __typename
      }
      experiences {
        items {
          id
          jobTitle
          businessId
          companyName
          profileId
          startDate
          endDate
          employmentType
          location
          description
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      jobs {
        items {
          id
          profileId
          jobId
          coverLetter
          saved
          savedDate
          applied
          appliedDate
          createdAt
          updatedAt
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          job {
            id
            owner
            jobType
            businessId
            jobRole
            title
            description
            salary {
              type
              from
              to
              __typename
            }
            region
            requireCV
            requireCoverLetter
            createdAt
            archived
            startDate
            endDate
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            reported {
              items {
                id
                postId
                userId
                commentId
                jobId
                creatorId
                createdAt
                message
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          rejected
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    post {
      id
      hubId
      creatorId
      content
      createdAt
      archived
      feedRegion
      regions
      attachments {
        mediaType
        source
        alt
        __typename
      }
      updatedAt
      creator {
        id
        slug
        firstName
        lastName
        bio
        picture
        location {
          searchName
          address
          address2
          city
          region
          postcode
          country
          latitude
          longitude
          __typename
        }
        createdAt
        updatedAt
        business {
          id
          verified
          name
          tipping {
            merchantId
            policy
            type
            active
            __typename
          }
          marketing {
            customerId
            subscriptionId
            active
            expirationDate
            sequenceId
            cancelled
            cancelledAt
            __typename
          }
          createdAt
          updatedAt
          owner
          code
          answers {
            questionId
            value
            __typename
          }
          isOutlet
          details {
            id
            contactName
            jobRole
            phone
            email
            website
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          ownerProfile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          invites {
            items {
              id
              businessId
              receiver
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          employees {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          brands {
            items {
              id
              businessId
              brandId
              status
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profileId
              brand {
                id
                name
                logo
                active
                productType
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        archived
        details {
          id
          accountType
          isOnboardingComplete
          email
          feedRegion
          tippingAccount {
            code
            merchant
            accountId
            verified
            missingRequirements {
              key
              description
              __typename
            }
            pendingValidation
            bankDetails {
              name
              sortCode
              last4Digits
              __typename
            }
            __typename
          }
          phone_number
          phoneNumberConfirmed
          pushNotificationToken
          pushNotificationReadDate
          createdAt
          updatedAt
          __typename
        }
        employers {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        experiences {
          items {
            id
            jobTitle
            businessId
            companyName
            profileId
            startDate
            endDate
            employmentType
            location
            description
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        jobs {
          items {
            id
            profileId
            jobId
            coverLetter
            saved
            savedDate
            applied
            appliedDate
            createdAt
            updatedAt
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            job {
              id
              owner
              jobType
              businessId
              jobRole
              title
              description
              salary {
                type
                from
                to
                __typename
              }
              region
              requireCV
              requireCoverLetter
              createdAt
              archived
              startDate
              endDate
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              reported {
                nextToken
                __typename
              }
              __typename
            }
            rejected
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      comments {
        items {
          id
          postId
          commentId
          content
          creatorId
          createdAt
          archived
          attachments {
            mediaType
            source
            alt
            __typename
          }
          updatedAt
          creator {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          post {
            id
            hubId
            creatorId
            content
            createdAt
            archived
            feedRegion
            regions
            attachments {
              mediaType
              source
              alt
              __typename
            }
            updatedAt
            creator {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            comments {
              items {
                id
                postId
                commentId
                content
                creatorId
                createdAt
                archived
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            reported {
              items {
                id
                postId
                userId
                commentId
                jobId
                creatorId
                createdAt
                message
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            likes {
              items {
                id
                postId
                profileId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          comments {
            items {
              id
              postId
              commentId
              content
              creatorId
              createdAt
              archived
              attachments {
                mediaType
                source
                alt
                __typename
              }
              updatedAt
              creator {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              post {
                id
                hubId
                creatorId
                content
                createdAt
                archived
                feedRegion
                regions
                updatedAt
                __typename
              }
              comments {
                nextToken
                __typename
              }
              reported {
                nextToken
                __typename
              }
              likes {
                nextToken
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          reported {
            items {
              id
              postId
              userId
              commentId
              jobId
              creatorId
              createdAt
              message
              updatedAt
              creator {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          likes {
            items {
              id
              commentId
              profileId
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      reported {
        items {
          id
          postId
          userId
          commentId
          jobId
          creatorId
          createdAt
          message
          updatedAt
          creator {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      likes {
        items {
          id
          postId
          profileId
          createdAt
          updatedAt
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    comments {
      items {
        id
        postId
        commentId
        content
        creatorId
        createdAt
        archived
        attachments {
          mediaType
          source
          alt
          __typename
        }
        updatedAt
        creator {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        post {
          id
          hubId
          creatorId
          content
          createdAt
          archived
          feedRegion
          regions
          attachments {
            mediaType
            source
            alt
            __typename
          }
          updatedAt
          creator {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          comments {
            items {
              id
              postId
              commentId
              content
              creatorId
              createdAt
              archived
              attachments {
                mediaType
                source
                alt
                __typename
              }
              updatedAt
              creator {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              post {
                id
                hubId
                creatorId
                content
                createdAt
                archived
                feedRegion
                regions
                updatedAt
                __typename
              }
              comments {
                nextToken
                __typename
              }
              reported {
                nextToken
                __typename
              }
              likes {
                nextToken
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          reported {
            items {
              id
              postId
              userId
              commentId
              jobId
              creatorId
              createdAt
              message
              updatedAt
              creator {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          likes {
            items {
              id
              postId
              profileId
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        comments {
          items {
            id
            postId
            commentId
            content
            creatorId
            createdAt
            archived
            attachments {
              mediaType
              source
              alt
              __typename
            }
            updatedAt
            creator {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            post {
              id
              hubId
              creatorId
              content
              createdAt
              archived
              feedRegion
              regions
              attachments {
                mediaType
                source
                alt
                __typename
              }
              updatedAt
              creator {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              comments {
                nextToken
                __typename
              }
              reported {
                nextToken
                __typename
              }
              likes {
                nextToken
                __typename
              }
              __typename
            }
            comments {
              items {
                id
                postId
                commentId
                content
                creatorId
                createdAt
                archived
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            reported {
              items {
                id
                postId
                userId
                commentId
                jobId
                creatorId
                createdAt
                message
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            likes {
              items {
                id
                commentId
                profileId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        reported {
          items {
            id
            postId
            userId
            commentId
            jobId
            creatorId
            createdAt
            message
            updatedAt
            creator {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        likes {
          items {
            id
            commentId
            profileId
            createdAt
            updatedAt
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    reported {
      items {
        id
        postId
        userId
        commentId
        jobId
        creatorId
        createdAt
        message
        updatedAt
        creator {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    likes {
      items {
        id
        verified
        name
        tipping {
          merchantId
          policy
          type
          active
          __typename
        }
        marketing {
          customerId
          subscriptionId
          active
          expirationDate
          sequenceId
          cancelled
          cancelledAt
          __typename
        }
        createdAt
        updatedAt
        owner
        code
        answers {
          questionId
          value
          __typename
        }
        isOutlet
        details {
          id
          contactName
          jobRole
          phone
          email
          website
          createdAt
          updatedAt
          __typename
        }
        ownerProfile {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCommentQueryVariables,
  APITypes.GetCommentQuery
>;
export const listComments = /* GraphQL */ `query ListComments(
  $filter: ModelCommentFilterInput
  $limit: Int
  $nextToken: String
) {
  listComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      postId
      commentId
      content
      creatorId
      createdAt
      archived
      attachments {
        mediaType
        source
        alt
        __typename
      }
      updatedAt
      creator {
        id
        slug
        firstName
        lastName
        bio
        picture
        location {
          searchName
          address
          address2
          city
          region
          postcode
          country
          latitude
          longitude
          __typename
        }
        createdAt
        updatedAt
        business {
          id
          verified
          name
          tipping {
            merchantId
            policy
            type
            active
            __typename
          }
          marketing {
            customerId
            subscriptionId
            active
            expirationDate
            sequenceId
            cancelled
            cancelledAt
            __typename
          }
          createdAt
          updatedAt
          owner
          code
          answers {
            questionId
            value
            __typename
          }
          isOutlet
          details {
            id
            contactName
            jobRole
            phone
            email
            website
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          ownerProfile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          invites {
            items {
              id
              businessId
              receiver
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          employees {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          brands {
            items {
              id
              businessId
              brandId
              status
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profileId
              brand {
                id
                name
                logo
                active
                productType
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        archived
        details {
          id
          accountType
          isOnboardingComplete
          email
          feedRegion
          tippingAccount {
            code
            merchant
            accountId
            verified
            missingRequirements {
              key
              description
              __typename
            }
            pendingValidation
            bankDetails {
              name
              sortCode
              last4Digits
              __typename
            }
            __typename
          }
          phone_number
          phoneNumberConfirmed
          pushNotificationToken
          pushNotificationReadDate
          createdAt
          updatedAt
          __typename
        }
        employers {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        experiences {
          items {
            id
            jobTitle
            businessId
            companyName
            profileId
            startDate
            endDate
            employmentType
            location
            description
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        jobs {
          items {
            id
            profileId
            jobId
            coverLetter
            saved
            savedDate
            applied
            appliedDate
            createdAt
            updatedAt
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            job {
              id
              owner
              jobType
              businessId
              jobRole
              title
              description
              salary {
                type
                from
                to
                __typename
              }
              region
              requireCV
              requireCoverLetter
              createdAt
              archived
              startDate
              endDate
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              reported {
                nextToken
                __typename
              }
              __typename
            }
            rejected
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      post {
        id
        hubId
        creatorId
        content
        createdAt
        archived
        feedRegion
        regions
        attachments {
          mediaType
          source
          alt
          __typename
        }
        updatedAt
        creator {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        comments {
          items {
            id
            postId
            commentId
            content
            creatorId
            createdAt
            archived
            attachments {
              mediaType
              source
              alt
              __typename
            }
            updatedAt
            creator {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            post {
              id
              hubId
              creatorId
              content
              createdAt
              archived
              feedRegion
              regions
              attachments {
                mediaType
                source
                alt
                __typename
              }
              updatedAt
              creator {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              comments {
                nextToken
                __typename
              }
              reported {
                nextToken
                __typename
              }
              likes {
                nextToken
                __typename
              }
              __typename
            }
            comments {
              items {
                id
                postId
                commentId
                content
                creatorId
                createdAt
                archived
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            reported {
              items {
                id
                postId
                userId
                commentId
                jobId
                creatorId
                createdAt
                message
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            likes {
              items {
                id
                commentId
                profileId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        reported {
          items {
            id
            postId
            userId
            commentId
            jobId
            creatorId
            createdAt
            message
            updatedAt
            creator {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        likes {
          items {
            id
            postId
            profileId
            createdAt
            updatedAt
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      comments {
        items {
          id
          postId
          commentId
          content
          creatorId
          createdAt
          archived
          attachments {
            mediaType
            source
            alt
            __typename
          }
          updatedAt
          creator {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          post {
            id
            hubId
            creatorId
            content
            createdAt
            archived
            feedRegion
            regions
            attachments {
              mediaType
              source
              alt
              __typename
            }
            updatedAt
            creator {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            comments {
              items {
                id
                postId
                commentId
                content
                creatorId
                createdAt
                archived
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            reported {
              items {
                id
                postId
                userId
                commentId
                jobId
                creatorId
                createdAt
                message
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            likes {
              items {
                id
                postId
                profileId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          comments {
            items {
              id
              postId
              commentId
              content
              creatorId
              createdAt
              archived
              attachments {
                mediaType
                source
                alt
                __typename
              }
              updatedAt
              creator {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              post {
                id
                hubId
                creatorId
                content
                createdAt
                archived
                feedRegion
                regions
                updatedAt
                __typename
              }
              comments {
                nextToken
                __typename
              }
              reported {
                nextToken
                __typename
              }
              likes {
                nextToken
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          reported {
            items {
              id
              postId
              userId
              commentId
              jobId
              creatorId
              createdAt
              message
              updatedAt
              creator {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          likes {
            items {
              id
              commentId
              profileId
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      reported {
        items {
          id
          postId
          userId
          commentId
          jobId
          creatorId
          createdAt
          message
          updatedAt
          creator {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      likes {
        items {
          id
          commentId
          profileId
          createdAt
          updatedAt
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCommentsQueryVariables,
  APITypes.ListCommentsQuery
>;
export const commentsByDate = /* GraphQL */ `query CommentsByDate(
  $postId: ID
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelCommentFilterInput
  $limit: Int
  $nextToken: String
) {
  commentsByDate(
    postId: $postId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      postId
      commentId
      content
      creatorId
      createdAt
      archived
      attachments {
        mediaType
        source
        alt
        __typename
      }
      updatedAt
      creator {
        id
        slug
        firstName
        lastName
        bio
        picture
        location {
          searchName
          address
          address2
          city
          region
          postcode
          country
          latitude
          longitude
          __typename
        }
        createdAt
        updatedAt
        business {
          id
          verified
          name
          tipping {
            merchantId
            policy
            type
            active
            __typename
          }
          marketing {
            customerId
            subscriptionId
            active
            expirationDate
            sequenceId
            cancelled
            cancelledAt
            __typename
          }
          createdAt
          updatedAt
          owner
          code
          answers {
            questionId
            value
            __typename
          }
          isOutlet
          details {
            id
            contactName
            jobRole
            phone
            email
            website
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          ownerProfile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          invites {
            items {
              id
              businessId
              receiver
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          employees {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          brands {
            items {
              id
              businessId
              brandId
              status
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profileId
              brand {
                id
                name
                logo
                active
                productType
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        archived
        details {
          id
          accountType
          isOnboardingComplete
          email
          feedRegion
          tippingAccount {
            code
            merchant
            accountId
            verified
            missingRequirements {
              key
              description
              __typename
            }
            pendingValidation
            bankDetails {
              name
              sortCode
              last4Digits
              __typename
            }
            __typename
          }
          phone_number
          phoneNumberConfirmed
          pushNotificationToken
          pushNotificationReadDate
          createdAt
          updatedAt
          __typename
        }
        employers {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        experiences {
          items {
            id
            jobTitle
            businessId
            companyName
            profileId
            startDate
            endDate
            employmentType
            location
            description
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        jobs {
          items {
            id
            profileId
            jobId
            coverLetter
            saved
            savedDate
            applied
            appliedDate
            createdAt
            updatedAt
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            job {
              id
              owner
              jobType
              businessId
              jobRole
              title
              description
              salary {
                type
                from
                to
                __typename
              }
              region
              requireCV
              requireCoverLetter
              createdAt
              archived
              startDate
              endDate
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              reported {
                nextToken
                __typename
              }
              __typename
            }
            rejected
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      post {
        id
        hubId
        creatorId
        content
        createdAt
        archived
        feedRegion
        regions
        attachments {
          mediaType
          source
          alt
          __typename
        }
        updatedAt
        creator {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        comments {
          items {
            id
            postId
            commentId
            content
            creatorId
            createdAt
            archived
            attachments {
              mediaType
              source
              alt
              __typename
            }
            updatedAt
            creator {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            post {
              id
              hubId
              creatorId
              content
              createdAt
              archived
              feedRegion
              regions
              attachments {
                mediaType
                source
                alt
                __typename
              }
              updatedAt
              creator {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              comments {
                nextToken
                __typename
              }
              reported {
                nextToken
                __typename
              }
              likes {
                nextToken
                __typename
              }
              __typename
            }
            comments {
              items {
                id
                postId
                commentId
                content
                creatorId
                createdAt
                archived
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            reported {
              items {
                id
                postId
                userId
                commentId
                jobId
                creatorId
                createdAt
                message
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            likes {
              items {
                id
                commentId
                profileId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        reported {
          items {
            id
            postId
            userId
            commentId
            jobId
            creatorId
            createdAt
            message
            updatedAt
            creator {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        likes {
          items {
            id
            postId
            profileId
            createdAt
            updatedAt
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      comments {
        items {
          id
          postId
          commentId
          content
          creatorId
          createdAt
          archived
          attachments {
            mediaType
            source
            alt
            __typename
          }
          updatedAt
          creator {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          post {
            id
            hubId
            creatorId
            content
            createdAt
            archived
            feedRegion
            regions
            attachments {
              mediaType
              source
              alt
              __typename
            }
            updatedAt
            creator {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            comments {
              items {
                id
                postId
                commentId
                content
                creatorId
                createdAt
                archived
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            reported {
              items {
                id
                postId
                userId
                commentId
                jobId
                creatorId
                createdAt
                message
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            likes {
              items {
                id
                postId
                profileId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          comments {
            items {
              id
              postId
              commentId
              content
              creatorId
              createdAt
              archived
              attachments {
                mediaType
                source
                alt
                __typename
              }
              updatedAt
              creator {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              post {
                id
                hubId
                creatorId
                content
                createdAt
                archived
                feedRegion
                regions
                updatedAt
                __typename
              }
              comments {
                nextToken
                __typename
              }
              reported {
                nextToken
                __typename
              }
              likes {
                nextToken
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          reported {
            items {
              id
              postId
              userId
              commentId
              jobId
              creatorId
              createdAt
              message
              updatedAt
              creator {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          likes {
            items {
              id
              commentId
              profileId
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      reported {
        items {
          id
          postId
          userId
          commentId
          jobId
          creatorId
          createdAt
          message
          updatedAt
          creator {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      likes {
        items {
          id
          commentId
          profileId
          createdAt
          updatedAt
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CommentsByDateQueryVariables,
  APITypes.CommentsByDateQuery
>;
export const getReportInfo = /* GraphQL */ `query GetReportInfo($id: ID!) {
  getReportInfo(id: $id) {
    id
    postId
    userId
    commentId
    jobId
    creatorId
    createdAt
    message
    updatedAt
    creator {
      id
      slug
      firstName
      lastName
      bio
      picture
      location {
        searchName
        address
        address2
        city
        region
        postcode
        country
        latitude
        longitude
        __typename
      }
      createdAt
      updatedAt
      business {
        id
        verified
        name
        tipping {
          merchantId
          policy
          type
          active
          __typename
        }
        marketing {
          customerId
          subscriptionId
          active
          expirationDate
          sequenceId
          cancelled
          cancelledAt
          __typename
        }
        createdAt
        updatedAt
        owner
        code
        answers {
          questionId
          value
          __typename
        }
        isOutlet
        details {
          id
          contactName
          jobRole
          phone
          email
          website
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        ownerProfile {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        invites {
          items {
            id
            businessId
            receiver
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        employees {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        brands {
          items {
            id
            businessId
            brandId
            status
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profileId
            brand {
              id
              name
              logo
              active
              productType
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      archived
      details {
        id
        accountType
        isOnboardingComplete
        email
        feedRegion
        tippingAccount {
          code
          merchant
          accountId
          verified
          missingRequirements {
            key
            description
            __typename
          }
          pendingValidation
          bankDetails {
            name
            sortCode
            last4Digits
            __typename
          }
          __typename
        }
        phone_number
        phoneNumberConfirmed
        pushNotificationToken
        pushNotificationReadDate
        createdAt
        updatedAt
        __typename
      }
      employers {
        items {
          id
          jobRole
          businessId
          profileId
          employeeId
          tippingPolicyAcknowledgedDate
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          confirmed
          __typename
        }
        nextToken
        __typename
      }
      experiences {
        items {
          id
          jobTitle
          businessId
          companyName
          profileId
          startDate
          endDate
          employmentType
          location
          description
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      jobs {
        items {
          id
          profileId
          jobId
          coverLetter
          saved
          savedDate
          applied
          appliedDate
          createdAt
          updatedAt
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          job {
            id
            owner
            jobType
            businessId
            jobRole
            title
            description
            salary {
              type
              from
              to
              __typename
            }
            region
            requireCV
            requireCoverLetter
            createdAt
            archived
            startDate
            endDate
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            reported {
              items {
                id
                postId
                userId
                commentId
                jobId
                creatorId
                createdAt
                message
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          rejected
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetReportInfoQueryVariables,
  APITypes.GetReportInfoQuery
>;
export const listReportInfos = /* GraphQL */ `query ListReportInfos(
  $filter: ModelReportInfoFilterInput
  $limit: Int
  $nextToken: String
) {
  listReportInfos(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      postId
      userId
      commentId
      jobId
      creatorId
      createdAt
      message
      updatedAt
      creator {
        id
        slug
        firstName
        lastName
        bio
        picture
        location {
          searchName
          address
          address2
          city
          region
          postcode
          country
          latitude
          longitude
          __typename
        }
        createdAt
        updatedAt
        business {
          id
          verified
          name
          tipping {
            merchantId
            policy
            type
            active
            __typename
          }
          marketing {
            customerId
            subscriptionId
            active
            expirationDate
            sequenceId
            cancelled
            cancelledAt
            __typename
          }
          createdAt
          updatedAt
          owner
          code
          answers {
            questionId
            value
            __typename
          }
          isOutlet
          details {
            id
            contactName
            jobRole
            phone
            email
            website
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          ownerProfile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          invites {
            items {
              id
              businessId
              receiver
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          employees {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          brands {
            items {
              id
              businessId
              brandId
              status
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profileId
              brand {
                id
                name
                logo
                active
                productType
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        archived
        details {
          id
          accountType
          isOnboardingComplete
          email
          feedRegion
          tippingAccount {
            code
            merchant
            accountId
            verified
            missingRequirements {
              key
              description
              __typename
            }
            pendingValidation
            bankDetails {
              name
              sortCode
              last4Digits
              __typename
            }
            __typename
          }
          phone_number
          phoneNumberConfirmed
          pushNotificationToken
          pushNotificationReadDate
          createdAt
          updatedAt
          __typename
        }
        employers {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        experiences {
          items {
            id
            jobTitle
            businessId
            companyName
            profileId
            startDate
            endDate
            employmentType
            location
            description
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        jobs {
          items {
            id
            profileId
            jobId
            coverLetter
            saved
            savedDate
            applied
            appliedDate
            createdAt
            updatedAt
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            job {
              id
              owner
              jobType
              businessId
              jobRole
              title
              description
              salary {
                type
                from
                to
                __typename
              }
              region
              requireCV
              requireCoverLetter
              createdAt
              archived
              startDate
              endDate
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              reported {
                nextToken
                __typename
              }
              __typename
            }
            rejected
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListReportInfosQueryVariables,
  APITypes.ListReportInfosQuery
>;
export const getPostLike = /* GraphQL */ `query GetPostLike($id: ID!) {
  getPostLike(id: $id) {
    id
    postId
    profileId
    createdAt
    updatedAt
    profile {
      id
      slug
      firstName
      lastName
      bio
      picture
      location {
        searchName
        address
        address2
        city
        region
        postcode
        country
        latitude
        longitude
        __typename
      }
      createdAt
      updatedAt
      business {
        id
        verified
        name
        tipping {
          merchantId
          policy
          type
          active
          __typename
        }
        marketing {
          customerId
          subscriptionId
          active
          expirationDate
          sequenceId
          cancelled
          cancelledAt
          __typename
        }
        createdAt
        updatedAt
        owner
        code
        answers {
          questionId
          value
          __typename
        }
        isOutlet
        details {
          id
          contactName
          jobRole
          phone
          email
          website
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        ownerProfile {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        invites {
          items {
            id
            businessId
            receiver
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        employees {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        brands {
          items {
            id
            businessId
            brandId
            status
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profileId
            brand {
              id
              name
              logo
              active
              productType
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      archived
      details {
        id
        accountType
        isOnboardingComplete
        email
        feedRegion
        tippingAccount {
          code
          merchant
          accountId
          verified
          missingRequirements {
            key
            description
            __typename
          }
          pendingValidation
          bankDetails {
            name
            sortCode
            last4Digits
            __typename
          }
          __typename
        }
        phone_number
        phoneNumberConfirmed
        pushNotificationToken
        pushNotificationReadDate
        createdAt
        updatedAt
        __typename
      }
      employers {
        items {
          id
          jobRole
          businessId
          profileId
          employeeId
          tippingPolicyAcknowledgedDate
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          confirmed
          __typename
        }
        nextToken
        __typename
      }
      experiences {
        items {
          id
          jobTitle
          businessId
          companyName
          profileId
          startDate
          endDate
          employmentType
          location
          description
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      jobs {
        items {
          id
          profileId
          jobId
          coverLetter
          saved
          savedDate
          applied
          appliedDate
          createdAt
          updatedAt
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          job {
            id
            owner
            jobType
            businessId
            jobRole
            title
            description
            salary {
              type
              from
              to
              __typename
            }
            region
            requireCV
            requireCoverLetter
            createdAt
            archived
            startDate
            endDate
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            reported {
              items {
                id
                postId
                userId
                commentId
                jobId
                creatorId
                createdAt
                message
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          rejected
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPostLikeQueryVariables,
  APITypes.GetPostLikeQuery
>;
export const listPostLikes = /* GraphQL */ `query ListPostLikes(
  $filter: ModelPostLikeFilterInput
  $limit: Int
  $nextToken: String
) {
  listPostLikes(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      postId
      profileId
      createdAt
      updatedAt
      profile {
        id
        slug
        firstName
        lastName
        bio
        picture
        location {
          searchName
          address
          address2
          city
          region
          postcode
          country
          latitude
          longitude
          __typename
        }
        createdAt
        updatedAt
        business {
          id
          verified
          name
          tipping {
            merchantId
            policy
            type
            active
            __typename
          }
          marketing {
            customerId
            subscriptionId
            active
            expirationDate
            sequenceId
            cancelled
            cancelledAt
            __typename
          }
          createdAt
          updatedAt
          owner
          code
          answers {
            questionId
            value
            __typename
          }
          isOutlet
          details {
            id
            contactName
            jobRole
            phone
            email
            website
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          ownerProfile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          invites {
            items {
              id
              businessId
              receiver
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          employees {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          brands {
            items {
              id
              businessId
              brandId
              status
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profileId
              brand {
                id
                name
                logo
                active
                productType
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        archived
        details {
          id
          accountType
          isOnboardingComplete
          email
          feedRegion
          tippingAccount {
            code
            merchant
            accountId
            verified
            missingRequirements {
              key
              description
              __typename
            }
            pendingValidation
            bankDetails {
              name
              sortCode
              last4Digits
              __typename
            }
            __typename
          }
          phone_number
          phoneNumberConfirmed
          pushNotificationToken
          pushNotificationReadDate
          createdAt
          updatedAt
          __typename
        }
        employers {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        experiences {
          items {
            id
            jobTitle
            businessId
            companyName
            profileId
            startDate
            endDate
            employmentType
            location
            description
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        jobs {
          items {
            id
            profileId
            jobId
            coverLetter
            saved
            savedDate
            applied
            appliedDate
            createdAt
            updatedAt
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            job {
              id
              owner
              jobType
              businessId
              jobRole
              title
              description
              salary {
                type
                from
                to
                __typename
              }
              region
              requireCV
              requireCoverLetter
              createdAt
              archived
              startDate
              endDate
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              reported {
                nextToken
                __typename
              }
              __typename
            }
            rejected
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPostLikesQueryVariables,
  APITypes.ListPostLikesQuery
>;
export const getCommentLike = /* GraphQL */ `query GetCommentLike($id: ID!) {
  getCommentLike(id: $id) {
    id
    commentId
    profileId
    createdAt
    updatedAt
    profile {
      id
      slug
      firstName
      lastName
      bio
      picture
      location {
        searchName
        address
        address2
        city
        region
        postcode
        country
        latitude
        longitude
        __typename
      }
      createdAt
      updatedAt
      business {
        id
        verified
        name
        tipping {
          merchantId
          policy
          type
          active
          __typename
        }
        marketing {
          customerId
          subscriptionId
          active
          expirationDate
          sequenceId
          cancelled
          cancelledAt
          __typename
        }
        createdAt
        updatedAt
        owner
        code
        answers {
          questionId
          value
          __typename
        }
        isOutlet
        details {
          id
          contactName
          jobRole
          phone
          email
          website
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        ownerProfile {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        invites {
          items {
            id
            businessId
            receiver
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        employees {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        brands {
          items {
            id
            businessId
            brandId
            status
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profileId
            brand {
              id
              name
              logo
              active
              productType
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      archived
      details {
        id
        accountType
        isOnboardingComplete
        email
        feedRegion
        tippingAccount {
          code
          merchant
          accountId
          verified
          missingRequirements {
            key
            description
            __typename
          }
          pendingValidation
          bankDetails {
            name
            sortCode
            last4Digits
            __typename
          }
          __typename
        }
        phone_number
        phoneNumberConfirmed
        pushNotificationToken
        pushNotificationReadDate
        createdAt
        updatedAt
        __typename
      }
      employers {
        items {
          id
          jobRole
          businessId
          profileId
          employeeId
          tippingPolicyAcknowledgedDate
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          confirmed
          __typename
        }
        nextToken
        __typename
      }
      experiences {
        items {
          id
          jobTitle
          businessId
          companyName
          profileId
          startDate
          endDate
          employmentType
          location
          description
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      jobs {
        items {
          id
          profileId
          jobId
          coverLetter
          saved
          savedDate
          applied
          appliedDate
          createdAt
          updatedAt
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          job {
            id
            owner
            jobType
            businessId
            jobRole
            title
            description
            salary {
              type
              from
              to
              __typename
            }
            region
            requireCV
            requireCoverLetter
            createdAt
            archived
            startDate
            endDate
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            reported {
              items {
                id
                postId
                userId
                commentId
                jobId
                creatorId
                createdAt
                message
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          rejected
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCommentLikeQueryVariables,
  APITypes.GetCommentLikeQuery
>;
export const listCommentLikes = /* GraphQL */ `query ListCommentLikes(
  $filter: ModelCommentLikeFilterInput
  $limit: Int
  $nextToken: String
) {
  listCommentLikes(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      commentId
      profileId
      createdAt
      updatedAt
      profile {
        id
        slug
        firstName
        lastName
        bio
        picture
        location {
          searchName
          address
          address2
          city
          region
          postcode
          country
          latitude
          longitude
          __typename
        }
        createdAt
        updatedAt
        business {
          id
          verified
          name
          tipping {
            merchantId
            policy
            type
            active
            __typename
          }
          marketing {
            customerId
            subscriptionId
            active
            expirationDate
            sequenceId
            cancelled
            cancelledAt
            __typename
          }
          createdAt
          updatedAt
          owner
          code
          answers {
            questionId
            value
            __typename
          }
          isOutlet
          details {
            id
            contactName
            jobRole
            phone
            email
            website
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          ownerProfile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          invites {
            items {
              id
              businessId
              receiver
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          employees {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          brands {
            items {
              id
              businessId
              brandId
              status
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profileId
              brand {
                id
                name
                logo
                active
                productType
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        archived
        details {
          id
          accountType
          isOnboardingComplete
          email
          feedRegion
          tippingAccount {
            code
            merchant
            accountId
            verified
            missingRequirements {
              key
              description
              __typename
            }
            pendingValidation
            bankDetails {
              name
              sortCode
              last4Digits
              __typename
            }
            __typename
          }
          phone_number
          phoneNumberConfirmed
          pushNotificationToken
          pushNotificationReadDate
          createdAt
          updatedAt
          __typename
        }
        employers {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        experiences {
          items {
            id
            jobTitle
            businessId
            companyName
            profileId
            startDate
            endDate
            employmentType
            location
            description
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        jobs {
          items {
            id
            profileId
            jobId
            coverLetter
            saved
            savedDate
            applied
            appliedDate
            createdAt
            updatedAt
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            job {
              id
              owner
              jobType
              businessId
              jobRole
              title
              description
              salary {
                type
                from
                to
                __typename
              }
              region
              requireCV
              requireCoverLetter
              createdAt
              archived
              startDate
              endDate
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              reported {
                nextToken
                __typename
              }
              __typename
            }
            rejected
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCommentLikesQueryVariables,
  APITypes.ListCommentLikesQuery
>;
export const getUserNotification = /* GraphQL */ `query GetUserNotification($id: ID!) {
  getUserNotification(id: $id) {
    id
    receiverId
    senderId
    type
    targetId
    subTargetId
    additionalTargetId
    read
    title
    message
    createdAt
    updatedAt
    sender {
      id
      slug
      firstName
      lastName
      bio
      picture
      location {
        searchName
        address
        address2
        city
        region
        postcode
        country
        latitude
        longitude
        __typename
      }
      createdAt
      updatedAt
      business {
        id
        verified
        name
        tipping {
          merchantId
          policy
          type
          active
          __typename
        }
        marketing {
          customerId
          subscriptionId
          active
          expirationDate
          sequenceId
          cancelled
          cancelledAt
          __typename
        }
        createdAt
        updatedAt
        owner
        code
        answers {
          questionId
          value
          __typename
        }
        isOutlet
        details {
          id
          contactName
          jobRole
          phone
          email
          website
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        ownerProfile {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        invites {
          items {
            id
            businessId
            receiver
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        employees {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        brands {
          items {
            id
            businessId
            brandId
            status
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profileId
            brand {
              id
              name
              logo
              active
              productType
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      archived
      details {
        id
        accountType
        isOnboardingComplete
        email
        feedRegion
        tippingAccount {
          code
          merchant
          accountId
          verified
          missingRequirements {
            key
            description
            __typename
          }
          pendingValidation
          bankDetails {
            name
            sortCode
            last4Digits
            __typename
          }
          __typename
        }
        phone_number
        phoneNumberConfirmed
        pushNotificationToken
        pushNotificationReadDate
        createdAt
        updatedAt
        __typename
      }
      employers {
        items {
          id
          jobRole
          businessId
          profileId
          employeeId
          tippingPolicyAcknowledgedDate
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          confirmed
          __typename
        }
        nextToken
        __typename
      }
      experiences {
        items {
          id
          jobTitle
          businessId
          companyName
          profileId
          startDate
          endDate
          employmentType
          location
          description
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      jobs {
        items {
          id
          profileId
          jobId
          coverLetter
          saved
          savedDate
          applied
          appliedDate
          createdAt
          updatedAt
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          job {
            id
            owner
            jobType
            businessId
            jobRole
            title
            description
            salary {
              type
              from
              to
              __typename
            }
            region
            requireCV
            requireCoverLetter
            createdAt
            archived
            startDate
            endDate
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            reported {
              items {
                id
                postId
                userId
                commentId
                jobId
                creatorId
                createdAt
                message
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          rejected
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserNotificationQueryVariables,
  APITypes.GetUserNotificationQuery
>;
export const listUserNotifications = /* GraphQL */ `query ListUserNotifications(
  $filter: ModelUserNotificationFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      receiverId
      senderId
      type
      targetId
      subTargetId
      additionalTargetId
      read
      title
      message
      createdAt
      updatedAt
      sender {
        id
        slug
        firstName
        lastName
        bio
        picture
        location {
          searchName
          address
          address2
          city
          region
          postcode
          country
          latitude
          longitude
          __typename
        }
        createdAt
        updatedAt
        business {
          id
          verified
          name
          tipping {
            merchantId
            policy
            type
            active
            __typename
          }
          marketing {
            customerId
            subscriptionId
            active
            expirationDate
            sequenceId
            cancelled
            cancelledAt
            __typename
          }
          createdAt
          updatedAt
          owner
          code
          answers {
            questionId
            value
            __typename
          }
          isOutlet
          details {
            id
            contactName
            jobRole
            phone
            email
            website
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          ownerProfile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          invites {
            items {
              id
              businessId
              receiver
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          employees {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          brands {
            items {
              id
              businessId
              brandId
              status
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profileId
              brand {
                id
                name
                logo
                active
                productType
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        archived
        details {
          id
          accountType
          isOnboardingComplete
          email
          feedRegion
          tippingAccount {
            code
            merchant
            accountId
            verified
            missingRequirements {
              key
              description
              __typename
            }
            pendingValidation
            bankDetails {
              name
              sortCode
              last4Digits
              __typename
            }
            __typename
          }
          phone_number
          phoneNumberConfirmed
          pushNotificationToken
          pushNotificationReadDate
          createdAt
          updatedAt
          __typename
        }
        employers {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        experiences {
          items {
            id
            jobTitle
            businessId
            companyName
            profileId
            startDate
            endDate
            employmentType
            location
            description
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        jobs {
          items {
            id
            profileId
            jobId
            coverLetter
            saved
            savedDate
            applied
            appliedDate
            createdAt
            updatedAt
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            job {
              id
              owner
              jobType
              businessId
              jobRole
              title
              description
              salary {
                type
                from
                to
                __typename
              }
              region
              requireCV
              requireCoverLetter
              createdAt
              archived
              startDate
              endDate
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              reported {
                nextToken
                __typename
              }
              __typename
            }
            rejected
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserNotificationsQueryVariables,
  APITypes.ListUserNotificationsQuery
>;
export const userNotificationsByReceiverId = /* GraphQL */ `query UserNotificationsByReceiverId(
  $receiverId: ID
  $id: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelUserNotificationFilterInput
  $limit: Int
  $nextToken: String
) {
  userNotificationsByReceiverId(
    receiverId: $receiverId
    id: $id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      receiverId
      senderId
      type
      targetId
      subTargetId
      additionalTargetId
      read
      title
      message
      createdAt
      updatedAt
      sender {
        id
        slug
        firstName
        lastName
        bio
        picture
        location {
          searchName
          address
          address2
          city
          region
          postcode
          country
          latitude
          longitude
          __typename
        }
        createdAt
        updatedAt
        business {
          id
          verified
          name
          tipping {
            merchantId
            policy
            type
            active
            __typename
          }
          marketing {
            customerId
            subscriptionId
            active
            expirationDate
            sequenceId
            cancelled
            cancelledAt
            __typename
          }
          createdAt
          updatedAt
          owner
          code
          answers {
            questionId
            value
            __typename
          }
          isOutlet
          details {
            id
            contactName
            jobRole
            phone
            email
            website
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          ownerProfile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          invites {
            items {
              id
              businessId
              receiver
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          employees {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          brands {
            items {
              id
              businessId
              brandId
              status
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profileId
              brand {
                id
                name
                logo
                active
                productType
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        archived
        details {
          id
          accountType
          isOnboardingComplete
          email
          feedRegion
          tippingAccount {
            code
            merchant
            accountId
            verified
            missingRequirements {
              key
              description
              __typename
            }
            pendingValidation
            bankDetails {
              name
              sortCode
              last4Digits
              __typename
            }
            __typename
          }
          phone_number
          phoneNumberConfirmed
          pushNotificationToken
          pushNotificationReadDate
          createdAt
          updatedAt
          __typename
        }
        employers {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        experiences {
          items {
            id
            jobTitle
            businessId
            companyName
            profileId
            startDate
            endDate
            employmentType
            location
            description
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        jobs {
          items {
            id
            profileId
            jobId
            coverLetter
            saved
            savedDate
            applied
            appliedDate
            createdAt
            updatedAt
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            job {
              id
              owner
              jobType
              businessId
              jobRole
              title
              description
              salary {
                type
                from
                to
                __typename
              }
              region
              requireCV
              requireCoverLetter
              createdAt
              archived
              startDate
              endDate
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              reported {
                nextToken
                __typename
              }
              __typename
            }
            rejected
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserNotificationsByReceiverIdQueryVariables,
  APITypes.UserNotificationsByReceiverIdQuery
>;
export const userNotificationsByDate = /* GraphQL */ `query UserNotificationsByDate(
  $receiverId: ID
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelUserNotificationFilterInput
  $limit: Int
  $nextToken: String
) {
  userNotificationsByDate(
    receiverId: $receiverId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      receiverId
      senderId
      type
      targetId
      subTargetId
      additionalTargetId
      read
      title
      message
      createdAt
      updatedAt
      sender {
        id
        slug
        firstName
        lastName
        bio
        picture
        location {
          searchName
          address
          address2
          city
          region
          postcode
          country
          latitude
          longitude
          __typename
        }
        createdAt
        updatedAt
        business {
          id
          verified
          name
          tipping {
            merchantId
            policy
            type
            active
            __typename
          }
          marketing {
            customerId
            subscriptionId
            active
            expirationDate
            sequenceId
            cancelled
            cancelledAt
            __typename
          }
          createdAt
          updatedAt
          owner
          code
          answers {
            questionId
            value
            __typename
          }
          isOutlet
          details {
            id
            contactName
            jobRole
            phone
            email
            website
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          ownerProfile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          invites {
            items {
              id
              businessId
              receiver
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          employees {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          brands {
            items {
              id
              businessId
              brandId
              status
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profileId
              brand {
                id
                name
                logo
                active
                productType
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        archived
        details {
          id
          accountType
          isOnboardingComplete
          email
          feedRegion
          tippingAccount {
            code
            merchant
            accountId
            verified
            missingRequirements {
              key
              description
              __typename
            }
            pendingValidation
            bankDetails {
              name
              sortCode
              last4Digits
              __typename
            }
            __typename
          }
          phone_number
          phoneNumberConfirmed
          pushNotificationToken
          pushNotificationReadDate
          createdAt
          updatedAt
          __typename
        }
        employers {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        experiences {
          items {
            id
            jobTitle
            businessId
            companyName
            profileId
            startDate
            endDate
            employmentType
            location
            description
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        jobs {
          items {
            id
            profileId
            jobId
            coverLetter
            saved
            savedDate
            applied
            appliedDate
            createdAt
            updatedAt
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            job {
              id
              owner
              jobType
              businessId
              jobRole
              title
              description
              salary {
                type
                from
                to
                __typename
              }
              region
              requireCV
              requireCoverLetter
              createdAt
              archived
              startDate
              endDate
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              reported {
                nextToken
                __typename
              }
              __typename
            }
            rejected
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserNotificationsByDateQueryVariables,
  APITypes.UserNotificationsByDateQuery
>;
export const getPublicEmail = /* GraphQL */ `query GetPublicEmail($id: ID!) {
  getPublicEmail(id: $id) {
    id
    email
    allowMarketing
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPublicEmailQueryVariables,
  APITypes.GetPublicEmailQuery
>;
export const listPublicEmails = /* GraphQL */ `query ListPublicEmails(
  $filter: ModelPublicEmailFilterInput
  $limit: Int
  $nextToken: String
) {
  listPublicEmails(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      email
      allowMarketing
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPublicEmailsQueryVariables,
  APITypes.ListPublicEmailsQuery
>;
export const getTipSplit = /* GraphQL */ `query GetTipSplit($id: ID!) {
  getTipSplit(id: $id) {
    id
    amount
    owner
    status
    from
    to
    createdAt
    updatedAt
    ownerProfile {
      id
      slug
      firstName
      lastName
      bio
      picture
      location {
        searchName
        address
        address2
        city
        region
        postcode
        country
        latitude
        longitude
        __typename
      }
      createdAt
      updatedAt
      business {
        id
        verified
        name
        tipping {
          merchantId
          policy
          type
          active
          __typename
        }
        marketing {
          customerId
          subscriptionId
          active
          expirationDate
          sequenceId
          cancelled
          cancelledAt
          __typename
        }
        createdAt
        updatedAt
        owner
        code
        answers {
          questionId
          value
          __typename
        }
        isOutlet
        details {
          id
          contactName
          jobRole
          phone
          email
          website
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        ownerProfile {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        invites {
          items {
            id
            businessId
            receiver
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        employees {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        brands {
          items {
            id
            businessId
            brandId
            status
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profileId
            brand {
              id
              name
              logo
              active
              productType
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      archived
      details {
        id
        accountType
        isOnboardingComplete
        email
        feedRegion
        tippingAccount {
          code
          merchant
          accountId
          verified
          missingRequirements {
            key
            description
            __typename
          }
          pendingValidation
          bankDetails {
            name
            sortCode
            last4Digits
            __typename
          }
          __typename
        }
        phone_number
        phoneNumberConfirmed
        pushNotificationToken
        pushNotificationReadDate
        createdAt
        updatedAt
        __typename
      }
      employers {
        items {
          id
          jobRole
          businessId
          profileId
          employeeId
          tippingPolicyAcknowledgedDate
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          confirmed
          __typename
        }
        nextToken
        __typename
      }
      experiences {
        items {
          id
          jobTitle
          businessId
          companyName
          profileId
          startDate
          endDate
          employmentType
          location
          description
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      jobs {
        items {
          id
          profileId
          jobId
          coverLetter
          saved
          savedDate
          applied
          appliedDate
          createdAt
          updatedAt
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          job {
            id
            owner
            jobType
            businessId
            jobRole
            title
            description
            salary {
              type
              from
              to
              __typename
            }
            region
            requireCV
            requireCoverLetter
            createdAt
            archived
            startDate
            endDate
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            reported {
              items {
                id
                postId
                userId
                commentId
                jobId
                creatorId
                createdAt
                message
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          rejected
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    transfers {
      items {
        id
        splitId
        employeeId
        amount
        status
        createdAt
        updatedAt
        employee {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    transactions {
      items {
        id
        splitId
        receiverId
        amount
        fee
        currency
        createdAt
        status
        type
        source
        payoutInvoiceId
        transactionId
        updatedAt
        receiver {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTipSplitQueryVariables,
  APITypes.GetTipSplitQuery
>;
export const listTipSplits = /* GraphQL */ `query ListTipSplits(
  $filter: ModelTipSplitFilterInput
  $limit: Int
  $nextToken: String
) {
  listTipSplits(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      amount
      owner
      status
      from
      to
      createdAt
      updatedAt
      ownerProfile {
        id
        slug
        firstName
        lastName
        bio
        picture
        location {
          searchName
          address
          address2
          city
          region
          postcode
          country
          latitude
          longitude
          __typename
        }
        createdAt
        updatedAt
        business {
          id
          verified
          name
          tipping {
            merchantId
            policy
            type
            active
            __typename
          }
          marketing {
            customerId
            subscriptionId
            active
            expirationDate
            sequenceId
            cancelled
            cancelledAt
            __typename
          }
          createdAt
          updatedAt
          owner
          code
          answers {
            questionId
            value
            __typename
          }
          isOutlet
          details {
            id
            contactName
            jobRole
            phone
            email
            website
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          ownerProfile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          invites {
            items {
              id
              businessId
              receiver
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          employees {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          brands {
            items {
              id
              businessId
              brandId
              status
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profileId
              brand {
                id
                name
                logo
                active
                productType
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        archived
        details {
          id
          accountType
          isOnboardingComplete
          email
          feedRegion
          tippingAccount {
            code
            merchant
            accountId
            verified
            missingRequirements {
              key
              description
              __typename
            }
            pendingValidation
            bankDetails {
              name
              sortCode
              last4Digits
              __typename
            }
            __typename
          }
          phone_number
          phoneNumberConfirmed
          pushNotificationToken
          pushNotificationReadDate
          createdAt
          updatedAt
          __typename
        }
        employers {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        experiences {
          items {
            id
            jobTitle
            businessId
            companyName
            profileId
            startDate
            endDate
            employmentType
            location
            description
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        jobs {
          items {
            id
            profileId
            jobId
            coverLetter
            saved
            savedDate
            applied
            appliedDate
            createdAt
            updatedAt
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            job {
              id
              owner
              jobType
              businessId
              jobRole
              title
              description
              salary {
                type
                from
                to
                __typename
              }
              region
              requireCV
              requireCoverLetter
              createdAt
              archived
              startDate
              endDate
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              reported {
                nextToken
                __typename
              }
              __typename
            }
            rejected
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      transfers {
        items {
          id
          splitId
          employeeId
          amount
          status
          createdAt
          updatedAt
          employee {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      transactions {
        items {
          id
          splitId
          receiverId
          amount
          fee
          currency
          createdAt
          status
          type
          source
          payoutInvoiceId
          transactionId
          updatedAt
          receiver {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTipSplitsQueryVariables,
  APITypes.ListTipSplitsQuery
>;
export const searchTipSplits = /* GraphQL */ `query SearchTipSplits(
  $filter: SearchableTipSplitFilterInput
  $sort: SearchableTipSplitSortInput
  $limit: Int
  $nextToken: String
  $from: Int
) {
  searchTipSplits(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
  ) {
    items {
      id
      amount
      owner
      status
      from
      to
      createdAt
      updatedAt
      ownerProfile {
        id
        slug
        firstName
        lastName
        bio
        picture
        location {
          searchName
          address
          address2
          city
          region
          postcode
          country
          latitude
          longitude
          __typename
        }
        createdAt
        updatedAt
        business {
          id
          verified
          name
          tipping {
            merchantId
            policy
            type
            active
            __typename
          }
          marketing {
            customerId
            subscriptionId
            active
            expirationDate
            sequenceId
            cancelled
            cancelledAt
            __typename
          }
          createdAt
          updatedAt
          owner
          code
          answers {
            questionId
            value
            __typename
          }
          isOutlet
          details {
            id
            contactName
            jobRole
            phone
            email
            website
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          ownerProfile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          invites {
            items {
              id
              businessId
              receiver
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          employees {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          brands {
            items {
              id
              businessId
              brandId
              status
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profileId
              brand {
                id
                name
                logo
                active
                productType
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        archived
        details {
          id
          accountType
          isOnboardingComplete
          email
          feedRegion
          tippingAccount {
            code
            merchant
            accountId
            verified
            missingRequirements {
              key
              description
              __typename
            }
            pendingValidation
            bankDetails {
              name
              sortCode
              last4Digits
              __typename
            }
            __typename
          }
          phone_number
          phoneNumberConfirmed
          pushNotificationToken
          pushNotificationReadDate
          createdAt
          updatedAt
          __typename
        }
        employers {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        experiences {
          items {
            id
            jobTitle
            businessId
            companyName
            profileId
            startDate
            endDate
            employmentType
            location
            description
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        jobs {
          items {
            id
            profileId
            jobId
            coverLetter
            saved
            savedDate
            applied
            appliedDate
            createdAt
            updatedAt
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            job {
              id
              owner
              jobType
              businessId
              jobRole
              title
              description
              salary {
                type
                from
                to
                __typename
              }
              region
              requireCV
              requireCoverLetter
              createdAt
              archived
              startDate
              endDate
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              reported {
                nextToken
                __typename
              }
              __typename
            }
            rejected
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      transfers {
        items {
          id
          splitId
          employeeId
          amount
          status
          createdAt
          updatedAt
          employee {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      transactions {
        items {
          id
          splitId
          receiverId
          amount
          fee
          currency
          createdAt
          status
          type
          source
          payoutInvoiceId
          transactionId
          updatedAt
          receiver {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    total
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchTipSplitsQueryVariables,
  APITypes.SearchTipSplitsQuery
>;
export const getEmployeeTransfer = /* GraphQL */ `query GetEmployeeTransfer($id: ID!) {
  getEmployeeTransfer(id: $id) {
    id
    splitId
    employeeId
    amount
    status
    createdAt
    updatedAt
    employee {
      id
      slug
      firstName
      lastName
      bio
      picture
      location {
        searchName
        address
        address2
        city
        region
        postcode
        country
        latitude
        longitude
        __typename
      }
      createdAt
      updatedAt
      business {
        id
        verified
        name
        tipping {
          merchantId
          policy
          type
          active
          __typename
        }
        marketing {
          customerId
          subscriptionId
          active
          expirationDate
          sequenceId
          cancelled
          cancelledAt
          __typename
        }
        createdAt
        updatedAt
        owner
        code
        answers {
          questionId
          value
          __typename
        }
        isOutlet
        details {
          id
          contactName
          jobRole
          phone
          email
          website
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        ownerProfile {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        invites {
          items {
            id
            businessId
            receiver
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        employees {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        brands {
          items {
            id
            businessId
            brandId
            status
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profileId
            brand {
              id
              name
              logo
              active
              productType
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      archived
      details {
        id
        accountType
        isOnboardingComplete
        email
        feedRegion
        tippingAccount {
          code
          merchant
          accountId
          verified
          missingRequirements {
            key
            description
            __typename
          }
          pendingValidation
          bankDetails {
            name
            sortCode
            last4Digits
            __typename
          }
          __typename
        }
        phone_number
        phoneNumberConfirmed
        pushNotificationToken
        pushNotificationReadDate
        createdAt
        updatedAt
        __typename
      }
      employers {
        items {
          id
          jobRole
          businessId
          profileId
          employeeId
          tippingPolicyAcknowledgedDate
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          confirmed
          __typename
        }
        nextToken
        __typename
      }
      experiences {
        items {
          id
          jobTitle
          businessId
          companyName
          profileId
          startDate
          endDate
          employmentType
          location
          description
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      jobs {
        items {
          id
          profileId
          jobId
          coverLetter
          saved
          savedDate
          applied
          appliedDate
          createdAt
          updatedAt
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          job {
            id
            owner
            jobType
            businessId
            jobRole
            title
            description
            salary {
              type
              from
              to
              __typename
            }
            region
            requireCV
            requireCoverLetter
            createdAt
            archived
            startDate
            endDate
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            reported {
              items {
                id
                postId
                userId
                commentId
                jobId
                creatorId
                createdAt
                message
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          rejected
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetEmployeeTransferQueryVariables,
  APITypes.GetEmployeeTransferQuery
>;
export const listEmployeeTransfers = /* GraphQL */ `query ListEmployeeTransfers(
  $filter: ModelEmployeeTransferFilterInput
  $limit: Int
  $nextToken: String
) {
  listEmployeeTransfers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      splitId
      employeeId
      amount
      status
      createdAt
      updatedAt
      employee {
        id
        slug
        firstName
        lastName
        bio
        picture
        location {
          searchName
          address
          address2
          city
          region
          postcode
          country
          latitude
          longitude
          __typename
        }
        createdAt
        updatedAt
        business {
          id
          verified
          name
          tipping {
            merchantId
            policy
            type
            active
            __typename
          }
          marketing {
            customerId
            subscriptionId
            active
            expirationDate
            sequenceId
            cancelled
            cancelledAt
            __typename
          }
          createdAt
          updatedAt
          owner
          code
          answers {
            questionId
            value
            __typename
          }
          isOutlet
          details {
            id
            contactName
            jobRole
            phone
            email
            website
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          ownerProfile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          invites {
            items {
              id
              businessId
              receiver
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          employees {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          brands {
            items {
              id
              businessId
              brandId
              status
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profileId
              brand {
                id
                name
                logo
                active
                productType
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        archived
        details {
          id
          accountType
          isOnboardingComplete
          email
          feedRegion
          tippingAccount {
            code
            merchant
            accountId
            verified
            missingRequirements {
              key
              description
              __typename
            }
            pendingValidation
            bankDetails {
              name
              sortCode
              last4Digits
              __typename
            }
            __typename
          }
          phone_number
          phoneNumberConfirmed
          pushNotificationToken
          pushNotificationReadDate
          createdAt
          updatedAt
          __typename
        }
        employers {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        experiences {
          items {
            id
            jobTitle
            businessId
            companyName
            profileId
            startDate
            endDate
            employmentType
            location
            description
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        jobs {
          items {
            id
            profileId
            jobId
            coverLetter
            saved
            savedDate
            applied
            appliedDate
            createdAt
            updatedAt
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            job {
              id
              owner
              jobType
              businessId
              jobRole
              title
              description
              salary {
                type
                from
                to
                __typename
              }
              region
              requireCV
              requireCoverLetter
              createdAt
              archived
              startDate
              endDate
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              reported {
                nextToken
                __typename
              }
              __typename
            }
            rejected
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListEmployeeTransfersQueryVariables,
  APITypes.ListEmployeeTransfersQuery
>;
export const transferByEmployee = /* GraphQL */ `query TransferByEmployee(
  $employeeId: ID
  $status: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelEmployeeTransferFilterInput
  $limit: Int
  $nextToken: String
) {
  transferByEmployee(
    employeeId: $employeeId
    status: $status
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      splitId
      employeeId
      amount
      status
      createdAt
      updatedAt
      employee {
        id
        slug
        firstName
        lastName
        bio
        picture
        location {
          searchName
          address
          address2
          city
          region
          postcode
          country
          latitude
          longitude
          __typename
        }
        createdAt
        updatedAt
        business {
          id
          verified
          name
          tipping {
            merchantId
            policy
            type
            active
            __typename
          }
          marketing {
            customerId
            subscriptionId
            active
            expirationDate
            sequenceId
            cancelled
            cancelledAt
            __typename
          }
          createdAt
          updatedAt
          owner
          code
          answers {
            questionId
            value
            __typename
          }
          isOutlet
          details {
            id
            contactName
            jobRole
            phone
            email
            website
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          ownerProfile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          invites {
            items {
              id
              businessId
              receiver
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          employees {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          brands {
            items {
              id
              businessId
              brandId
              status
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profileId
              brand {
                id
                name
                logo
                active
                productType
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        archived
        details {
          id
          accountType
          isOnboardingComplete
          email
          feedRegion
          tippingAccount {
            code
            merchant
            accountId
            verified
            missingRequirements {
              key
              description
              __typename
            }
            pendingValidation
            bankDetails {
              name
              sortCode
              last4Digits
              __typename
            }
            __typename
          }
          phone_number
          phoneNumberConfirmed
          pushNotificationToken
          pushNotificationReadDate
          createdAt
          updatedAt
          __typename
        }
        employers {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        experiences {
          items {
            id
            jobTitle
            businessId
            companyName
            profileId
            startDate
            endDate
            employmentType
            location
            description
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        jobs {
          items {
            id
            profileId
            jobId
            coverLetter
            saved
            savedDate
            applied
            appliedDate
            createdAt
            updatedAt
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            job {
              id
              owner
              jobType
              businessId
              jobRole
              title
              description
              salary {
                type
                from
                to
                __typename
              }
              region
              requireCV
              requireCoverLetter
              createdAt
              archived
              startDate
              endDate
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              reported {
                nextToken
                __typename
              }
              __typename
            }
            rejected
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.TransferByEmployeeQueryVariables,
  APITypes.TransferByEmployeeQuery
>;
export const getTipTransaction = /* GraphQL */ `query GetTipTransaction($id: ID!) {
  getTipTransaction(id: $id) {
    id
    splitId
    receiverId
    amount
    fee
    currency
    createdAt
    status
    type
    source
    payoutInvoiceId
    transactionId
    updatedAt
    receiver {
      id
      slug
      firstName
      lastName
      bio
      picture
      location {
        searchName
        address
        address2
        city
        region
        postcode
        country
        latitude
        longitude
        __typename
      }
      createdAt
      updatedAt
      business {
        id
        verified
        name
        tipping {
          merchantId
          policy
          type
          active
          __typename
        }
        marketing {
          customerId
          subscriptionId
          active
          expirationDate
          sequenceId
          cancelled
          cancelledAt
          __typename
        }
        createdAt
        updatedAt
        owner
        code
        answers {
          questionId
          value
          __typename
        }
        isOutlet
        details {
          id
          contactName
          jobRole
          phone
          email
          website
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        ownerProfile {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        invites {
          items {
            id
            businessId
            receiver
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        employees {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        brands {
          items {
            id
            businessId
            brandId
            status
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profileId
            brand {
              id
              name
              logo
              active
              productType
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      archived
      details {
        id
        accountType
        isOnboardingComplete
        email
        feedRegion
        tippingAccount {
          code
          merchant
          accountId
          verified
          missingRequirements {
            key
            description
            __typename
          }
          pendingValidation
          bankDetails {
            name
            sortCode
            last4Digits
            __typename
          }
          __typename
        }
        phone_number
        phoneNumberConfirmed
        pushNotificationToken
        pushNotificationReadDate
        createdAt
        updatedAt
        __typename
      }
      employers {
        items {
          id
          jobRole
          businessId
          profileId
          employeeId
          tippingPolicyAcknowledgedDate
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          confirmed
          __typename
        }
        nextToken
        __typename
      }
      experiences {
        items {
          id
          jobTitle
          businessId
          companyName
          profileId
          startDate
          endDate
          employmentType
          location
          description
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      jobs {
        items {
          id
          profileId
          jobId
          coverLetter
          saved
          savedDate
          applied
          appliedDate
          createdAt
          updatedAt
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          job {
            id
            owner
            jobType
            businessId
            jobRole
            title
            description
            salary {
              type
              from
              to
              __typename
            }
            region
            requireCV
            requireCoverLetter
            createdAt
            archived
            startDate
            endDate
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            reported {
              items {
                id
                postId
                userId
                commentId
                jobId
                creatorId
                createdAt
                message
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          rejected
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTipTransactionQueryVariables,
  APITypes.GetTipTransactionQuery
>;
export const listTipTransactions = /* GraphQL */ `query ListTipTransactions(
  $filter: ModelTipTransactionFilterInput
  $limit: Int
  $nextToken: String
) {
  listTipTransactions(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      splitId
      receiverId
      amount
      fee
      currency
      createdAt
      status
      type
      source
      payoutInvoiceId
      transactionId
      updatedAt
      receiver {
        id
        slug
        firstName
        lastName
        bio
        picture
        location {
          searchName
          address
          address2
          city
          region
          postcode
          country
          latitude
          longitude
          __typename
        }
        createdAt
        updatedAt
        business {
          id
          verified
          name
          tipping {
            merchantId
            policy
            type
            active
            __typename
          }
          marketing {
            customerId
            subscriptionId
            active
            expirationDate
            sequenceId
            cancelled
            cancelledAt
            __typename
          }
          createdAt
          updatedAt
          owner
          code
          answers {
            questionId
            value
            __typename
          }
          isOutlet
          details {
            id
            contactName
            jobRole
            phone
            email
            website
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          ownerProfile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          invites {
            items {
              id
              businessId
              receiver
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          employees {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          brands {
            items {
              id
              businessId
              brandId
              status
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profileId
              brand {
                id
                name
                logo
                active
                productType
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        archived
        details {
          id
          accountType
          isOnboardingComplete
          email
          feedRegion
          tippingAccount {
            code
            merchant
            accountId
            verified
            missingRequirements {
              key
              description
              __typename
            }
            pendingValidation
            bankDetails {
              name
              sortCode
              last4Digits
              __typename
            }
            __typename
          }
          phone_number
          phoneNumberConfirmed
          pushNotificationToken
          pushNotificationReadDate
          createdAt
          updatedAt
          __typename
        }
        employers {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        experiences {
          items {
            id
            jobTitle
            businessId
            companyName
            profileId
            startDate
            endDate
            employmentType
            location
            description
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        jobs {
          items {
            id
            profileId
            jobId
            coverLetter
            saved
            savedDate
            applied
            appliedDate
            createdAt
            updatedAt
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            job {
              id
              owner
              jobType
              businessId
              jobRole
              title
              description
              salary {
                type
                from
                to
                __typename
              }
              region
              requireCV
              requireCoverLetter
              createdAt
              archived
              startDate
              endDate
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              reported {
                nextToken
                __typename
              }
              __typename
            }
            rejected
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTipTransactionsQueryVariables,
  APITypes.ListTipTransactionsQuery
>;
export const searchTipTransactions = /* GraphQL */ `query SearchTipTransactions(
  $filter: SearchableTipTransactionFilterInput
  $sort: SearchableTipTransactionSortInput
  $limit: Int
  $nextToken: String
  $from: Int
) {
  searchTipTransactions(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
  ) {
    items {
      id
      splitId
      receiverId
      amount
      fee
      currency
      createdAt
      status
      type
      source
      payoutInvoiceId
      transactionId
      updatedAt
      receiver {
        id
        slug
        firstName
        lastName
        bio
        picture
        location {
          searchName
          address
          address2
          city
          region
          postcode
          country
          latitude
          longitude
          __typename
        }
        createdAt
        updatedAt
        business {
          id
          verified
          name
          tipping {
            merchantId
            policy
            type
            active
            __typename
          }
          marketing {
            customerId
            subscriptionId
            active
            expirationDate
            sequenceId
            cancelled
            cancelledAt
            __typename
          }
          createdAt
          updatedAt
          owner
          code
          answers {
            questionId
            value
            __typename
          }
          isOutlet
          details {
            id
            contactName
            jobRole
            phone
            email
            website
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          ownerProfile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          invites {
            items {
              id
              businessId
              receiver
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          employees {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          brands {
            items {
              id
              businessId
              brandId
              status
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profileId
              brand {
                id
                name
                logo
                active
                productType
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        archived
        details {
          id
          accountType
          isOnboardingComplete
          email
          feedRegion
          tippingAccount {
            code
            merchant
            accountId
            verified
            missingRequirements {
              key
              description
              __typename
            }
            pendingValidation
            bankDetails {
              name
              sortCode
              last4Digits
              __typename
            }
            __typename
          }
          phone_number
          phoneNumberConfirmed
          pushNotificationToken
          pushNotificationReadDate
          createdAt
          updatedAt
          __typename
        }
        employers {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        experiences {
          items {
            id
            jobTitle
            businessId
            companyName
            profileId
            startDate
            endDate
            employmentType
            location
            description
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        jobs {
          items {
            id
            profileId
            jobId
            coverLetter
            saved
            savedDate
            applied
            appliedDate
            createdAt
            updatedAt
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            job {
              id
              owner
              jobType
              businessId
              jobRole
              title
              description
              salary {
                type
                from
                to
                __typename
              }
              region
              requireCV
              requireCoverLetter
              createdAt
              archived
              startDate
              endDate
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              reported {
                nextToken
                __typename
              }
              __typename
            }
            rejected
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    total
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchTipTransactionsQueryVariables,
  APITypes.SearchTipTransactionsQuery
>;
export const getBusinessSetupTippingInvite = /* GraphQL */ `query GetBusinessSetupTippingInvite($id: ID!) {
  getBusinessSetupTippingInvite(id: $id) {
    id
    sender
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBusinessSetupTippingInviteQueryVariables,
  APITypes.GetBusinessSetupTippingInviteQuery
>;
export const listBusinessSetupTippingInvites = /* GraphQL */ `query ListBusinessSetupTippingInvites(
  $filter: ModelBusinessSetupTippingInviteFilterInput
  $limit: Int
  $nextToken: String
) {
  listBusinessSetupTippingInvites(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      sender
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBusinessSetupTippingInvitesQueryVariables,
  APITypes.ListBusinessSetupTippingInvitesQuery
>;
export const businessSetupTippingInviteBySender = /* GraphQL */ `query BusinessSetupTippingInviteBySender(
  $sender: ID
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelBusinessSetupTippingInviteFilterInput
  $limit: Int
  $nextToken: String
) {
  businessSetupTippingInviteBySender(
    sender: $sender
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      sender
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.BusinessSetupTippingInviteBySenderQueryVariables,
  APITypes.BusinessSetupTippingInviteBySenderQuery
>;
export const getUserMessage = /* GraphQL */ `query GetUserMessage($id: ID!) {
  getUserMessage(id: $id) {
    id
    senderId
    receiverId
    content
    createdAt
    updatedAt
    sender {
      id
      slug
      firstName
      lastName
      bio
      picture
      location {
        searchName
        address
        address2
        city
        region
        postcode
        country
        latitude
        longitude
        __typename
      }
      createdAt
      updatedAt
      business {
        id
        verified
        name
        tipping {
          merchantId
          policy
          type
          active
          __typename
        }
        marketing {
          customerId
          subscriptionId
          active
          expirationDate
          sequenceId
          cancelled
          cancelledAt
          __typename
        }
        createdAt
        updatedAt
        owner
        code
        answers {
          questionId
          value
          __typename
        }
        isOutlet
        details {
          id
          contactName
          jobRole
          phone
          email
          website
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        ownerProfile {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        invites {
          items {
            id
            businessId
            receiver
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        employees {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        brands {
          items {
            id
            businessId
            brandId
            status
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profileId
            brand {
              id
              name
              logo
              active
              productType
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      archived
      details {
        id
        accountType
        isOnboardingComplete
        email
        feedRegion
        tippingAccount {
          code
          merchant
          accountId
          verified
          missingRequirements {
            key
            description
            __typename
          }
          pendingValidation
          bankDetails {
            name
            sortCode
            last4Digits
            __typename
          }
          __typename
        }
        phone_number
        phoneNumberConfirmed
        pushNotificationToken
        pushNotificationReadDate
        createdAt
        updatedAt
        __typename
      }
      employers {
        items {
          id
          jobRole
          businessId
          profileId
          employeeId
          tippingPolicyAcknowledgedDate
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          confirmed
          __typename
        }
        nextToken
        __typename
      }
      experiences {
        items {
          id
          jobTitle
          businessId
          companyName
          profileId
          startDate
          endDate
          employmentType
          location
          description
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      jobs {
        items {
          id
          profileId
          jobId
          coverLetter
          saved
          savedDate
          applied
          appliedDate
          createdAt
          updatedAt
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          job {
            id
            owner
            jobType
            businessId
            jobRole
            title
            description
            salary {
              type
              from
              to
              __typename
            }
            region
            requireCV
            requireCoverLetter
            createdAt
            archived
            startDate
            endDate
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            reported {
              items {
                id
                postId
                userId
                commentId
                jobId
                creatorId
                createdAt
                message
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          rejected
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserMessageQueryVariables,
  APITypes.GetUserMessageQuery
>;
export const listUserMessages = /* GraphQL */ `query ListUserMessages(
  $filter: ModelUserMessageFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      senderId
      receiverId
      content
      createdAt
      updatedAt
      sender {
        id
        slug
        firstName
        lastName
        bio
        picture
        location {
          searchName
          address
          address2
          city
          region
          postcode
          country
          latitude
          longitude
          __typename
        }
        createdAt
        updatedAt
        business {
          id
          verified
          name
          tipping {
            merchantId
            policy
            type
            active
            __typename
          }
          marketing {
            customerId
            subscriptionId
            active
            expirationDate
            sequenceId
            cancelled
            cancelledAt
            __typename
          }
          createdAt
          updatedAt
          owner
          code
          answers {
            questionId
            value
            __typename
          }
          isOutlet
          details {
            id
            contactName
            jobRole
            phone
            email
            website
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          ownerProfile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          invites {
            items {
              id
              businessId
              receiver
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          employees {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          brands {
            items {
              id
              businessId
              brandId
              status
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profileId
              brand {
                id
                name
                logo
                active
                productType
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        archived
        details {
          id
          accountType
          isOnboardingComplete
          email
          feedRegion
          tippingAccount {
            code
            merchant
            accountId
            verified
            missingRequirements {
              key
              description
              __typename
            }
            pendingValidation
            bankDetails {
              name
              sortCode
              last4Digits
              __typename
            }
            __typename
          }
          phone_number
          phoneNumberConfirmed
          pushNotificationToken
          pushNotificationReadDate
          createdAt
          updatedAt
          __typename
        }
        employers {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        experiences {
          items {
            id
            jobTitle
            businessId
            companyName
            profileId
            startDate
            endDate
            employmentType
            location
            description
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        jobs {
          items {
            id
            profileId
            jobId
            coverLetter
            saved
            savedDate
            applied
            appliedDate
            createdAt
            updatedAt
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            job {
              id
              owner
              jobType
              businessId
              jobRole
              title
              description
              salary {
                type
                from
                to
                __typename
              }
              region
              requireCV
              requireCoverLetter
              createdAt
              archived
              startDate
              endDate
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              reported {
                nextToken
                __typename
              }
              __typename
            }
            rejected
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserMessagesQueryVariables,
  APITypes.ListUserMessagesQuery
>;
export const getUserPhoneValidation = /* GraphQL */ `query GetUserPhoneValidation($id: ID!) {
  getUserPhoneValidation(id: $id) {
    id
    userId
    phoneNumber
    code
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserPhoneValidationQueryVariables,
  APITypes.GetUserPhoneValidationQuery
>;
export const listUserPhoneValidations = /* GraphQL */ `query ListUserPhoneValidations(
  $filter: ModelUserPhoneValidationFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserPhoneValidations(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userId
      phoneNumber
      code
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserPhoneValidationsQueryVariables,
  APITypes.ListUserPhoneValidationsQuery
>;
export const phoneValidationByUserPhone = /* GraphQL */ `query PhoneValidationByUserPhone(
  $userId: ID
  $phoneNumber: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelUserPhoneValidationFilterInput
  $limit: Int
  $nextToken: String
) {
  phoneValidationByUserPhone(
    userId: $userId
    phoneNumber: $phoneNumber
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userId
      phoneNumber
      code
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PhoneValidationByUserPhoneQueryVariables,
  APITypes.PhoneValidationByUserPhoneQuery
>;
export const getPayoutInvoice = /* GraphQL */ `query GetPayoutInvoice($id: ID!) {
  getPayoutInvoice(id: $id) {
    id
    createdAt
    file
    amount
    fee
    currency
    status
    payedAt
    updatedAt
    tipTransactions {
      items {
        id
        splitId
        receiverId
        amount
        fee
        currency
        createdAt
        status
        type
        source
        payoutInvoiceId
        transactionId
        updatedAt
        receiver {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPayoutInvoiceQueryVariables,
  APITypes.GetPayoutInvoiceQuery
>;
export const listPayoutInvoices = /* GraphQL */ `query ListPayoutInvoices(
  $filter: ModelPayoutInvoiceFilterInput
  $limit: Int
  $nextToken: String
) {
  listPayoutInvoices(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      createdAt
      file
      amount
      fee
      currency
      status
      payedAt
      updatedAt
      tipTransactions {
        items {
          id
          splitId
          receiverId
          amount
          fee
          currency
          createdAt
          status
          type
          source
          payoutInvoiceId
          transactionId
          updatedAt
          receiver {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPayoutInvoicesQueryVariables,
  APITypes.ListPayoutInvoicesQuery
>;
