import React from "react";
import { IonItem, IonLabel } from "@ionic/react";
import "./Header.css";

interface IProps {
  children?: React.ReactNode;
  title: string;
}

const Header: React.FC<IProps> = ({ title, children }) => {
  return (
    <IonItem lines="none" className="item-header">
      <IonLabel slot="start">
        <strong>{title}</strong>
      </IonLabel>
      {children && <div slot="end">{children}</div>}
    </IonItem>
  );
};

export default Header;
