import { combineReducers } from "@reduxjs/toolkit";
import userReducers from "./reducers/user.reducers";
import uiReducers from "./reducers/ui.reducers";
import tippingReducers from "./reducers/tipping.reducers";
import individualsReducer from "./reducers/individuals.reducers";
import outletsReducer from "./reducers/outlets.reducers";
import brandReducers from "./reducers/brand.reducers";
import splitsReducers from "./reducers/splits.reducers";
import enterpriseReducers from "./reducers/enterprise.reducers";

export default combineReducers({
  ui: uiReducers,
  user: userReducers,

  tipping: tippingReducers,

  individuals: individualsReducer,
  outlets: outletsReducer,
  brands: brandReducers,
  splits: splitsReducers,
  enterprise: enterpriseReducers,
});
