const handleImageUrl = (path: string, width?: number, height?: number) => {
  if (width)
    return `${process.env.REACT_APP_MEDIA_URL}/${path}&w=${width}&h=${
      height || width
    }&fit=cover`;

  return `${process.env.REACT_APP_MEDIA_URL}/${path}`;
};

export default handleImageUrl;
