import { TAction } from "../store";
import {
  TRepeatEmployeeTransferSuccessPayload,
  TState,
} from "../types/splits.types";
import {
  LOAD_SPLIT_DETAILS_SUCCESS,
  LOAD_SPLITS_MORE_SUCCESS,
  LOAD_SPLITS_SUCCESS,
  REPEAT_EMPLOYEE_TRANSFER_SUCCESS,
} from "../actions/splits.actions";

//----------------------------------------------------------------------------------------------------------------------
const initialState: TState = {
  items: [],
};
//----------------------------------------------------------------------------------------------------------------------

const splitReducers = (state = initialState, { type, payload }: TAction) => {
  switch (type) {
    case LOAD_SPLITS_SUCCESS: {
      const { items, nextToken } = payload;
      return { ...state, items, nextToken };
    }
    case LOAD_SPLITS_MORE_SUCCESS: {
      const { items, nextToken } = payload;
      return { ...state, items: [...state.items, ...items], nextToken };
    }

    case LOAD_SPLIT_DETAILS_SUCCESS: {
      const { splitDetails } = payload;
      return { ...state, splitDetails };
    }

    case REPEAT_EMPLOYEE_TRANSFER_SUCCESS: {
      const { id, transfer } = payload as TRepeatEmployeeTransferSuccessPayload;

      const transfersItems = state.splitDetails?.transfers?.items.map(
        (item) => {
          if (!item) return item;
          if (item.id !== id) return item;

          return {
            ...item,
            ...transfer,
          };
        }
      );

      console.log("transfersItems", id, transfersItems);

      return {
        ...state,
        splitDetails: {
          ...state.splitDetails,
          transfers: {
            ...state.splitDetails?.transfers,
            items: transfersItems,
          },
        },
      };
    }

    default:
      return state;
  }
};

export default splitReducers;
