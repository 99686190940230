import {
  IonDatetime,
  IonDatetimeButton,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonProgressBar,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import React, { useState } from "react";
import Checkbox from "../../../../common/Checkbox";
import {
  DatetimeChangeEventDetail,
  SelectChangeEventDetail,
} from "@ionic/core";
import {
  IonDatetimeCustomEvent,
  IonSelectCustomEvent,
} from "@ionic/core/dist/types/components";
import { useAppDispatch } from "../../../../../store/store";
import { loadIndividuals } from "../../../../../store/actions/individuals.actions";
import useDelayCallback from "../../../../../hooks/useDelayCallback";

const REGION_LIST = ["Greater Manchester", "London", "West Yorkshire"];
const REGISTERED_AFTER = `${new Date().getFullYear()}-01-01T00:00:00.000Z`;

const Filter = () => {
  const [verified, setVerified] = useState(true);
  const [notVerified, setNotVerified] = useState(true);
  const [createdAfter, setCreatedAfter] = useState<string>(REGISTERED_AFTER);
  const [regions, setRegions] = useState<string[]>([]);

  const call = useAppDispatch();

  const { loading } = useDelayCallback(() => {
    call(loadIndividuals({ verified, notVerified, createdAfter, regions }));
  }, [notVerified, createdAfter, regions, verified]);

  const handleCreatedAtChange = (
    e: IonDatetimeCustomEvent<DatetimeChangeEventDetail>
  ) => {
    const { value } = e.detail;
    if (typeof value !== "string") return;

    const valueFormatted = value.split("T")[0] + "T00:00:00.000Z";
    setCreatedAfter(valueFormatted);
  };

  const handleRegionsChange = (
    e: IonSelectCustomEvent<SelectChangeEventDetail>
  ) => {
    const { value } = e.detail;
    setRegions(value.length === REGION_LIST.length ? [] : value);
  };

  return (
    <IonList lines="inset">
      <Checkbox checked={verified} setChecked={setVerified}>
        Verified
      </Checkbox>

      <Checkbox checked={notVerified} setChecked={setNotVerified}>
        Not verified
      </Checkbox>

      <IonItem>
        <IonLabel>Registered after</IonLabel>
        <IonDatetimeButton datetime="datetime"></IonDatetimeButton>

        <IonModal keepContentsMounted={true}>
          <IonDatetime
            id="datetime"
            presentation="date"
            value={createdAfter}
            onIonChange={handleCreatedAtChange}
          />
        </IonModal>
      </IonItem>

      <IonItem lines="none">
        <IonLabel>Regions</IonLabel>
        <IonSelect
          placeholder="All"
          multiple
          interface="action-sheet"
          onIonChange={handleRegionsChange}
          value={regions}
        >
          {REGION_LIST.map((region) => (
            <IonSelectOption key={region} value={region}>
              {region}
            </IonSelectOption>
          ))}
        </IonSelect>
      </IonItem>
      {loading && <IonProgressBar type="indeterminate" />}
    </IonList>
  );
};

export default Filter;
