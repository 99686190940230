import { Redirect, Route } from "react-router-dom";
import IndividualsPage from "../feature/individuals/pages/Individuals/Individuals";
import { IonRouterOutlet, IonSplitPane } from "@ionic/react";
import Menu from "../layout/Menu/Menu";
import { ReactNode } from "react";
import OutletsPage from "../feature/outlets/pages/Outlets/Outlets";
import OutletProfilePage from "../feature/outlets/pages/OutletProfile/OutletProfile";
import BrandAndSupplierPage from "../feature/brandAndSupplier/pages/BrandAndSupplier/BrandAndSupplier";
import SplitHome from "../feature/splits/pages/SplitHome";
import SplitDetails from "../feature/splits/pages/SplitDetails/SplitDetails";
import TipsOverview from "../feature/overview/pages/TipsOverview/TipsOverview";
import EnterpriseHome from "../feature/enterprise/pages/EnterpriseHome/EnterpriseHome";
import EnterpriseProfile from "../feature/enterprise/pages/EnterpriseProfile/EnterpriseProfile";
import EnterpriseUserCreate from "../feature/enterprise/pages/EnterpriseUserCreate/EnterpriseUserCreate";

const AuthorizedRoutes = () => {
  const menuWrapper = (component: ReactNode) => (
    <IonSplitPane contentId="authorized">
      <Menu />
      {component}
    </IonSplitPane>
  );

  return menuWrapper(
    <IonRouterOutlet id="authorized" animated>
      <Route path="/" exact>
        <Redirect to="/individual" />
      </Route>
      <Route path="/:tab(individual)" exact component={IndividualsPage} />

      <Route path="/:tab(outlet)" exact component={OutletsPage} />
      <Route path="/:tab(outlet)/:slug" exact component={OutletProfilePage} />

      <Route path="/:tab(brands)" exact component={BrandAndSupplierPage} />

      <Route path="/:tab(overview)" exact component={TipsOverview} />

      <Route path="/:tab(enterprise)" exact component={EnterpriseHome} />
      <Route path="/:tab(enterprise)/:id" exact component={EnterpriseProfile} />
      <Route
        path="/:tab(enterprise)/create"
        exact
        component={EnterpriseUserCreate}
      />

      <Route path="/:tab(split)" exact component={SplitHome} />
      <Route path="/:tab(split)/:id" exact component={SplitDetails} />
    </IonRouterOutlet>
  );
};

export default AuthorizedRoutes;
