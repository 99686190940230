import {
  IonButton,
  IonIcon,
  IonItem,
  IonLabel,
  useIonAlert,
} from "@ionic/react";
import formatAmount from "../../../../../utils/formatAmount";
import "./PaymentModalHeader.css";
import { FEE_ALERT_CONTENT } from "../BankCardModal/lib/config";
import { informationCircleOutline } from "ionicons/icons";

interface IProps {
  amount: number;
  fee: number;
  isFeeCovered: boolean;
}

const PaymentModalHeader = ({ amount, isFeeCovered, fee }: IProps) => {
  const [presentAlert] = useIonAlert();

  return (
    <IonItem className="modal-header" lines="none">
      <IonLabel>
        <strong>
          {formatAmount(isFeeCovered ? amount + fee : amount, 2)}{" "}
        </strong>
        {/*<span>*/}
        {/*  {isFeeCovered ? "inc." : "exc."} {formatAmount(fee)} transaction fee*/}
        {/*</span>*/}
      </IonLabel>
      <IonButton
        onClick={() => presentAlert(FEE_ALERT_CONTENT)}
        fill="clear"
        color="dark"
        slot="end"
        className="ion-no-padding ion-no-margin"
      >
        <IonIcon icon={informationCircleOutline} slot="icon-only" />
      </IonButton>
    </IonItem>
  );
};

export default PaymentModalHeader;
