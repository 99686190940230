import { BRAND_CATEGORIES } from "../../utils/constants";
import { IonChip, IonProgressBar } from "@ionic/react";
import React, { Fragment, useState } from "react";
import { useAppDispatch } from "../../../../../store/store";
import { loadBrands } from "../../../../../store/actions/brand.actions";
import useDelayCallback from "../../../../../hooks/useDelayCallback";

const CategoriesFilter = () => {
  const [categories, setCategories] = useState<string[]>([]);

  const call = useAppDispatch();
  const { loading } = useDelayCallback(
    () => call(loadBrands({ categories })),
    [categories]
  );
  const handleCategoryClick = (category: string) => {
    let categoriesNew: string[] = [];
    switch (true) {
      case category === "All": {
        if (categories.length === BRAND_CATEGORIES.length) categoriesNew = [];
        else categoriesNew = BRAND_CATEGORIES;
        break;
      }
      case categories.includes(category):
        categoriesNew = categories.filter((cat) => cat !== category);
        break;

      default:
        categoriesNew = [...categories, category];
    }
    setCategories(categoriesNew);
  };

  return (
    <Fragment>
      <div className="scrollable-content ion-margin-top">
        {BRAND_CATEGORIES.map((category) => (
          <IonChip
            color={categories.includes(category) ? "primary" : "medium"}
            onClick={() => handleCategoryClick(category)}
          >
            {category}
          </IonChip>
        ))}
      </div>
      {loading && <IonProgressBar type="indeterminate" />}
    </Fragment>
  );
};

export default CategoriesFilter;
