import { IonAvatar, IonImg } from "@ionic/react";
import { handleImageUrl } from "../../../utils";
import avatarDefault from "../../../assets/images/avatar-default.svg";
import React from "react";

interface IProps {
  url?: string | null;
  slot?: "start" | "end";
}

const Avatar = ({ url, slot }: IProps) => (
  <IonAvatar slot={slot}>
    <IonImg src={url ? handleImageUrl(url) : avatarDefault} />
  </IonAvatar>
);

export default Avatar;
