import { IonItem, IonLabel, IonToggle, ToggleCustomEvent } from "@ionic/react";

interface IProps {
  children: string;
  checked?: boolean;
  setChecked?: (value: boolean) => void;
  onClick?: () => void;
}

const Toggle = ({ children, checked, setChecked, onClick }: IProps) => {
  const handleChange = ({ detail }: ToggleCustomEvent) => {
    if (setChecked) setChecked(detail?.checked);
  };

  return (
    <IonItem onClick={onClick} lines="none">
      <IonLabel style={{ whiteSpace: "break-spaces" }}>{children}</IonLabel>
      {/*<IonCheckbox slot="end/" color="dark" checked={checked}  onIonChange={handleChange}/>*/}
      <IonToggle
        slot="end"
        color="dark"
        checked={checked}
        onIonChange={handleChange}
      />
    </IonItem>
  );
};

export default Toggle;
