import TabPageHeader from "../../../../layout/TabPageHeader/TabPageHeader";
import {
  IonChip,
  IonCol,
  IonContent,
  IonGrid,
  IonList,
  IonPage,
  IonRow,
} from "@ionic/react";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { loadSplitDetails } from "../../../../../store/actions/splits.actions";
import { TipSplit } from "../../../../../types/DBTypes";
import { TRootState } from "../../../../../store/store";
import { getSplitDetails } from "../../../../../store/selectors/splits.selectors";
import { useParams } from "react-router";
import { formatAmount } from "../../../../../utils";
import Header from "../../../../common/Header/Header";
import TipItem from "../../components/TipItem/TipItem";
import "./SplitDetails.css";
import TransfersToEmployeeSection from "../../components/TransfersToEmployeesSection/TransfersToEmployeeSection";
import StatisticCard from "../../../../common/StatisticCard/StatisticCard";

const mapState = (state: TRootState) => ({
  splitDetails: getSplitDetails(state),
});
const mapDispatch = (dispatch: any) => ({
  onLoadSplitDetails: (id: string) => dispatch(loadSplitDetails({ id })),
});

interface IProps {
  onLoadSplitDetails: (id: string) => void;
  splitDetails: TipSplit;
}

const SplitDetails = ({ splitDetails, onLoadSplitDetails }: IProps) => {
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    if (splitDetails?.id !== id) onLoadSplitDetails(id);
  });

  if (!splitDetails) return <h2>Loading</h2>;
  const { status, amount, from, to, ownerProfile, transactions, transfers } =
    splitDetails;

  const failedAmount =
    transfers?.items.reduce(
      (acc, curr) => (curr?.status === "fail" ? acc + curr.amount : acc),
      0
    ) || 0;

  return (
    <IonPage className="page--outlet-profile">
      <TabPageHeader defaultBackHref="/split" title={"Split"}>
        <IonChip slot="end" color={status === "sent" ? "success" : "danger"}>
          {status}
        </IonChip>
      </TabPageHeader>

      <IonContent>
        <IonGrid>
          <IonRow>
            <IonCol sizeMd="4">
              <StatisticCard title="Total" value={formatAmount(amount / 100)} />
            </IonCol>
            <IonCol sizeMd="4">
              <StatisticCard
                title="Failed"
                value={formatAmount(failedAmount / 100)}
              />
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol sizeMd="8" size="12">
              {/* @ts-ignore */}
              <TransfersToEmployeeSection transfers={transfers?.items} />
            </IonCol>

            <IonCol sizeMd="4" size="12">
              <Header title="Tips" />
              <IonList inset>
                {transactions?.items.map(
                  (transaction) =>
                    transaction && (
                      <TipItem key={transaction.id} transfer={transaction} />
                    )
                )}
              </IonList>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default connect(mapState, mapDispatch)(SplitDetails);
