import { createAction } from "@reduxjs/toolkit";
import {
  TCreateBrandPayload,
  TCreateBrandSuccessPayload,
  TDeleteBrandPayload,
  TDeleteBrandSuccessPayload,
  TLoadBrandsPayload,
  TLoadBrandsSuccessPayload,
  TUpdateBrandPayload,
  TUpdateBrandSuccessPayload,
} from "../types/brand.types";

export const LOAD_BRANDS = "[BRANDS] LOAD_BRANDS";
export const LOAD_BRANDS_SUCCESS = "[BRANDS] LOAD_BRANDS_SUCCESS";
export const LOAD_BRANDS_MORE = "[BRANDS] LOAD_BRANDS_MORE";
export const LOAD_BRANDS_MORE_SUCCESS = "[BRANDS] LOAD_BRANDS_MORE_SUCCESS";

export const UPDATE_BRAND = "[BRANDS] UPDATE_BRAND";
export const UPDATE_BRAND_SUCCESS = "[BRANDS] UPDATE_BRAND_SUCCESS";

export const CREATE_BRAND = "[BRANDS] CREATE_BRAND";
export const CREATE_BRAND_SUCCESS = "[BRANDS] CREATE_BRAND_SUCCESS";

export const DELETE_BRAND = "[BRANDS] DELETE_BRAND";
export const DELETE_BRAND_SUCCESS = "[BRANDS] DELETE_BRAND_SUCCESS";

export const loadBrands = createAction<TLoadBrandsPayload>(LOAD_BRANDS);
export const loadBrandsSuccess =
  createAction<TLoadBrandsSuccessPayload>(LOAD_BRANDS_SUCCESS);
export const loadBrandsMore = createAction(LOAD_BRANDS_MORE);
export const loadBrandsMoreSuccess = createAction<TLoadBrandsSuccessPayload>(
  LOAD_BRANDS_MORE_SUCCESS
);

export const updateBrand = createAction<TUpdateBrandPayload>(UPDATE_BRAND);
export const updateBrandSuccess =
  createAction<TUpdateBrandSuccessPayload>(UPDATE_BRAND_SUCCESS);

export const createBrand = createAction<TCreateBrandPayload>(CREATE_BRAND);
export const createBrandSuccess =
  createAction<TCreateBrandSuccessPayload>(CREATE_BRAND_SUCCESS);

export const deleteBrand = createAction<TDeleteBrandPayload>(DELETE_BRAND);
export const deleteBrandSuccess =
  createAction<TDeleteBrandSuccessPayload>(DELETE_BRAND_SUCCESS);
