import { FC } from "react";
import { IonChip } from "@ionic/react";
import "./ChipsPicker.css";

interface ChipsPickerProps {
  options: string[];
  value: string;
  onChange: (val: string) => void;
}

const ChipsPicker: FC<ChipsPickerProps> = ({ options, value, onChange }) => {
  return (
    <div className="chips-picker">
      {options.map((option) => (
        <IonChip
          key={option}
          color={value !== option ? "medium" : undefined}
          onClick={() => onChange(option)}
        >
          {option}
        </IonChip>
      ))}
    </div>
  );
};

export default ChipsPicker;
