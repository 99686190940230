import { IonIcon } from "@ionic/react";
import { logoApple, logoGoogle } from "ionicons/icons";

export const FEE_ALERT_CONTENT = {
  header: "Tips go direct and in full to the team",
  // message: `We do not charge our users anything to use this service but there
  // are transactions fees to cover so that the team get 100% of the tip!`,
  buttons: ["Got it"],
};

export const IS_APPLE_PAY_SUPPORTED = () => {
  try {
    return (window as any).ApplePaySession?.canMakePayments().then(
      (result: any) => !!result
    );
  } catch {
    return false;
  }
};

export const PAY_ICON = (
  <div>
    <IonIcon
      slot="start"
      icon={IS_APPLE_PAY_SUPPORTED() ? logoApple : logoGoogle}
    />
    <span>Pay</span>
  </div>
);
