import { API, Auth } from "aws-amplify";

type TParams = { [key: string]: string };

const handleAuthorization = async (authorization: boolean) => {
  if (!authorization) return {};
  const cognitoUser = await Auth.currentAuthenticatedUser();
  return {
    headers: {
      Authorization: cognitoUser.signInUserSession.idToken.jwtToken,
    },
  };
};
const get = async (
  endpoint: string,
  params?: TParams,
  authorization: boolean = false
) => {
  const endpointWithParams = !params
    ? endpoint
    : `${endpoint}?${Object.keys(params)
        .map((key) => [key, params[key]].join("="))
        .join("&")}`;

  return API.get(
    "tipmiapiRest",
    endpointWithParams,
    await handleAuthorization(authorization)
  );
};

const post = async (endpoint: string, body: any, auth = false) => {
  return API.post("tipmiapiRest", endpoint, {
    body,
    ...(await handleAuthorization(auth)),
  });
};

const put = async (endpoint: string, body: any) => {
  const headers = await handleAuthorization(true);
  return API.put("tipmiapiRest", endpoint, { body, ...headers });
};

const request = {
  get,
  post,
  put,
};

export default request;
