import {
  IonButton,
  IonModal,
  IonAvatar,
  IonText,
  IonSpinner,
} from "@ionic/react";
import { ModalButton } from "../../../types/types";
import avatarDefault from "../../../assets/images/avatar-default.svg";

import "./Modal.css";
import { useState } from "react";

interface ModalProps {
  title?: string;
  children: JSX.Element | JSX.Element[];
  image?: string;
  secondaryButton?: ModalButton;
  primaryButton: ModalButton;
  active: boolean;
  setActive: (value: boolean) => void;
}

const Modal = ({
  title,
  children,
  image,
  secondaryButton,
  primaryButton,
  active,
  setActive,
}: ModalProps) => {
  const [clicked, setClicked] = useState(false);

  const handlePrimaryClick = () => {
    setClicked(true);
    if (!primaryButton?.onClick) {
      setActive(false);
      return;
    }

    setTimeout(() => {
      setClicked(false);
      setActive(false);
    }, 2000);
    primaryButton.onClick();
  };

  return (
    <IonModal
      className="modal"
      isOpen={active}
      onDidDismiss={() => setActive(false)}
    >
      <div className="wrapper">
        {image && (
          <IonAvatar className="modal-avatar">
            <img alt="Recipient's avatar" src={image || avatarDefault} />
          </IonAvatar>
        )}
        <IonText color="dark">
          <h2 className="modal-title">{title}</h2>
        </IonText>
        {children}
      </div>
      <div className="btn-container">
        {secondaryButton && (
          <IonButton
            className="modal-button"
            color="light"
            onClick={secondaryButton.onClick}
            disabled={clicked}
          >
            {secondaryButton.title}
          </IonButton>
        )}
        <IonButton
          className="modal-button modal-button-primary"
          onClick={handlePrimaryClick}
          color="light"
          disabled={clicked}
        >
          {clicked ? <IonSpinner /> : primaryButton.title}
        </IonButton>
      </div>
    </IonModal>
  );
};

export default Modal;
