import React from "react";
import Toggle from "../../../../common/Toggle/Toggle";
import { IonList, useIonModal } from "@ionic/react";
import MerchantIdSetup from "../MerchantIdSetup/MerchantIdSetup";
import { Business } from "../../../../../types/types";

interface IProps {
  outlet: Business;
}

const SolutionsController = ({ outlet }: IProps) => {
  const [showTerminalSetup, dismiss] = useIonModal(MerchantIdSetup, {
    outlet,
    dismiss: () => dismiss(),
  });

  return (
    <IonList>
      <Toggle
        checked={!!outlet?.tipping?.merchantId}
        onClick={showTerminalSetup}
      >
        Tipinc Terminal
      </Toggle>
      <Toggle>Tipinc Contactless</Toggle>
      <Toggle checked={!!outlet?.tipping?.merchantId}>Tipinc QR</Toggle>
    </IonList>
  );
};

export default SolutionsController;
