import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonChip,
  IonImg,
} from "@ionic/react";
import React from "react";
import { Brand } from "../../../../../types/DBTypes";
import { handleImageUrl } from "../../../../../utils";
import avatarDefault from "../../../../../assets/images/avatar-default.svg";
import "./BrandCard.css";

interface IProps {
  brand: Brand;
  onClick: (brand: Brand) => void;
}

const BrandCard = ({ brand, onClick }: IProps) => {
  const { name, logo, active, productType } = brand;
  return (
    <IonCard
      className="brand-card"
      onClick={() => onClick(brand)}
      disabled={!active}
      button
    >
      <IonImg src={logo ? handleImageUrl(logo) : avatarDefault} />
      <IonCardHeader>
        <IonCardTitle>{name}</IonCardTitle>
      </IonCardHeader>
      {productType && (
        <IonCardContent>
          <IonChip>{productType}</IonChip>
        </IonCardContent>
      )}
    </IonCard>
  );
};

export default BrandCard;
