import { IonButton, IonIcon } from "@ionic/react";
import "./ButtonWide.css";
import React from "react";

interface TProps {
  onClick?: () => void;
  children?: string | React.ReactNode;
  icon?: string | JSX.Element;
  className?: string;
  disabled?: boolean;
  type?: "button" | "submit" | "reset";
}

const ButtonWide = ({
  onClick,
  icon,
  children = null,
  className = "",
  disabled = false,
  type = "button",
}: TProps) => {
  return (
    <IonButton
      onClick={onClick}
      className={["button--wide", className].join(" ")}
      expand="block"
      color="dark"
      disabled={disabled}
      type={type}
    >
      {children}
      {typeof icon === "string" ? <IonIcon slot="end" icon={icon} /> : icon}
    </IonButton>
  );
};

export default ButtonWide;
