import {
  IonButton,
  IonContent,
  IonFooter,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import "./MerchantIdSetup.css";
import { useState } from "react";
import {
  InputInputEventDetail,
  IonInputCustomEvent,
} from "@ionic/core/dist/types/components";
import getMerchantOutlets from "../../utils/getMerchantOutlets";
import TMerchant from "../../types/TMerchant";
import useConfirmationModal from "../../../../../hooks/useConfirmationModal";
import { connect } from "react-redux";
import { UpdateBusinessInput } from "../../../../../types/DBTypes";
import { updateBusiness as updateBusinessAction } from "../../../../../store/actions/outlets.actions";
import { Business } from "../../../../../types/types";

const VAT_LENGTH = 9;
const MID_LENGTH = 24;
const INC_NUMBER_LENGTH = 8;

const mapProps = (dispatch: any) => ({
  updateBusiness: (input: UpdateBusinessInput) =>
    dispatch(updateBusinessAction({ input })),
});

interface IProps {
  updateBusiness: (input: UpdateBusinessInput) => void;
  outlet: Business;
  slug: string;
  dismiss: (data?: any, role?: string) => void;
}
const MerchantIdSetup = ({ updateBusiness, outlet, dismiss }: IProps) => {
  const [identifier, setIdentifier] = useState<string>("");
  const [merchant, setMerchant] = useState<TMerchant | null>();
  const setMerchantId = useConfirmationModal((merchantId: string) => {
    updateBusiness({
      id: outlet?.id,
      tipping: {
        ...outlet.tipping,
        merchantId,
      },
    });

    setTimeout(() => {
      setIdentifier("");
      setMerchant(null);
      dismiss();
    }, 1000);
  }, "Are you sure you want to link this account with specified outlet ");

  const handleIdentifierChange = (
    e: IonInputCustomEvent<InputInputEventDetail>
  ) => {
    const { value } = e.detail;
    setIdentifier(value || "");
    setMerchant(null);

    // start of temporary disable code TIP-974
    // if (
    //   value?.length === VAT_LENGTH ||
    //   value?.length === MID_LENGTH ||
    //   value?.length === INC_NUMBER_LENGTH
    // )
    //  getMerchantOutlets(value).then(setMerchant);
    // end of temporary disable code TIP-974

  };

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Set up terminal</IonTitle>
          {/*<IonButtons>*/}
          {/*  <IonButton>Cancel</IonButton>*/}
          {/*</IonButtons>*/}
        </IonToolbar>
      </IonHeader>

      <IonContent className="merchant-id-content">
        {outlet?.tipping?.merchantId && (
          <IonItem>
            <IonLabel>Actual merchantID</IonLabel>
            <IonText>
              <p>{outlet?.tipping?.merchantId}</p>
            </IonText>
          </IonItem>
        )}
        <IonItem>
          <IonInput
            label="Merchant ID"
            labelPlacement="floating"
            onIonInput={handleIdentifierChange}
            value={identifier}
          />
        </IonItem>

        {merchant && (
          <>
            <IonList>
              <IonListHeader>{merchant.company_registered_name}</IonListHeader>
              {merchant.outlets.map(
                ({
                  outlet_city_field,
                  outlet_trading_address,
                  merchant_outlet_id,
                }) => (
                  <IonItem
                    onClick={() => setMerchantId(merchant_outlet_id)}
                    key={merchant_outlet_id}
                    button
                  >
                    <IonText slot="start">
                      <p>{outlet_city_field}</p>
                    </IonText>
                    <IonLabel>
                      <IonText>
                        <p>{outlet_trading_address}</p>
                        <small>{merchant_outlet_id}</small>
                      </IonText>
                    </IonLabel>
                  </IonItem>
                )
              )}
            </IonList>
          </>
        )}
        {/* start of temporary code block for TIP-974 */}
        {identifier && (
          <IonFooter>
            <IonButton fill="clear" color="medium" onClick={() => setMerchantId(identifier)}>Assign</IonButton>
          </IonFooter>
        )}
        {/* end of temporary code block for TIP-974 */}
      </IonContent>
    </>
  );
};

export default connect(null, mapProps)(MerchantIdSetup);
