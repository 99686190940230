import React, { useEffect } from "react";
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonPage,
  IonRow,
} from "@ionic/react";
import TabHeader from "../../../../layout/TabHeader/TabHeader";
import { connect } from "react-redux";
import { TRootState } from "../../../../../store/store";
import { ProfileEnterprise } from "../../../../../types/DBTypes";
import { getEnterpriseProfiles } from "../../../../../store/selectors/enterprise.selectors";
import { loadProfilesEnterprise } from "../../../../../store/actions/enterprise.actions";
import EnterpriseItem from "../../components/EnterpriseItem/EnterpriseItem";

const mapState = (state: TRootState) => ({
  items: getEnterpriseProfiles(state),
});

const mapProps = (dispatch: any) => ({
  onLoadEnterprise: () => dispatch(loadProfilesEnterprise()),
});

interface IProps {
  items?: ProfileEnterprise[];
  onLoadEnterprise: () => void;
}

const EnterpriseHome = ({ items, onLoadEnterprise }: IProps) => {
  // const [showCreateModal, dismiss] = useIonModal(EnterpriseUserCreate, {
  //   onDismiss: () => dismiss(),
  // });

  useEffect(() => {
    if (!items) onLoadEnterprise();
  }, [items, onLoadEnterprise]);

  return (
    <IonPage>
      <TabHeader title="Enterprise">
        <IonButton className="ion-margin-end" routerLink="/enterprise/create">
          Create
        </IonButton>
      </TabHeader>
      <IonContent>
        <IonGrid>
          <IonRow>
            <IonCol>
              <div className="section-inset">
                {items
                  ? items.map((item) => (
                      <EnterpriseItem key={item.id} item={item} />
                    ))
                  : null}
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default connect(mapState, mapProps)(EnterpriseHome);
