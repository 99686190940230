const defaultOptions: Intl.DateTimeFormatOptions = {
  hour12: true,
  hour: "numeric",
  minute: "2-digit",
  year: "numeric",
  month: "numeric",
  day: "numeric"
};

const formatDateTime = (dateTimeString: string, locale = "en-GB", options = defaultOptions) => {
  if (!Date.parse(dateTimeString)) return "";
  const formattedDateTime = new Date(dateTimeString);
  return formattedDateTime.toLocaleString(locale, options).split(",").join("");
};

export default formatDateTime;
