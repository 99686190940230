import { call, put } from "redux-saga/effects";
import { showToast } from "../actions/ui.actions";

const safe = (saga: any, ...args: any) =>
  function* (action: any) {
    try {
      yield call(saga, ...args, action);
    } catch (err: any) {
      yield put(
        showToast({
          text: err.response?.data?.data?.message || err.message,
        })
      );
    }
  };

export default safe;
