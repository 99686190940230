import { IonRouterOutlet } from "@ionic/react";
import { Redirect, Route } from "react-router-dom";
import LoginPage from "../feature/login/pages/Login";
import { useAppDispatch } from "../../store/store";
import { useEffect } from "react";
import { Auth } from "aws-amplify";
import { signInSuccess } from "../../store/actions/user.actions";

const SignInRoutes = () => {
  const call = useAppDispatch();

  useEffect(() => {
    Auth.currentUserPoolUser()
      // TODO doesn't cover the case when user lost admin privileges
      //  and has been already authenticated
      .then(() => call(signInSuccess()))
      .catch(() => {});
  }, [call]);

  return (
    <IonRouterOutlet id="sign-in">
      <Route path="/" exact={true} component={LoginPage} />

      <Route path="/:anything" exact={true}>
        <Redirect to="/" />
      </Route>
    </IonRouterOutlet>
  );
};

export default SignInRoutes;
