import { createContext, useEffect, useState } from "react";
import PaymentModal from "../PaymentModal/PaymentModal";
import { useIonViewWillLeave } from "@ionic/react";
import request from "../../../../../store/utils/requestController";
import { TRootState } from "../../../../../store/store";
import { useSelector } from "react-redux";

const PAGE_WRAPPER_CLASS = "content--tipping";
/*****************************************************
 * Provider for the PaymentModal component. It manages
 * the state of the PaymentModal and the amount to be
 * paid. Also, it does payment pre-preparation on BE.
 *****************************************************/
interface TProps {
  children: JSX.Element | JSX.Element[];
  visible?: boolean;
  setVisible?: (visible: boolean) => void;
}

type PaymentModalContextType = {
  amount: number;
  isFeeCovered: boolean;

  clientSecret?: string;
  paymentIntent?: string;

  setAmount?: (amount: number) => void;
  setIsFeeCovered?: (include: boolean) => void;
};

export const PaymentModalContext = createContext<PaymentModalContextType>({
  amount: 0,
  isFeeCovered: false,
});

/*****************************************************/
const PaymentModalProvider = ({ children }: TProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [amount, setAmount] = useState<number>(0);
  const [fee, setFee] = useState(0);
  const [isFeeCovered, setIsFeeCovered] = useState(false);

  const [clientSecret, setClientSecret] = useState("");
  const [paymentIntent, setPaymentIntent] = useState<string | undefined>(
    undefined
  );

  const receiverId: string = useSelector(
    (state: TRootState) => state.tipping?.data?.id
  );

  // Close the modal when leaving the page
  useIonViewWillLeave(() => setIsModalOpen(false));

  useEffect(() => {
    // modal should be opened when amount is set
    //             and closed when amount is not chosen
    setIsModalOpen(!!amount);

    const pageWrapper = document.getElementsByClassName(
      PAGE_WRAPPER_CLASS
    )[0] as HTMLIonContentElement;

    if (!pageWrapper) return;
    if (!!amount) pageWrapper.scrollToBottom(200);
    else pageWrapper.scrollToTop(200);
  }, [amount]);

  // calculate fees
  useEffect(() => {
    if (Number.isNaN(process.env.REACT_APP_TIP_FEE_PERCENTAGE)) return;
    const feePercentage = Number(process.env.REACT_APP_TIP_FEE_PERCENTAGE);
    const fee = amount * feePercentage;

    setFee(+fee.toFixed(2));

    const totalAmount = amount + (isFeeCovered ? fee : 0);
    if (!totalAmount) return;

    request
      .post("/api/merchant/stripe/checkout", {
        paymentIntent,
        amount: totalAmount * 100,
        metadata: { receiverId, tipsAmount: amount, source: "QR" },
      })
      .then(({ data }) => {
        const { client_secret, id } = data;
        if (!client_secret) return;

        setClientSecret(client_secret);
        setPaymentIntent(id);
      });
  }, [amount, isFeeCovered]);

  return (
    <PaymentModalContext.Provider
      value={{
        setAmount,
        amount,
        isFeeCovered,
        setIsFeeCovered,
        clientSecret,
        paymentIntent,
      }}
    >
      {children}
      <PaymentModal
        amount={amount}
        isFeeCovered={isFeeCovered}
        fee={fee}
        isActive={isModalOpen}
      />
    </PaymentModalContext.Provider>
  );
};
export default PaymentModalProvider;
