import {
  CheckboxCustomEvent,
  IonCheckbox,
  IonItem,
  IonLabel,
} from "@ionic/react";
import React from "react";

interface IProps {
  children: string;
  checked?: boolean;
  setChecked?: (checked: boolean) => void;
}

const Checkbox = ({ children, checked, setChecked = () => null }: IProps) => {
  const handleChange = ({ detail }: CheckboxCustomEvent) =>
    setChecked(detail?.checked);

  return (
    <IonItem lines="none">
      <IonLabel>{children}</IonLabel>
      <IonCheckbox
        slot="end"
        color="dark"
        checked={checked}
        onIonChange={handleChange}
      />
    </IonItem>
  );
};

export default Checkbox;
