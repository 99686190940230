import { createSelector } from "@reduxjs/toolkit";
import { TRootState } from "../store";

const brandsSelectors = (state: TRootState) => state.brands;

export const getBrandsNextToken = createSelector(
  brandsSelectors,
  (state) => state?.nextToken
);

export const getBrandsHasMore = createSelector(
  getBrandsNextToken,
  (nextToken) => !!nextToken
);

export const getBrands = createSelector(
  brandsSelectors,
  (state) => state?.items
);
