const getPrimitiveValueObject = (obj: object): object => {
  const primitiveValueObject: { [key: string]: string } = {};

  Object.keys(obj).forEach((key) => {
    const value = obj[key as keyof typeof obj];

    if (typeof value === "object" && value !== null) {
      return;
    }

    primitiveValueObject[key] = value;
  });

  return primitiveValueObject;
};

export default getPrimitiveValueObject;
