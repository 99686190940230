import { TAction } from "../store";
import { TState } from "../types/enterprise.types";
import {
  CREATE_PROFILE_ENTERPRISE_SUCCESS,
  LOAD_PROFILES_ENTERPRISE_SUCCESS,
} from "../actions/enterprise.actions";

//----------------------------------------------------------------------------------------------------------------------
const initialState: TState = {
  items: undefined,
};
//----------------------------------------------------------------------------------------------------------------------

const enterpriseReducers = (
  state = initialState,
  { type, payload }: TAction<any>
): TState => {
  switch (type) {
    case LOAD_PROFILES_ENTERPRISE_SUCCESS: {
      const { items } = payload;
      return {
        ...state,
        items,
      };
    }
    case CREATE_PROFILE_ENTERPRISE_SUCCESS: {
      const { item } = payload;
      return {
        ...state,
        items: [item, ...(state.items || [])],
      };
    }
    default:
      return state;
  }
};

export default enterpriseReducers;
