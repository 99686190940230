import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonChip,
} from "@ionic/react";
import React from "react";
import Skeleton from "../../../../common/Skeleton/Skeleton";

const BrandCardSkeleton = () => (
  <IonCard>
    <Skeleton width="100%" height="10rem" />
    <IonCardHeader>
      <IonCardTitle>
        <Skeleton width="5ch" />
      </IonCardTitle>
    </IonCardHeader>
    <IonCardContent>
      <IonChip>
        <Skeleton width="6ch" />
      </IonChip>
    </IonCardContent>
  </IonCard>
);

export default BrandCardSkeleton;
